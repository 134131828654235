import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { Box, Button, Grid, Typography } from '@material-ui/core';

import QuoteCard from '@@Components/QuoteCard';
import { operations as socketOperations } from '@@Redux/socket';


const FuturesPair = ({
	subscribe
}) => {

	useEffect(() => {
		console.log('Subscribing Pair');
		subscribe({ service: 'LEVELONE_FUTURES', symbol: '/XCZ20' });
		subscribe({ service: 'LEVELONE_FUTURES', symbol: '/XCH21' });
	}, []);

	return (
		<Box>
			<Typography variant="h6">Pair</Typography>
			<Grid container>
				<Grid item xs>
					1
					<QuoteCard service="LEVELONE_FUTURES" symbol="/XCZ20" />
				</Grid>
				<Grid item xs>
					2
					<QuoteCard service="LEVELONE_FUTURES" symbol="/XCH21" />
				</Grid>
			</Grid>
		</Box>
	);
};


const mapStateToProps = (state) => {
	return {
	}
};

const mapDispatchToProps = {
	subscribe: socketOperations.subscribe,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(FuturesPair);

