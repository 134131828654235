import { combineReducers } from 'redux';
import produce from 'immer';

import types from './types';
import socketTypes from '@@Redux/socket/types';


const results = (state = [], action) => produce(state, draft => {
	switch (action.type) {
		case types.BACKTEST.SUCCESS: {
			console.log('new', action.payload.closedPositions);
			return action.payload.closedPositions;
		}
	}
});

const rawData = (state = {}, action) => produce(state, draft => {
	switch (action.type) {
		case types.BACKTEST.SUCCESS: {
			return action.payload.rawData;
		}
	}
});

const iterations = (state = [], action) => produce(state, draft => {
	switch (action.type) {
		case socketTypes.SOCKET.MESSAGE.RECEIVE: {
			if (action.meta.type === 'backtest') {
				draft.push(action.payload.data);
				return
			}
		}
	}
});

const reducer = combineReducers({
	rawData,
	iterations,
	results,
});

export default reducer;




