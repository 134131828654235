import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { withTheme, Box, Card, Grid, Typography, CardHeader } from '@material-ui/core';

import { USD } from '@@Utils';
import { selectors as quoteSelectors } from '@@Redux/quotes';
import { operations as socketOperations } from '@@Redux/socket';

const QuoteCard = ({
	quote,
	service,
	subscribe,
	symbol,
}) => {

	useEffect(() => {
		if (service && symbol) {
			subscribe({ service, symbol });
		}
	}, [service, symbol]);

	if (symbol) {
		return (
			<Fragment>
				<Card>
					<CardHeader title={(
						<Fragment>
							<Typography variant="h2">{symbol}</Typography>
							{quote && (
								<Typography variant="caption" component="div">{quote.description}</Typography>
							)}
						</Fragment>
					)} />
					{quote && (
						<Box p={2}>
							<Typography variant="subtitle2">last</Typography>
							<Typography variant="h4" gutterBottom>{USD((quote.last || quote.price)).format()}</Typography>
							<Grid container spacing={2}>
								<Grid item>
									<Typography variant="subtitle2">bid</Typography>
									<strong>{USD(quote.bid).format()}</strong>
								</Grid>
								<Grid item>
									<Typography variant="subtitle2">mark</Typography>
									<strong>{USD(quote.mark || quote.price).format()}</strong>
								</Grid>
								<Grid item>
									<Typography variant="subtitle2">ask</Typography>
									<strong>{USD(quote.ask).format()}</strong>
								</Grid>
							</Grid>
						</Box>
					)}
				</Card>
			</Fragment>
		);
	}
	return null;
};

const mapStateToProps = (state, ownProps) => {

	const { service, symbol } = ownProps;
	let { quote } = ownProps;

	if (!quote) {
		quote = quoteSelectors.getQuote(state, { service, symbol });
	}

	return {
		quote,
	};
};

const mapDispatchToProps = {
	subscribe: socketOperations.subscribe,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(withTheme(QuoteCard));
