import React, { Fragment } from 'react';


const Price = ({
	price,
	decimals = 5,
}) => {
	if (price && price.toFixed) {
		return (
			<Fragment>
				{price.toFixed(decimals).slice(0, -1)}
				<small><sup>{price.toFixed(decimals).slice(-1)}</sup></small>
			</Fragment>
		);
	}
	return null;
};

export default Price;
