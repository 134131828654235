import types from './types';


export const socketConnect = () => ({ type: types.SOCKET.CONNECT, meta: {} });
export const socketConnecting = () => ({ type: types.SOCKET.CONNECTING, meta: {} });
export const socketConnected = () => ({ type: types.SOCKET.CONNECTED, meta: {} });
export const socketDisconnect = () => ({ type: types.SOCKET.DISCONNECT, meta: {} });
export const socketDisconnected = () => ({ type: types.SOCKET.DISCONNECTED, meta: {} });
export const sendMessage = (message) => ({ type: types.SOCKET.MESSAGE.SEND, payload: message, meta: { type: message.type } });
export const receiveMessage = (message) => ({ type: types.SOCKET.MESSAGE.RECEIVE, payload: message, meta: { type: message.type } });

export const subscribe = (options) => {
	return ({
		type: types.SOCKET.SUBSCRIBE,
		payload: options,
	})
};

export default {
	socketConnect,
	socketConnecting,
	socketConnected,
	socketDisconnect,
	socketDisconnected,
	sendMessage,
	receiveMessage,
	subscribe,
};
