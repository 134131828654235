import React, { Fragment } from 'react';
import {connect} from 'react-redux';
import {Form, Formik} from 'formik';

import { Box, Grid, Button, TextField, Typography, withTheme} from '@material-ui/core';

import {operations as authOperations, selectors as authSelectors} from '@@Redux/auth';


const Oanda = ({
	oanda,
	postOandaAccessToken,
}) => {
	return (
		<Fragment>
			<Typography component="h2" variant="h3" gutterBottom>
				OANDA Access
			</Typography>

			{oanda.refreshToken && (
				<Fragment>
					{oanda.refreshToken}
				</Fragment>
			)}

			<Formik
				initialValues={{
					token: '',
				}}
				validate={values => {
					const errors = {};
					if (!values.token) {
						errors.token = 'Token  is required.';
					}
					return errors;
				}}
				onSubmit={(values, { setSubmitting }) => {
					setTimeout(() => {
						setSubmitting(false);

						postOandaAccessToken(values.token);
					}, 400);
				}}
			>
				{({
					values,
					errors,
					touched,
					handleChange,
					handleBlur,
					handleSubmit,
					isSubmitting,
				}) => (
					<Form>
						<Grid container spacing={2}>
							<Grid item xs={12} sm={8} md={6}>
								<TextField
									size="small"
									variant="filled"
									fullWidth
									id="token"
									name="token"
									label="Token"
									onBlur={handleBlur}
									onChange={handleChange}
									value={values.token}
									error={(errors.token && touched.token)}
									helperText={(errors.token && touched.token) && errors.token}
								/>
							</Grid>
						</Grid>

						<Box my={2}>
							<Button
								variant="contained"
								color="primary"
								type="submit"
								disabled={isSubmitting || !values.token}
							>
								Save Token
							</Button>
						</Box>

					</Form>
				)}
			</Formik>
		</Fragment>
	);
};

const mapStateToProps = (state) => {
	return {
		oanda: authSelectors.getOanda(state),
	};
};

export default connect(
	mapStateToProps,
	{
		postOandaAccessToken: authOperations.postOandaAccessToken,
	},
)(withTheme(Oanda));

