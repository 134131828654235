import produce from 'immer';

import { messageTypes } from '@@Constants';
import types from '@@Redux/socket/types';


const quotes = (state = {}, action) => produce(state, draft => {
	switch (action.type) {
		case types.SOCKET.MESSAGE.RECEIVE: {
			const message = action.payload;

			switch (message.type) {
				case messageTypes.AMERITRADE.QUOTE:
				case messageTypes.AMERITRADE.OPTION:
				case messageTypes.AMERITRADE.LEVELONE_FUTURES: {
					const { data, service } = message;
					const { symbol, bid, ask } = data;

					if (!draft[service]) {
						draft[service] = {};
					}
					if (!draft[service][symbol]) {
						draft[service][symbol] = {};
					}

					draft[service][symbol] = {
						...draft[service][symbol],
						...data
					};

					return;
				}
				case messageTypes.OANDA.PRICE: {
					const { data, service } = message;
					const { instrument, bid, ask } = data;

					if (!draft[service]) {
						draft[service] = {};
					}
					if (!draft[service][instrument]) {
						draft[service][instrument] = {};
					}

					draft[service][instrument] = {
						...draft[service][instrument],
						...data
					};

					return;

				}
				default: return state;
			}
		}
	}
});

const reducer = quotes;

export default reducer;
