import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';

import { configureAmplify } from '@@/aws.config';
import App from '@@Components/App';
import ErrorBoundary from '@@Components/ErrorBoundary';
import configureStore, { history } from '@@Redux/store';


configureAmplify();

ReactDOM.render(
	<ErrorBoundary>
		<Provider store={configureStore()}>
			<ConnectedRouter history={history}>
				<App/>
			</ConnectedRouter>
		</Provider>
	</ErrorBoundary>,
	document.getElementById('root')
);
