const BACKTEST = {
	name: 'backtest/BACKTEST',
	REQUEST: 'backtest/BACKTEST_REQUEST',
	SUCCESS: 'backtest/BACKTEST_SUCCESS',
	FAILURE: 'backtest/BACKTEST_FAILURE',
};

export default {
	BACKTEST
};
