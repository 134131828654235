export const agentStatuses = {
	INITIALIZED: 'INITIALIZED',
	IDLE: 'IDLE',
	ERROR: 'ERROR',
	ACTIVE: 'ACTIVE',
	FINISHED: 'FINISHED',
	BUY: 'BUY',
	BUY_PENDING: 'BUY_PENDING',
	BUY_QUEUED: 'BUY_QUEUED',
	SELL: 'SELL',
	SELL_PENDING: 'SELL_PENDING',
	SELL_QUEUED: 'SELL_QUEUED',
};
