import produce from 'immer';
import { combineReducers } from 'redux';
import moment from 'moment';

import { camelizeKeys } from 'humps';

import types from './types';


const ameritrade = (state = {}, action) => produce(state, draft => {
	switch (action.type) {
		case types.TOKEN_POST.SUCCESS: {
			const result = camelizeKeys(action.payload);
			draft.accessToken = result.accessToken;
			draft.refreshToken = result.refreshToken;
			draft.scope = result.scope;
			draft.refreshTokenExpiresAt = Number(moment().add(result.refreshTokenExpiresIn, 'seconds').format('X'));
			draft.accessTokenExpiresAt = Number(moment().add(result.expiresIn, 'seconds').format('X'));
			localStorage.setItem('td', JSON.stringify(draft));
			return;
		}
		case types.AMERITRADE_CREDENTIALS.SUCCESS: {
			return action.payload;
		}
		case types.TOKEN_REFRESH.SUCCESS:
			const result = camelizeKeys(action.payload);
			draft.accessToken = result.accessToken;
			draft.accessTokenExpiresAt = result.accessTokenExpiresAt;
			localStorage.setItem('td', JSON.stringify(draft));
			return;
		case types.SIGN_OUT.SUCCESS: {
			return {};
		}
		default: return state;
	}
});

const oanda = (state = {}, action) => produce(state, draft => {

});

const session = (state = {}, action) => produce(state, draft => {
	switch (action.type) {
		case types.SESSION.SUCCESS:
		case types.SIGN_IN.SUCCESS: {
			return JSON.parse(JSON.stringify(action.payload));
		}
		case types.SIGN_OUT.SUCCESS: {
			localStorage.clear();
			return {};
		}
		default: return state;
	}
});

const aws = (state = {}, action) => produce(state, draft => {
	switch (action.type) {
		case types.AWS_CREDENTIALS.SUCCESS: {
			return action.payload;
		}
		case types.SIGN_OUT.SUCCESS: {
			return {};
		}
		default: return state;
	}
});

const reducer = combineReducers({
	aws,
	session,
	ameritrade,
	oanda,
});

export default reducer;
