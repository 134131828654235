const TRANSACTIONS = {
	name: 'transactions/TRANSACTIONS',
	REQUEST: 'transactions/TRANSACTIONS_REQUEST',
	SUCCESS: 'transactions/TRANSACTIONS_SUCCESS',
	FAILURE: 'transactions/TRANSACTIONS_FAILURE',
};

const TRANSACTION = {
	name: 'transactions/TRANSACTION',
	REQUEST: 'transactions/TRANSACTION_REQUEST',
	SUCCESS: 'transactions/TRANSACTION_SUCCESS',
	FAILURE: 'transactions/TRANSACTION_FAILURE',
};

export default {
	TRANSACTIONS,
	TRANSACTION,
};
