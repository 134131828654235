
const CHAIN = {
	name: 'chains/CHAIN',
	REQUEST: 'chains/CHAIN_REQUEST',
	SUCCESS: 'chains/CHAIN_SUCCESS',
	FAILURE: 'chains/CHAIN_FAILURE',
};


export default {
	CHAIN,
};
