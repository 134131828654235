import actions from './actions';


const getChain = (symbol, expiration, interval) => (dispatch, getState) => {
	console.info('Operation: getChain()', symbol, expiration, interval);
	return dispatch(actions.getChain(symbol, expiration, interval));
};


export {
	getChain,
};
