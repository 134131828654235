import { decamelizeKeys as decamelizeKeysHumps  } from 'humps';
import currency from 'currency.js';

import api from './api';


const normalize = (value, minValue = 0, maxValue) => {
	return ((value - minValue) / (maxValue - minValue)) * 100;
};

const truncate = (input, length= 5) => {
	return input.length > length ? `${input.substring(0, length)}…` : input;
};

// const currency = (value, symbol = false) => {
// 	// eslint-disable-next-line no-restricted-globals
// 	if (isNaN(value) || value === null) return null;
// 	console.log('sy', symbol, value);
// 	if (symbol) {
// 		return value.toLocaleString(
// 			'en-US',
// 			{
// 				style: 'currency',
// 				currency: 'USD',
// 			},
// 		);
// 	}
// 	return value.toLocaleString(
// 		'en-US',
// 		{
// 			style: 'decimal',
// 			maximumFractionDigits: 2,
// 			minimumFractionDigits: 2,
// 		},
// 	);
// };

const getPipFactor = (instrument) => {
	if (instrument.pipLocation) {
		return Math.pow(10, Math.abs(instrument.pipLocation));
	}
	return instrument.split('_').includes('JPY') ? 100 : 10000;
};

const calculatePipValue = (pair, price, units = 1, referencePair, referencePrice) => {
	/** https://www.earnforex.com/guides/pip-value-formula **/

	const [baseCurrency, quoteCurrency] = pair.split('_');

	let value = 0;
	let size = 0.0001;
	if (quoteCurrency === 'JPY') {
		size = 0.01
	}
	if (quoteCurrency === 'USD') {
		value = (size * units);
	} else if (baseCurrency === 'USD') {
		value = (size / price) * units;
	} else {
		if (referencePair.split('_')[1] === 'USD') {
			//use bid rate
			value = units * size * referencePrice;
		} else if (referencePair.split('_')[0] === 'USD') {
			// use ask rate
			value = units * size / referencePrice;
		}
	}

	return value;
};

const arrayToString = (bufferValue) => {
	const string = new TextDecoder('utf-8').decode(bufferValue);
	return string;
};

const stringToArray = (bufferString) => {
	let uint8Array = new TextEncoder('utf-8').encode(bufferString);
	return uint8Array;
};

const decamelizeKeys = (obj) => {
	return decamelizeKeysHumps(obj, { separator: '-' });
};

const USD = (value, symbol='$', precision=2) => {
	return currency(value, {symbol, precision});
};


export {
	api,
	arrayToString,
	calculatePipValue,
	currency,
	decamelizeKeys,
	getPipFactor,
	normalize,
	stringToArray,
	truncate,
	USD,
}
