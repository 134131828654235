const SOCKET = {
	CONNECT: 'socket/CONNECT',
	CONNECTING: 'socket/CONNECTING',
	CONNECTED: 'socket/CONNECTED',
	DISCONNECT: 'socket/DISCONNECT',
	DISCONNECTED: 'socket/DISCONNECTED',
	MESSAGE: {
		SEND: 'socket/MESSAGE_SEND',
		RECEIVE: 'socket/MESSAGE_RECEIVE',
	},
	SUBSCRIBE: 'socket/SUBSCRIBE',
};

export default {
	SOCKET,
};
