import actions from './actions';


const getOrders = () => (dispatch) => {
	console.info('Operation: getOrders()');
	return dispatch(actions.getOrders());
};

const getOrder = (orderId) => (dispatch) => {
	console.info('Operation: getOrder()', orderId);
	return dispatch(actions.getOrder(orderId));
};

const createOrder = () => (dispatch) => {
	console.info('Operation: createOrder()');
	return dispatch(actions.createOrder());
};

const cancelOrder = (orderId) => (dispatch) => {
	console.info('Operation: cancelOrder()', orderId);
	dispatch(actions.cancelOrder(orderId))
		.then(() => dispatch(actions.getOrders()));
};


export {
	getOrders,
	getOrder,
	createOrder,
	cancelOrder,
};
