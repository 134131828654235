
const getPositions = (state, underlyingSymbol = 'TSLA', showCashPositions = false) => {
	let positions = Object.values(state.positions) || [];
	if (underlyingSymbol) {
		positions = positions.filter((position) => {
			return position.instrument.underlyingSymbol === underlyingSymbol;
		})
	}
	positions.sort((a, b) => {
		if (a.instrument.putCall < b.instrument.putCall) {
			return -1;
		} else if (a.instrument.putCall === b.instrument.putCall) {
			return (a.instrument.symbol > b.instrument.symbol) ? -1 : 1;
		} else
			return 1;
	} );
	if (!showCashPositions) {
		positions = positions.filter((position) => {
			return position.instrument.assetType !== 'CASH_EQUIVALENT';
		});
	}
	return positions;
};

const getPosition = (state, id) => {
	return state.positions.positions.find(position => position.id === id) || {};
};


export {
	getPosition,
	getPositions,
};
