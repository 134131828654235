import React, { useEffect, useState } from 'react';
import { Box, Grid, Button, withTheme } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { connect } from 'react-redux';

import AgentCard from './AgentCard/AgentCard';
import AgentDialog from './AgentDialog';

import { selectors as agentSelectors, operations as agentOperations } from '@@Redux/agents';


const AgentDashboard = (props) => {

	useEffect(() => {
		props.getAgents();
	}, []);

	const [dialogIsOpen, setDialogIsOpen] = useState(false);
	const [selectedAgent, setSelectedAgent] = useState({});

	const openDialog = () => {
		setSelectedAgent(null);
		setDialogIsOpen(true);
	};

	const handleEdit = (agent) => {
		console.log('Edit Agent:', agent);
		setDialogIsOpen(true);
		setSelectedAgent(agent);
	};

	const handleDelete = (agent) => {
		console.log('Delete Agent:', agent);
		props.deleteAgent(agent);
	};

	const handleStart = (agent) => {
		console.log('Start Agent:', agent);
		props.startAgent(agent);
	};

	const handleStop = (agent) => {
		console.log('Stop Agent:', agent);
		props.stopAgent(agent);
	};

	const closeDialog = () => {
		setDialogIsOpen(false);
	};

	return (
		<Box my={2}>

			<Button variant="contained" color="primary" onClick={openDialog}>
				Add Options Agent
			</Button>

			<Grid container>
				{props.agents.map((agent) => (
					<Grid key={agent.id} item xs={12}>
						{agent && agent.status && (
							<AgentCard
								agent={agent}
								onDelete={handleDelete}
								onStart={handleStart}
								onStop={handleStop}
							/>
						)}
					</Grid>
				))}
			</Grid>

			<AgentDialog isOpen={dialogIsOpen} onClose={closeDialog} agent={selectedAgent} />

		</Box>
	)
};


const mapStateToProps = (state) => {
	return {
		agents: agentSelectors.getAgents(state, 'OPTION'),
		messages: agentSelectors.getAgentMessages(state),
	};
};

const mapDispatchToProps = {
	getAgents: agentOperations.getAgents,
	deleteAgent: agentOperations.deleteAgent,
	startAgent: agentOperations.startAgent,
	stopAgent: agentOperations.stopAgent,
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(withTheme(AgentDashboard));
