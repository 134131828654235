import { RSAA } from 'redux-api-middleware';

import config from '@@Config';
import types from './types';


const refreshAmeritradeAccessToken = (userId, identityId, email) => {
	const url = `${config.api.hostname}/auth/token/refresh`;
	const obj = {
		userId,
		identityId,
		email
	};
	return {
		[RSAA]: {
			endpoint: url,
			method: 'POST',
			body: JSON.stringify(obj),
			headers: { ...RSAA.headers, 'Content-Type': 'application/json' },
			types: [types.TOKEN_REFRESH.REQUEST, types.TOKEN_REFRESH.SUCCESS, types.TOKEN_REFRESH.FAILURE],
		},
	};
};

const postOandaAccessToken = (token) => {
	const url = `${config.api.hostname}/auth/token/post/oanda`;
	const obj = { token };
	return {
		[RSAA]: {
			endpoint: url,
			method: 'POST',
			body: JSON.stringify(obj),
			headers: { ...RSAA.headers, 'Content-Type': 'application/json' },
			types: [types.TOKEN_POST.REQUEST, types.TOKEN_POST.SUCCESS, types.TOKEN_POST.FAILURE],
		},
	};
};

const postAmeritradeAccessToken = (code) => {
	const url = `${config.api.hostname}/auth/token/post`;
	const obj = { code };
	return {
		[RSAA]: {
			endpoint: url,
			method: 'POST',
			body: JSON.stringify(obj),
			headers: { ...RSAA.headers, 'Content-Type': 'application/json' },
			types: [types.TOKEN_POST.REQUEST, types.TOKEN_POST.SUCCESS, types.TOKEN_POST.FAILURE],
		},
	};
};

const getAmeritradeFromLocalStorageSuccess = (data) => {
	return {
		type: types.AMERITRADE_CREDENTIALS.SUCCESS,
		payload: data
	}
};

const getAmeritradeFromLocalStorageFailure = (err) => ({
	type: types.AMERITRADE_CREDENTIALS.FAILURE,
	error: true,
	payload: err,
	meta: { hidden: true },
});

const getAWSCredentials = {
	request: () => ({
		type: types.AWS_CREDENTIALS.REQUEST,
		meta: {
			credentials: 'omit',
			message: 'Signing In',
		},
	}),
	success: (data) => ({
		type: types.AWS_CREDENTIALS.SUCCESS,
		payload: data,
	}),
	failure: (err) => ({
		type: types.AWS_CREDENTIALS.FAILURE,
		error: true,
		payload: err,
	}),
};

const getSession = {
	request: () => ({
		type: types.SESSION.REQUEST,
	}),
	success: (data) => ({
		type: types.SESSION.SUCCESS,
		payload: data,
	}),
	failure: (err) => ({
		type: types.SESSION.FAILURE,
		error: true,
		payload: err,
	}),
};

const signIn = {
	request: (params) => ({
		type: types.SIGN_IN.REQUEST,
		payload: params,
		meta: {
			credentials: 'omit',
			message: 'Signing In',
		},
	}),
	success: (data) => ({
		type: types.SIGN_IN.SUCCESS,
		payload: data,
	}),
	failure: (err) => ({
		type: types.SIGN_IN.FAILURE,
		error: true,
		payload: err,
	}),
};

const signOut = {
	request: () => ({
		type: types.SIGN_OUT.REQUEST,
		meta: {
			message: 'Signing Out',
		},
	}),
	success: () => ({
		type: types.SIGN_OUT.SUCCESS,
	}),
	failure: (err) => ({
		type: types.SIGN_OUT.FAILURE,
		error: true,
		payload: err,
	}),
};


export default {
	refreshAmeritradeAccessToken,
	postAmeritradeAccessToken,
	postOandaAccessToken,
	getAmeritradeFromLocalStorageSuccess,
	getAmeritradeFromLocalStorageFailure,
	getAWSCredentials,
	getSession,
	signIn,
	signOut,
}
