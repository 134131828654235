import { createSelector } from 'reselect';


const getAccount = (state, props) => {
	if (props && props.name) {
		return state.account[props.name];
	}
	return state.account || {};
};

const getAccountInstruments = (state, asArray, enabledOnly = true) => {
	let instruments = {};
	if (state.account.oanda && state.account.oanda.instruments) {
		instruments = state.account.oanda.instruments;
	}
	if (instruments && asArray) {
		instruments = Object.values(instruments);
		instruments.sort((a, b) => {
			if (a.name > b.name) return 1;
			if (a.name < b.name) return -1;
			return 0;
		});

		if (enabledOnly) {
			instruments = instruments.filter((instrument) => instrument.enabled)
		}

		instruments = instruments.map((instrument) => {
			return { ...instrument };
		});
	}
	return instruments;
}

const getBuyingPower = createSelector(
	getAccount,
	(account) => {
		return account.currentBalances
			? account.currentBalances.availableFundsNonMarginableTrade
			: 0;
	}
);

const getOpenPositions = createSelector(
	getAccount,
	(account) => {
		return account.positions || [];
	}
);

export {
	getAccount,
	getAccountInstruments,
	getBuyingPower,
	getOpenPositions,
}
