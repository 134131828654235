import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTheme, Typography, Box, Divider } from '@material-ui/core';

import DebugOutput from '@@Components/DebugOutput';
import { operations as orderOperations, selectors as orderSelectors } from '@@Redux/orders';;

import OrdersList from './OrdersList';


const propTypes = {
	cancelOrder: PropTypes.func,
	getOrders: PropTypes.func,
	orders: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
};

const Orders = ({
	cancelOrder,
	getOrders,
	orders = [],
}) => {

	useEffect(() => {
		async function fetchData() {
			await getOrders();
		}
		fetchData();
	}, [getOrders]);

	const handleCancel = (orderId) => {
		cancelOrder(orderId);
	};

	return (
		<Box m={2}>
			<Typography gutterBottom variant="h1">
				Orders
			</Typography>

			<OrdersList
				orders={orders}
				onCancel={handleCancel}
			/>

			<Divider />

			<DebugOutput
				data={orders}
				label="orders"
			/>

		</Box>
	)
};

Orders.propTypes = propTypes;

const mapStateToProps = (state) => {
	return {
		orders: orderSelectors.getOrders(state),
	};
};

const mapDispatchToProps = {
	cancelOrder: orderOperations.cancelOrder,
	getOrders: orderOperations.getOrders,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(withTheme(Orders));
