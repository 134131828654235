import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { withTheme, Box, Card, Divider, Grid, Typography, CardHeader } from '@material-ui/core';
import Moment from 'react-moment';

import { getPipFactor } from '@@Utils';
import { selectors as quoteSelectors } from '@@Redux/quotes';
import { operations as socketOperations } from '@@Redux/socket';

import Price from '@@Components/Forex/Price';

const QuoteCard = ({
	service,
	subscribe,
	instrument,
	quote,
}) => {

	const symbol = instrument.name;
	const pipFactor = getPipFactor(instrument)
	const decimals = instrument.displayPrecision;

	useEffect(() => {
		if (service && symbol) {
			subscribe({ service, symbol });
		}
	}, [service, symbol]);

	if (symbol) {
		return (
			<Box>
				<Card variant="outlined">
					{(quote && quote.bid && quote.ask) && (
						<Box p={2}>
							<Grid container spacing={5}>
								<Grid item>
									<Typography variant="caption" component="div">BID</Typography>
									<Typography variant="h5">
										<Price price={quote.bid} decimals={decimals} />
									</Typography>
								</Grid>
								<Divider orientation="vertical" flexItem />
								<Grid item>
									<Typography variant="caption" component="div">SPREAD</Typography>
									{((quote.ask - quote.bid) * pipFactor).toFixed(1)}
								</Grid>
								<Divider orientation="vertical" flexItem />
								<Grid item>
									<Typography variant="caption" component="div">ASK</Typography>
									<Typography variant="h5">
										<Price price={quote.ask} decimals={decimals} />
									</Typography>
								</Grid>
							</Grid>
						</Box>
					)}
				</Card>
				{quote && quote.timestamp && (
					<Box>
						<Typography variant="caption">
							Last Updated:&nbsp;
							<Moment parse="X" date={quote.timestamp} />
						</Typography>
					</Box>
				)}
			</Box>
		);
	}
	return null;
};

const mapStateToProps = (state, ownProps) => {
	const { service, symbol } = ownProps;
	let { quote } = ownProps;

	if (!quote) {
		quote = quoteSelectors.getQuote(state, { service, symbol });
	}

	return {
		quote,
	};
};

const mapDispatchToProps = {
	subscribe: socketOperations.subscribe,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(withTheme(QuoteCard));
