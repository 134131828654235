import { RSAA } from 'redux-api-middleware';

import config from '@@Config';
import types from './types';


const getChain = (symbol, expiration, interval) => {
	let url = `${config.api.hostname}/chains/${symbol}?`;
	if (interval) {
		url += `&interval=${interval}`;
	}
	if (expiration) {
		url += `&expiration=${expiration}`;
	}

	console.info('getChain', url);

	return {
		[RSAA]: {
			endpoint: url,
			method: 'GET',
			headers: { ...RSAA.headers, 'Content-Type': 'application/json' },
			types: [
				{
					type: types.CHAIN.REQUEST,
					meta: {
						message: 'Getting Option Chain',
					},
				},
				{
					type: types.CHAIN.SUCCESS,
					meta: { interval },
				},
				types.CHAIN.FAILURE
			],
		},
	};
};

export default {
	getChain,
}
