export const messageTypes = {
	AGENT: {
		CONNECT: 'AGENT:CONNECT',
		SUBSCRIBE: 'AGENT:SUBSCRIBE',
		UNSUBSCRIBE: 'AGENT:UNSUBSCRIBE',
		STATUS: 'AGENT:STATUS',
		DISCONNECT: 'AGENT:DISCONNECT',
	},
	DATASET: 'DATASET',
	HEARTBEAT: 'HEARTBEAT',
	AMERITRADE: {
		ACTIVES: 'AMERITRADE:ACTIVES',
		HEARTBEAT: 'AMERITRADE:HEARTBEAT',
		MESSAGE: 'AMERITRADE:MESSAGE',
		QUOTE: 'AMERITRADE:QUOTE',
		OPTION: 'AMERITRADE:OPTION',
		CHART_EQUITY: 'AMERITRADE:CHART_EQUITY',
		CHART_FUTURES: 'AMERITRADE:CHART_FUTURES',
		LEVELONE_FUTURES: 'AMERITRADE:LEVELONE_FUTURES',
	},
	OANDA: {
		PRICE: 'OANDA:PRICE',
	},
	MESSAGE: {
		ALL: 'MESSAGE:ALL',
		AGENT: 'MESSAGE:AGENT',
		BROWSER: 'MESSAGE:BROWSER',
	}
};
