import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { serializeError } from 'serialize-error';

import { Box, Typography } from '@material-ui/core';


class ErrorBoundary extends Component {

	static propTypes = {
		children: PropTypes.element.isRequired,
	}

	constructor(props) {
		super(props);

		this.state = {
			hasError: false,
			stack: null,
		};
	}

	static getDerivedStateFromError(error) {
		const errorObj = serializeError(error);

		return {
			hasError: true,
			stack: errorObj.stack,
		};
	}

	componentDidCatch(error, errorInfo) {
		console.error(error);
	}

	render() {
		if (this.state.hasError) {
			return (
				<Box p={2}>
					<Typography variant="h1">
						Error
					</Typography>

					{this.state.errorInfo && (
						<Typography variant="body">
							{this.state.errorInfo.componentStack}
						</Typography>
					)}

					<Typography variant="body2">
						{this.state.stack}
					</Typography>
				</Box>
			);
		}
		return this.props.children;
	}
}


export default ErrorBoundary;
