import { all, takeLatest, put, fork } from 'redux-saga/effects';

import { api } from '@@Utils';

import actions from './actions';
import types from './types';

/**
 Workers
 */
function* fetchAccount(action) {
	const params = action.payload;
	const { name } = params;

	try {
		const { data } = yield api.get(`/account?name=${name}`);
		yield put(actions.getAccount.success(data, params));
	}
	catch (err) {
		yield put(actions.getAccount.failure(err));
	}
}

function* fetchAccountInstruments(action) {
	const params = action.payload;
	const { name } = params;

	try {
		const { data } = yield api.get(`/account/instruments?name=${name}`);
		yield put(actions.getAccountInstruments.success(data, params));
	}
	catch (err) {
		yield put(actions.getAccountInstruments.failure(err));
	}
}

/**
 * Watchers
 */
function *watchAccount() {
	try {
		yield takeLatest(types.ACCOUNT.REQUEST, fetchAccount);
	} catch(err) {
		yield put(actions.getAccount.failure(err));
	}
}

function *watchAccountInstruments() {
	try {
		yield takeLatest(types.ACCOUNT_INSTRUMENTS.REQUEST, fetchAccountInstruments);
	} catch(err) {
		yield put(actions.getAccountInstruments.failure(err));
	}
}

/**
 * Sagas
 */
function* sagas() {
	yield all([
		fork(watchAccount),
		fork(watchAccountInstruments),
	]);
}

export default sagas;
