import React, { Fragment } from 'react';
import prettyMs from 'pretty-ms';
import Moment from 'react-moment';

import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Grid,
	Divider,
	ButtonGroup,
	Button,
	Box,
	CardContent,
	CardActions,
	LinearProgress,
	Typography,
} from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import StopIcon from '@material-ui/icons/Stop';
import { Skeleton, Alert } from '@material-ui/lab';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FlightTakeoffIcon from '@material-ui/icons/FlightTakeoff';

import DebugOutput from '@@Components/DebugOutput';
import Price from "@@Components/Forex/Price";
import { agentStatuses, iterationStatuses } from '@@Constants';
import { USD, normalize } from '@@Utils';

import QuoteCard from './QuoteCard';
import * as Styles from './AgentCard.styles';
import orderActions from '@@Constants/orderActions';
import {table as tableDefaults} from '@@Config';
import tableIcons from '@@Theme/tableIcons';
import {colors} from '@@Theme';
import MaterialTable from 'material-table';


const AgentCard = ({
	agent,
	onDelete,
	onStart,
	onStop,
}) => {

	const { status } = agent;

	if (status) {

		const { config, dataset, quotes, positions, agentStatus, iterations, agentPlPips, agentPl, trades, orders } = status;

		const instrument = config.instruments.core;
		const decimals = instrument.displayPrecision;
		const quote = quotes[instrument.name];

		let plPips = 0;

		return (
			<Box>
				<Styles.Card>

					<Styles.CardHeader title={
						<Box>
							<Grid container justifyContent="space-between" alignItems="flex-start">
								<Grid item xs={8}>
									<Typography component="h6" variant="h6">
										{config.name}
									</Typography>

									{/*<DebugOutput data={config} label="config" />*/}

								</Grid>
								<Grid item style={{ textAlign: 'right' }}>
									{status && status.agentStatus && (
										<Fragment>
											<Styles.StatusBadge
												status={status.agentStatus}
											>
												{status.agentStatus}
											</Styles.StatusBadge>

											{status.error && (
												<Typography component="div" variant="caption" color="error" gutterBottom>
													{status.error}
												</Typography>
											)}
										</Fragment>
									)}
									<Typography variant="caption" component="div" align="right" gutterBottom>
										{status.duration ? prettyMs(status.duration, { secondsDecimalDigits: 0 }) : null}
									</Typography>
								</Grid>
							</Grid>
							<Grid container spacing={4} justifyContent="space-between" alignItems="flex-start">
								<Grid item xs={12}>
									<Typography color="primary" variant="h3" gutterBottom>
										<strong>{instrument && instrument.name.replace('_', '/')}</strong>
									</Typography>

									<Grid container spacing={4} justifyContent="space-between" alignItems="center">
										<Grid item>
											<QuoteCard
												instrument={instrument}
												service="FOREX"
												quote={status.quotes[instrument.name]}
											/>
										</Grid>
										<Divider flexItem orientation="vertical" />
										<Grid item>
											<Typography variant="caption" component="div">
												Initial Units
											</Typography>
											{config.initialUnits}
										</Grid>
										<Grid item>
											<Typography variant="caption" component="div">
												Max Level
											</Typography>
											{config.maxLevel}
										</Grid>
										<Grid item>
											<Typography variant="caption" component="div">
												Level Increment
											</Typography>
											{config.levelIncrement} SD
										</Grid>
										<Grid item>
											<Typography variant="caption" component="div">
												Level Multiplier
											</Typography>
											{config.levelMultiplier}&times;
										</Grid>
										<Grid item>
											<Typography variant="caption" component="div">
												Target Pips
											</Typography>
											{config.targetPips}
										</Grid>
										<Grid item>
											<Typography variant="caption" component="div">
												Stop-Loss Pips
											</Typography>
											{config.stopLossPips}
										</Grid>
										{/*<Grid item xs>
											{pipValue && Math.abs(position.units) > 0 && (
												<Fragment>
													Pip Value: {pipValue / Math.abs(position.units)} / {pipValue}<br />
												</Fragment>
											)}
										</Grid>*/}
										{/*<Grid item xs>
											<Typography variant="caption" component="div">
												Max Loss (per Iteration)
											</Typography>
											-{USD(config.stopLossPips * pipValue)}
										</Grid>*/}
										<Grid item style={{ marginLeft: 'auto' }}>
											<Typography variant="caption" component="div">
												Realized Pips
											</Typography>
											<Typography variant="h5" color={agentPl < 0 ? 'error' : 'primary'}>
												{agentPlPips.toFixed(1)}
											</Typography>
										</Grid>
										<Grid item>
											<Typography variant="caption" component="div">
												Realized P/L Rate
											</Typography>
											<Typography variant="h5" color={agentPl < 0 ? 'error' : 'primary'}>
												{USD(agentPl / (status.duration / 1000 / 60 / 60)).format()}/hr
											</Typography>
										</Grid>
										<Grid item>
											<Typography variant="caption" component="div">
												Realized P/L
											</Typography>
											<Typography variant="h5" color={agentPl < 0 ? 'error' : 'primary'}>
												{USD(agentPl).format()}
											</Typography>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Box>
					}>
					</Styles.CardHeader>

					<CardContent>

						{/*<DebugOutput
							data={iterations}
							label="iterations"
						/>*/}


						{/*<DebugOutput data={trades} label="trades" />*/}
						{/*<DebugOutput data={orders} label="orders" />*/}

						{quote && Object.values(iterations).map((iteration) => {

							if (iteration.position.type === 'SHORT') {
								plPips = (iteration.position.averagePrice - quote.ask) * instrument.pipFactor;
							} else if (iteration.position.type === 'LONG') {
								plPips = (quote.bid - iteration.position.averagePrice) * instrument.pipFactor;
							}

							const progressPercentage = Math.min(normalize(((quote.bid + quote.ask) / 2), iteration.nextLevelPrice, iteration.targetPrice), 100);

							const filledOrders = Object.values(iteration.orders['FILLED']).map((order) => { return {...order} });

							return (
								<Fragment key={iteration.id}>
									{/*defaultExpanded={iteration.status === 'POSITION_OPEN'}*/}
									<Accordion>
										<AccordionSummary
											expandIcon={<ExpandMoreIcon color="primary" />}
											aria-controls="panel1a-content"
											id="panel1a-header"
										>
											<Grid container spacing={2} justifyContent="space-between" alignItems="center">
												<Grid item>
													<Styles.StatusBadge
														title={iteration.id}
														status={iteration.status}
													>
														{iteration.status.replaceAll('_', ' ')}
													</Styles.StatusBadge>
												</Grid>
												{iteration.level && (
													<Grid item>
														Level: <strong>{Math.abs(iteration.level)}</strong>
													</Grid>
												)}
												<Grid item>
													Unrealized PL: {USD(iteration.unrealizedPl).format()}
												</Grid>
												<Grid item>
													Realized PL: {USD(iteration.realizedPl).format()}
												</Grid>
												<Grid item>
													Margin Used: {USD(iteration.marginUsed).format()}
												</Grid>
												<Grid item>
													{iteration.duration ? prettyMs(iteration.duration, { secondsDecimalDigits: 0 }) : null}
												</Grid>
												{iteration.trailingStopLoss.isActive && (
													<Grid item>
														<FlightTakeoffIcon color="primary" title="Trailing Stop Loss Active" />
													</Grid>
												)}
												<Grid item style={{ marginLeft: 'auto' }}>
													<Typography variant="body2" color={iteration.plPips < 0 ? 'error' : 'primary'}>
														{iteration.plPips.toFixed(1)} pips / {USD(iteration.pl).format()}
													</Typography>
												</Grid>
											</Grid>

											{/*created: {iteration.createdAt} {typeof iteration.createdAt}<br />*/}
											{/*closed: {iteration.closedAt} {typeof iteration.closedAt}<br />*/}

										</AccordionSummary>
										<AccordionDetails>

											<Box my={2} width="100%">

												<Grid container>
													{iteration.status === 'POSITION_OPEN' && (
														<Grid item xs={12}>
															{/*((input - min) * 100) / (max - min)*/}
															{/*{((quote.bid - iteration.nextLevelPrice) * 100) / (iteration.targetPrice - iteration.nextLevelPrice)}*/}
															<Box mb={3}>
																<LinearProgress
																	variant="determinate"
																	value={progressPercentage}
																	color="primary"
																/>
															</Box>
														</Grid>
													)}
												</Grid>

												{/*<DebugOutput data={iteration} />*/}
												<Grid container spacing={4} justifyContent="flex-start">
													{/*<Grid item>
														<Box my={1}>
															<Styles.StatusBadge
																title={iteration.id}
																status={iteration.status}
															>
																{iteration.status.replaceAll('_', ' ')}
															</Styles.StatusBadge>
														</Box>
													</Grid>*/}
													{iteration.level && (
														<Grid item>
															<Box mx={2}>
																<Typography variant="caption" component="div">
																	Level
																</Typography>
																<Typography variant="h2">
																	{Math.abs(iteration.level)}
																</Typography>
															</Box>
														</Grid>
													)}
													<Grid item>
														<Typography variant="caption" component="div">
															Position
														</Typography>
														<strong>{iteration.position.type}&nbsp;&nbsp;{iteration.position.units}</strong>
														&nbsp;@&nbsp;<Price price={iteration.position.averagePrice} decimals={decimals} />
													</Grid>
													{iteration.status === 'POSITION_OPEN' && (
														<Fragment>
															<Grid item>
																<Typography variant="caption" component="div">
																	Next Level
																	{iteration.position.type === 'LONG' && (
																		<Fragment> (Ask) </Fragment>
																	)}
																	{iteration.position.type === 'SHORT' && (
																		<Fragment> (Bid) </Fragment>
																	)}
																</Typography>
																{Math.abs(iteration.level) < config.maxLevel && (
																	<Price price={iteration.nextLevelPrice} decimals={decimals} />
																)}
																{Math.abs(iteration.level) >= config.maxLevel && (
																	<Fragment>
																		MAX LEVEL
																	</Fragment>
																)}
															</Grid>
															<Grid item>
																<Typography variant="caption" component="div">
																	Target
																	{iteration.position.type === 'LONG' && (
																		<Fragment> (Bid) </Fragment>
																	)}
																	{iteration.position.type === 'SHORT' && (
																		<Fragment> (Ask) </Fragment>
																	)}
																</Typography>
																<Price price={iteration.targetPrice} decimals={decimals} />
															</Grid>
															<Grid item>
																<Typography variant="caption" component="div">
																	Target Gain
																</Typography>
																{USD(config.targetPips * iteration.pipValue).format()}
															</Grid>
															<Grid item>
																<Typography variant="caption" component="div">
																	Max Loss
																</Typography>
																-{USD(config.stopLossPips * iteration.pipValue).format()}
															</Grid>
															{iteration.trailingStopLoss && iteration.trailingStopLoss.isActive && (
																<Grid item>
																	<Typography variant="caption" component="div">
																		Trailing Stop (@Pips)
																	</Typography>
																	{iteration.trailingStopLoss.triggerPips.toFixed(1)}
																</Grid>
															)}

															{(plPips && !isNaN(plPips)) && (
																<Fragment>
																	<Grid item style={{ marginLeft: 'auto' }}>
																		<Typography variant="caption" component="div" align="right">
																			Pips
																		</Typography>
																		<Typography variant="body1" color={plPips < 0 ? 'error' : 'primary'}>
																			<strong>{plPips.toFixed(1)}</strong>
																		</Typography>
																	</Grid>
																	<Grid item>
																		<Typography variant="caption" component="div">
																			Unrealized P/L
																		</Typography>
																		<Typography variant="body1" color={plPips < 0 ? 'error' : 'primary'}>
																			<strong>{USD(iteration.pipValue * plPips).format()}</strong>
																		</Typography>
																	</Grid>
																</Fragment>
															)}
														</Fragment>
													)}
												</Grid>


												{/*<DebugOutput
													data={iteration.position}
													label="position"
												/>*/}

												{/*<DebugOutput
													data={iteration.orders}
													label="orders"
												/>*/}

												<Box my={4}>
													{/*<Typography variant="h3" gutterBottom>Orders</Typography>*/}

													<MaterialTable
														{...tableDefaults}
														icons={tableIcons}
														data={filledOrders}
														options={{
															...tableDefaults.options(),
															padding: 'dense',
														}}
														columns={[
															{ title: 'ID', field: 'id' },
															{
																title: 'Date',
																field: 'time',
																cellStyle: { whiteSpace: 'nowrap' },
																render: (rowData) => (
																	<Moment date={Math.round(rowData.time)} parse={'X'} format="YYYY-MM-DD hh:mm:ss A" />
																),
															},
															{ title: 'Instrument', field: 'instrument' },
															{
																title: 'Action',
																render: (rowData) => {
																	return rowData.units < 0 ? 'SELL' : 'BUY';
																},
															},
															{ title: 'Units', field: 'units' },
															{
																title: 'Price',
																field: 'price',
																align: 'right',
																render: (rowData) => (
																	<Price price={rowData.price} decimals={decimals} />
																),
															},
															{ title: 'Reason', field: 'reason' },
														]}
													/>
												</Box>

											</Box>

										</AccordionDetails>
									</Accordion>
								</Fragment>
							);
						})}

						{/*<Divider />

						<DebugOutput
							data={orders['PENDING']}
							label="pending orders"
						/>

						<DebugOutput
							data={orders['FILLED']}
							label="filled orders"
						/>*/}

						{/*<DebugOutput*/}
						{/*	data={status.quotes}*/}
						{/*	label="quotes"*/}
						{/*/>*/}

						{/*<DebugOutput*/}
						{/*	data={dataset.indicators.M5.BB}*/}
						{/*	label="M5.BB"*/}
						{/*/>*/}

						{/*<DebugOutput*/}
						{/*	data={dataset.indicators.H1.EMA_20}*/}
						{/*	label="H1.EMA_20"*/}
						{/*/>*/}

						{/*<DebugOutput*/}
						{/*	data={dataset.indicators.M5.AO}*/}
						{/*	label="M5.AO"*/}
						{/*/>*/}

						{/*<DebugOutput
							data={config.instruments}
							label="instruments"
						/>*/}

						{/*<DebugOutput
							data={orders.PENDING}
							label="pending orders"
						/>*/}

						{/*<DebugOutput
							data={orders}
							label="orders"
						/>*/}

					</CardContent>

					<CardActions>
						<Box m={1}>
							<ButtonGroup variant="contained">

								<Button
									disabled={agentStatus !== agentStatuses.IDLE}
									onClick={() => onStart(agent)}>
									<PlayArrowIcon /> Start
								</Button>

								<Button
									disabled={[agentStatuses.ERROR, agentStatuses.IDLE].includes(agentStatus)}
									onClick={() => onStop(agent)}>
									<StopIcon /> Stop
								</Button>

								<Button
									onClick={() => onDelete(agent)}>
									<DeleteForeverIcon /> Delete
								</Button>

							</ButtonGroup>
						</Box>
					</CardActions>

				</Styles.Card>
			</Box>
		);
	}
	return (
		<Skeleton variant="rect" width="100%" height={100} />
	);
};

export default AgentCard;
