import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import qs from 'qs';

import { Box, Button, Grid, Typography } from '@material-ui/core';


import { operations as agentOperations, selectors as agentSelectors } from '@@Redux/agents';
import { operations as accountOperations, selectors as accountSelectors } from '@@Redux/account';
import { api, decamelizeKeys, USD } from '@@Utils';

import AccountSummary from './AccountSummary';
import AgentDialog from './AgentDialog';
import AgentCard from './AgentCard';

const name = 'oanda';


const Forex = ({
	agents,
	deleteAgent,
	getAccountInstruments,
	getAccount,
	getAgents,
	startAgent,
	stopAgent,
}) => {

	const [dialogIsOpen, setDialogIsOpen] = useState(false);

	useEffect(() => {
		async function fetchData() {
			await getAccount({ name });
			await getAccountInstruments({ name });
			await getAgents();
		}
		fetchData();
	}, [getAccount, getAccountInstruments, getAgents]);

	const openDialog = () => {
		setDialogIsOpen(true);
	};

	const closeDialog = () => {
		setDialogIsOpen(false);
	};

	const handleDelete = (agent) => {
		deleteAgent(agent);
	};

	const handleStart = (agent) => {
		startAgent(agent);
	};

	const handleStop = (agent) => {
		stopAgent(agent);
	};

	const handleCancelAllOrders = () => {
		const options = {
			// orderId: [],
		};
		const params = qs.stringify(decamelizeKeys(options), { arrayFormat: 'brackets', encode: false });
		try {
			api.delete(`/account/orders/cancel?${params}`);
		} catch (err) {
			console.error(err);
		}
	};

	const totalPl = agents.reduce((a, c) => {
		if (c.status) {
			a += c.status.agentPl;
		}
		return a;
	}, 0);

	return (
		<Box>
			<Typography variant="h5" gutterBottom>Forex</Typography>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<AccountSummary />
				</Grid>
				<Grid item xs={12}>
					<Typography variant="caption" component="div">
						Total Realized P/L
					</Typography>
					<Typography variant="h5" color={totalPl < 0 ? 'error' : 'primary'}>
						{USD(totalPl).format()}
					</Typography>
				</Grid>
				<Grid item xs={12} container spacing={2}>
					<Grid item>
						<Button variant="contained" color="primary" onClick={openDialog}>
							Add Forex Agent
						</Button>
					</Grid>
					<Grid item>
						<Button variant="contained" color="secondary" onClick={handleCancelAllOrders}>
							Cancel All Pending Orders
						</Button>
					</Grid>
				</Grid>
				<Grid item xs={12} container spacing={2}>
					{agents.map((agent) => (
						<Grid key={agent.id} item xs={12}>
							<Box mb={2}>
								<AgentCard
									agent={agent}
									onDelete={handleDelete}
									onStart={handleStart}
									onStop={handleStop}
								/>
							</Box>
						</Grid>
					))}
				</Grid>
			</Grid>

			<AgentDialog
				isOpen={dialogIsOpen}
				onClose={closeDialog}
			/>
		</Box>
	);
};

const mapStateToProps = (state) => {
	const account = accountSelectors.getAccount(state, { name });
	const agents = agentSelectors.getAgents(state);

	return {
		account,
		agents,
	};
};

const mapDispatchToProps = {
	getAccount: accountOperations.getAccount,
	getAccountInstruments: accountOperations.getAccountInstruments,
	getAgents: agentOperations.getAgents,
	deleteAgent: agentOperations.deleteAgent,
	startAgent: agentOperations.startAgent,
	stopAgent: agentOperations.stopAgent,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(Forex);
