import styled, { css } from 'styled-components';
import { transparentize } from 'polished';

import { Card, CardHeader, TableCell, TableRow, Typography, withTheme } from '@material-ui/core';

import { agentStatuses, iterationStatuses, orderStatuses } from '@@Constants';



const StyledCard = styled(Card)`
	font-size: 1em;
`;

const StyledCardHeader = withTheme(styled(CardHeader)`
	background-color: ${props => transparentize(0.9, props.theme.palette.primary.main)};
`);

const StyledTableRow = styled(TableRow)`
	${props => {
	switch (props.status) {
		case orderStatuses.FILLED: {
			return css`
					color: #999;
					.MuiTableCell-body {
						color: inherit;
					}
					`;
		}
		case orderStatuses.QUEUED: {
			return css`
					font-weight: 600;
					.MuiTableCell-body {
						font-weight: inherit;
					}
				`;
		}
	}
}}
`
const StatusBadge = styled(Typography)`
	display: inline-block;
	background-color: green;
	color: #fff;
	padding: 0.15em 0.3em;
	border-radius: 0.15em;
	font-weight: bold;
	font-size: 1rem;
	${props => {
		switch (props.status) {
			case agentStatuses.IDLE:
			case iterationStatuses.OPEN_COMPLETE: {
				return css`
						background-color: #999;
					`;
			}
			case agentStatuses.ERROR: {
				return css`
						background-color: red;
					`;
			}
		}
	}}
`;

const StyledDescriptionList = styled.dl`
	display: flex;
	flex-wrap: wrap;
	margin: 1em 0 0;

	dd, dt {
		font-size: 0.75rem;
		display: inline-block;
	}
	dt {
		font-weight: bold;
		width: 50%;
	}
	dd {
		text-align: right;
		margin-left: auto;
	}
`;


export {
	StatusBadge,
	StyledCard as Card,
	StyledCardHeader as CardHeader,
	StyledDescriptionList as DescriptionList,
	StyledTableRow,
}
