// import * as actions from './actions';
// import * as operations from './operations';
import * as selectors from './selectors';
// import * as types from './types';

import reducer from './reducers';

export default reducer;

export {
	// actions,
	// operations,
	selectors,
	// types,
};
