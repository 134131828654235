import { createTheme } from '@material-ui/core/styles';
import { yellow } from '@material-ui/core/colors';
import overrides from './overrides';
import colors from './colors';

export {
	colors,
};

const theme = createTheme({
	overrides,
	palette: {
		primary: colors.primary,
		secondary: colors.secondary,
		active: yellow,
	},
	typography: {
		fontSize: 14,
		fontFamily: [
			'Overpass',
			'-apple-system',
			'BlinkMacSystemFont',
			'"Segoe UI"',
			'Roboto',
			'"Helvetica Neue"',
			'Arial',
			'sans-serif',
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"',
		].join(','),
		h1: {
			fontSize: '2.25rem',
			letterSpacing: -0.25,
		},
		h2: {
			fontSize: '2rem',
		},
		h3: {
			fontSize: '1.75rem',
		},
		button: {
			fontWeight: '600',
			textTransform: 'none',
		},
	},
});

window.theme = theme;

export default theme;
