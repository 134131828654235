import { createGlobalStyle } from 'styled-components';

import colors from '../colors';


const GlobalStyle = createGlobalStyle`

	html, body, #root {
		height: 100vh;
	}

	dl {
		display: flex;
		flex-wrap: wrap;

		dd, dt {
			font-size: 0.9rem;
			display: inline-block;
		}

		dt {
			font-weight: bold;
			width: 50%;
		}

		dd {
			text-align: right;
			margin-left: auto;
		}
	}

	.MuiIconButton-root {
		&.active {
				// color: ${colors.primary['100']};
			background-color: ${colors.primary['800']};
		}
	}

`;

export default GlobalStyle;
