import { createSelector } from 'reselect';

import { messageTypes } from '@@Constants';


const getMessages = (state) => {
	return state.socket.messages.incoming || [];
};

const getLastHeartbeat = (state) => {
	return state.socket.heartbeat;
};

const getQuote = createSelector(
	getMessages,
	(messages) => {
		const quote = messages.filter(msg => msg.type === messageTypes.AMERITRADE.QUOTE);
		if (quote.length) {
			return quote[quote.length - 1];
		}
		return {};
	}
);

export {
	getLastHeartbeat,
	getMessages,
	getQuote,
}
