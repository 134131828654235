import React from 'react';
import { Box } from '@material-ui/core';
import config from '@@Config';


const DebugOutput = ({
	data,
	label,
}) => {
	if (config.debug.output) {
		return (
			<Box my={2}>
				<code
					style={{
						display: 'block',
						lineHeight: 1.4,
						fontFamily: 'Overpass Mono',
						fontSize: 10,
						fontWeight: '600',
						wordBreak: 'break-word',
						color: '#666',
					}}
				>
					{label && (<strong>{label}:</strong>)}
					{JSON.stringify(data)}
				</code>
			</Box>
		);
	}
	return null;
};

export default DebugOutput;
