import React from 'react';

import { Box, Table, TableBody, TableCell, TableFooter, TableHead, TableRow } from '@material-ui/core';

import QuoteCard from '@@Components/QuoteCard';
import DebugOutput from '@@Components/DebugOutput';
import * as Styles from '@@Components/Options/Agents/AgentCard/AgentCard.styles';

import { currency } from '@@Utils';


const Iteration = ({
	config,
	iteration,
	quotes,
}) => {

	const {
		id,
		balance,
		items,
		status,
	} = iteration;

	const {
		longCall, longCall2,
		longPut, longPut2,
		shortCall, shortCall2,
		shortPut, shortPut2,
	} = items;

	let unrealizedTotal = 0;
	if (shortCall.order && longCall.order && longPut.order && shortPut.order
		&& quotes[shortCall.symbol] && quotes[longCall.symbol] && quotes[longPut.symbol] && quotes[shortPut.symbol]
	) {
		unrealizedTotal = currency(100 * (
			(shortCall.order.filledPrice ? currency(quotes[shortCall.symbol].bid * -shortCall.order.filledQuantity) - currency(Number(shortCall.order.filledPrice) * -shortCall.order.filledQuantity) : 0)
			+ (longCall.order.filledPrice ? currency(quotes[longCall.symbol].bid * longCall.order.filledQuantity) - currency(Number(longCall.order.filledPrice) * longCall.order.filledQuantity) : 0)
			+ (longPut.order.filledPrice ? currency(quotes[longPut.symbol].bid * longPut.order.filledQuantity) - currency(Number(longPut.order.filledPrice) * longPut.order.filledQuantity) : 0)
			+ (shortPut.order.filledPrice ? currency(quotes[shortPut.symbol].bid * -shortPut.order.filledQuantity) - currency(Number(shortPut.order.filledPrice) * -shortPut.order.filledQuantity) : 0)
			+ (longCall2 && longCall2.order.filledPrice ? currency(quotes[longCall2.symbol].bid * longCall2.order.filledQuantity) - currency(Number(longCall2.order.filledPrice) * longCall2.order.filledQuantity) : 0)
			+ (longPut2 && longPut2.order.filledPrice ? currency(quotes[longPut2.symbol].bid * longPut2.order.filledQuantity) - currency(Number(longPut2.order.filledPrice) * longPut2.order.filledQuantity) : 0)
			+ (shortCall2 && shortCall2.order.filledPrice ? currency(quotes[shortCall2.symbol].bid * -shortCall2.order.filledQuantity) - currency(Number(shortCall2.order.filledPrice) * -shortCall2.order.filledQuantity) : 0)
			+ (shortPut2 && shortPut2.order.filledPrice ? currency(quotes[shortPut2.symbol].bid * -shortPut2.order.filledQuantity) - currency(Number(shortPut2.order.filledPrice) * -shortPut2.order.filledQuantity) : 0)
		));
	}

	return (
		<Box key={id}>

			<DebugOutput data={items} label="items" />

			{/*<DebugOutput data={longCall2} label="long call 2" />*/}
			{/*<DebugOutput data={shortCall2} label="short call 2" />*/}
			{/*<DebugOutput data={longPut2} label="long put 2" />*/}
			{/*<DebugOutput data={shortPut2} label="short put 2" />*/}

			{/*<QuoteCard service="OPTION" symbol={shortCall.symbol} />*/}
			{/*<QuoteCard service="OPTION" symbol={longCall.symbol} />*/}
			{/*<QuoteCard service="OPTION" symbol={longPut.symbol} />*/}
			{/*<QuoteCard service="OPTION" symbol={shortPut.symbol} />*/}

			<Styles.StatusBadge status={status}>
				{status}
			</Styles.StatusBadge>

			<Table size="small">
				{/*{iteration.spreads && iteration.spreads.length && (*/}
				{/*	<caption>{iteration.spreads.join(', ')}</caption>*/}
				{/*)}*/}
				<TableHead>
					<TableRow>
						<TableCell>Qty</TableCell>
						<TableCell>Type</TableCell>
						<TableCell>Symbol</TableCell>
						<TableCell align="right">Bid</TableCell>
						<TableCell align="right">Delta</TableCell>
						<TableCell>Order Status</TableCell>
						{/*<TableCell>Order</TableCell>*/}
						<TableCell align="right">Order Price</TableCell>
						<TableCell align="right">Order Total</TableCell>
						<TableCell align="right">Fill Price</TableCell>
						<TableCell align="right">Fill Total</TableCell>
						<TableCell align="right">Unrealized</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{shortCall.order && (
						<Styles.StyledTableRow status={shortCall.order.status}>
							<TableCell>-{shortCall.order.quantity}</TableCell>
							<TableCell>CALL</TableCell>
							<TableCell>{shortCall.symbol}</TableCell>
							<TableCell
								align="right">{quotes[shortCall.symbol] ? currency(quotes[shortCall.symbol].bid) : ''}</TableCell>
							<TableCell
								align="right">{quotes[shortCall.symbol] ? quotes[shortCall.symbol].delta : ''}</TableCell>
							<TableCell>{shortCall.order.status}</TableCell>
							{/*<TableCell><DebugOutput data={shortCall.order} /></TableCell>*/}
							<TableCell
								align="right">{currency(shortCall.order.price)}</TableCell>
							<TableCell
								align="right">{shortCall.order.price ? currency(Number(shortCall.order.price) * shortCall.order.quantity) : ''}</TableCell>
							<TableCell
								align="right">{currency(shortCall.order.filledPrice)}</TableCell>
							<TableCell align="right">
								{shortCall.order.filledPrice && (
									<span>{currency(Number(shortCall.order.filledPrice) * shortCall.order.filledQuantity)}</span>
								)}
							</TableCell>
							<TableCell align="right">
								{(shortCall.order.filledPrice && quotes[shortCall.symbol]) && (
									<span>
																	{currency(100 * (currency(quotes[shortCall.symbol].bid * -shortCall.order.filledQuantity) - currency(Number(shortCall.order.filledPrice) * -shortCall.order.filledQuantity)))}
																</span>
								)}
							</TableCell>
						</Styles.StyledTableRow>
					)}
					{longCall.order && (
						<Styles.StyledTableRow status={longCall.order.status}>
							<TableCell>+{longCall.order.quantity}</TableCell>
							<TableCell>CALL</TableCell>
							<TableCell>{longCall.symbol}</TableCell>
							<TableCell
								align="right">{quotes[longCall.symbol] ? currency(quotes[longCall.symbol].bid) : ''}</TableCell>
							<TableCell
								align="right">{quotes[longCall.symbol] ? quotes[longCall.symbol].delta : ''}</TableCell>
							<TableCell>{longCall.order.status}</TableCell>
							{/*<TableCell><DebugOutput data={longCall.order} /></TableCell>*/}
							<TableCell
								align="right">{currency(longCall.order.price)}</TableCell>
							<TableCell
								align="right">{longCall.order.price ? currency(longCall.order.price * -longCall.order.quantity) : ''}</TableCell>
							<TableCell
								align="right">{currency(longCall.order.filledPrice)}</TableCell>
							<TableCell align="right">
								{longCall.order.filledPrice && (
									<span>{currency(Number(longCall.order.filledPrice) * -longCall.order.filledQuantity)}</span>
								)}
							</TableCell>
							<TableCell align="right">
								{(longCall.order.filledPrice && quotes[longCall.symbol]) && (
									<span>
																	{currency(100 * (currency(quotes[longCall.symbol].bid * longCall.order.filledQuantity) - currency(Number(longCall.order.filledPrice) * longCall.order.filledQuantity)))}
																</span>

								)}
							</TableCell>
						</Styles.StyledTableRow>
					)}
					{longPut.order && (
						<Styles.StyledTableRow status={longPut.order.status}>
							<TableCell>+{longPut.order.quantity}</TableCell>
							<TableCell>PUT</TableCell>
							<TableCell>{longPut.symbol}</TableCell>
							<TableCell
								align="right">{quotes[longPut.symbol] ? currency(quotes[longPut.symbol].bid) : ''}</TableCell>
							<TableCell
								align="right">{quotes[longPut.symbol] ? quotes[longPut.symbol].delta : ''}</TableCell>
							<TableCell>{longPut.order.status}</TableCell>
							{/*<TableCell><DebugOutput data={longPut.order} /></TableCell>*/}
							<TableCell
								align="right">{currency(longPut.order.price)}</TableCell>
							<TableCell
								align="right">{longPut.order.price ? currency(longPut.order.price * -longPut.order.quantity) : ''}</TableCell>
							<TableCell
								align="right">{currency(longPut.order.filledPrice)}</TableCell>
							<TableCell
								align="right">{longPut.order.filledPrice ? currency(Number(longPut.order.filledPrice) * -longPut.order.filledQuantity) : ''}</TableCell>
							<TableCell align="right">
								{(longPut.order.filledPrice && quotes[longPut.symbol]) && (
									<span>
																	{currency(100 * (currency(quotes[longPut.symbol].bid * longPut.order.filledQuantity) - currency(Number(longPut.order.filledPrice) * longPut.order.filledQuantity)))}
																</span>
								)}
							</TableCell>
						</Styles.StyledTableRow>
					)}
					{shortPut.order && (
						<Styles.StyledTableRow status={shortPut.order.status}>
							<TableCell>-{shortPut.order.quantity}</TableCell>
							<TableCell>PUT</TableCell>
							<TableCell>{shortPut.symbol}</TableCell>
							<TableCell
								align="right">{quotes[shortPut.symbol] ? currency(quotes[shortPut.symbol].bid) : ''}</TableCell>
							<TableCell
								align="right">{quotes[shortPut.symbol] ? quotes[shortPut.symbol].delta : ''}</TableCell>
							<TableCell>{shortPut.order.status}</TableCell>
							{/*<TableCell><DebugOutput data={shortPut.order} /></TableCell>*/}
							<TableCell
								align="right">{currency(shortPut.order.price)}</TableCell>
							<TableCell
								align="right">{shortPut.order.price ? currency(Number(shortPut.order.price) * shortPut.order.quantity) : ''}</TableCell>
							<TableCell
								align="right">{currency(shortPut.order.filledPrice)}</TableCell>
							<TableCell align="right">
								{shortPut.order.filledPrice && (
									<span>{currency(Number(shortPut.order.filledPrice) * shortPut.order.filledQuantity)}</span>
								)}
							</TableCell>
							<TableCell align="right">
								{(shortPut.order.filledPrice && quotes[shortPut.symbol]) && (
									<span>
																	{currency(100 * (currency(quotes[shortPut.symbol].bid * -shortPut.order.filledQuantity) - currency(Number(shortPut.order.filledPrice) * -shortPut.order.filledQuantity)))}
																</span>
								)}
							</TableCell>
						</Styles.StyledTableRow>
					)}

					<TableRow>
						<TableCell colSpan={12}>&nbsp;</TableCell>
					</TableRow>

					{longCall2 && longCall2.order.orderId && (
						<Styles.StyledTableRow status={longCall2.order.status}>
							<TableCell>+{longCall2.order.quantity}</TableCell>
							<TableCell>CALL</TableCell>
							<TableCell>{longCall2.symbol}</TableCell>
							<TableCell
								align="right">{quotes[longCall2.symbol] ? currency(quotes[longCall2.symbol].bid) : ''}</TableCell>
							<TableCell
								align="right">{quotes[longCall2.symbol] ? quotes[longCall2.symbol].delta : ''}</TableCell>
							<TableCell>{longCall2.order.status}</TableCell>
							{/*<TableCell><DebugOutput data={shortPut.order} /></TableCell>*/}
							<TableCell
								align="right">{currency(longCall2.order.price)}</TableCell>
							<TableCell
								align="right">{longCall2.order.price ? currency(Number(longCall2.order.price) * longCall2.order.quantity) : ''}</TableCell>
							<TableCell
								align="right">{currency(longCall2.order.filledPrice)}</TableCell>
							<TableCell align="right">
								{longCall2.order.filledPrice && (
									<span>{currency(Number(longCall2.order.filledPrice) * -longCall2.order.filledQuantity)}</span>
								)}
							</TableCell>
							<TableCell align="right">
								{(longCall2.order.filledPrice && quotes[longCall2.symbol]) && (
									<span>
																	{currency(100 * (currency(quotes[longCall2.symbol].bid * longCall2.order.filledQuantity) - currency(Number(longCall2.order.filledPrice) * longCall2.order.filledQuantity)))}
																</span>
								)}
							</TableCell>
						</Styles.StyledTableRow>
					)}
					{shortCall2 && shortCall2.order.orderId && (
						<Styles.StyledTableRow status={shortCall2.order.status}>
							<TableCell>-{shortCall2.order.quantity}</TableCell>
							<TableCell>CALL</TableCell>
							<TableCell>{shortCall2.symbol}</TableCell>
							<TableCell
								align="right">{quotes[shortCall2.symbol] ? currency(quotes[shortCall2.symbol].bid) : ''}</TableCell>
							<TableCell
								align="right">{quotes[shortCall2.symbol] ? quotes[shortCall2.symbol].delta : ''}</TableCell>
							<TableCell>{shortCall2.order.status}</TableCell>
							{/*<TableCell><DebugOutput data={shortPut.order} /></TableCell>*/}
							<TableCell
								align="right">{currency(shortCall2.order.price)}</TableCell>
							<TableCell
								align="right">{shortCall2.order.price ? currency(Number(shortCall2.order.price) * shortCall2.order.quantity) : ''}</TableCell>
							<TableCell
								align="right">{currency(shortCall2.order.filledPrice)}</TableCell>
							<TableCell align="right">
								{shortCall2.order.filledPrice && (
									<span>{currency(Number(shortCall2.order.filledPrice) * shortCall2.order.filledQuantity)}</span>
								)}
							</TableCell>
							<TableCell align="right">
								{(shortCall2.order.filledPrice && quotes[shortCall2.symbol]) && (
									<span>
																	{currency(100 * (currency(quotes[shortCall2.symbol].bid * -shortCall2.order.filledQuantity) - currency(Number(shortCall2.order.filledPrice) * -shortCall2.order.filledQuantity)))}
																</span>
								)}
							</TableCell>
						</Styles.StyledTableRow>
					)}
					{longPut2 && longPut2.order.orderId && (
						<Styles.StyledTableRow status={longPut2.order.status}>
							<TableCell>+{longPut2.order.quantity}</TableCell>
							<TableCell>PUT</TableCell>
							<TableCell>{longPut2.symbol}</TableCell>
							<TableCell
								align="right">{quotes[longPut2.symbol] ? currency(quotes[longPut2.symbol].bid) : ''}</TableCell>
							<TableCell
								align="right">{quotes[longPut2.symbol] ? quotes[longPut2.symbol].delta : ''}</TableCell>
							<TableCell>{longPut2.order.status}</TableCell>
							{/*<TableCell><DebugOutput data={shortPut.order} /></TableCell>*/}
							<TableCell
								align="right">{currency(longPut2.order.price)}</TableCell>
							<TableCell
								align="right">{longPut2.order.price ? currency(Number(longPut2.order.price) * longPut2.order.quantity) : ''}</TableCell>
							<TableCell
								align="right">{currency(longPut2.order.filledPrice)}</TableCell>
							<TableCell align="right">
								{longPut2.order.filledPrice && (
									<span>{currency(Number(longPut2.order.filledPrice) * -longPut2.order.filledQuantity)}</span>
								)}
							</TableCell>
							<TableCell align="right">
								{(longPut2.order.filledPrice && quotes[longPut2.symbol]) && (
									<span>
																	{currency(100 * (currency(quotes[longPut2.symbol].bid * longPut2.order.filledQuantity) - currency(Number(longPut2.order.filledPrice) * longPut2.order.filledQuantity)))}
																</span>
								)}
							</TableCell>
						</Styles.StyledTableRow>
					)}
					{shortPut2 && shortPut2.order.orderId && (
						<Styles.StyledTableRow status={shortPut2.order.status}>
							<TableCell>-{shortPut2.order.quantity}</TableCell>
							<TableCell>PUT</TableCell>
							<TableCell>{shortPut2.symbol}</TableCell>
							<TableCell
								align="right">{quotes[shortPut2.symbol] ? currency(quotes[shortPut2.symbol].bid) : ''}</TableCell>
							<TableCell
								align="right">{quotes[shortPut2.symbol] ? quotes[shortPut2.symbol].delta : ''}</TableCell>
							<TableCell>{shortPut2.order.status}</TableCell>
							{/*<TableCell><DebugOutput data={shortPut.order} /></TableCell>*/}
							<TableCell
								align="right">{currency(shortPut2.order.price)}</TableCell>
							<TableCell
								align="right">{shortPut2.order.price ? currency(Number(shortPut2.order.price) * shortPut2.order.quantity) : ''}</TableCell>
							<TableCell
								align="right">{currency(shortPut2.order.filledPrice)}</TableCell>
							<TableCell align="right">
								{shortPut2.order.filledPrice && (
									<span>{currency(Number(shortPut2.order.filledPrice) * shortPut2.order.filledQuantity)}</span>
								)}
							</TableCell>
							<TableCell align="right">
								{(shortPut2.order.filledPrice && quotes[shortPut2.symbol]) && (
									<span>
																	{currency(100 * (currency(quotes[shortPut2.symbol].bid * -shortPut2.order.filledQuantity) - currency(Number(shortPut2.order.filledPrice) * -shortPut2.order.filledQuantity)))}
																</span>
								)}
							</TableCell>
						</Styles.StyledTableRow>
					)}
				</TableBody>
				<TableFooter>
					<TableRow>
						<TableCell colSpan={7}/>
						<TableCell colSpan={2}>
							{balance !== 0 && (
								<span>Risk:Reward Ratio = 1:{((config.spreadInterval + Number(currency(balance))) / Math.abs(Number(currency(balance)))).toFixed(1)}</span>
							)}
						</TableCell>
						<TableCell align="right">
							{balance >= 0 && (
								<strong><span
									style={{ color: 'green' }}>+{currency(balance * 100)}</span></strong>
							)}
							{balance < 0 && (
								<strong><span
									style={{ color: 'red' }}>{currency(balance * 100)}</span></strong>
							)}
						</TableCell>
						<TableCell align="right">
							{unrealizedTotal >= 0 && (
								<strong><span
									style={{ color: 'green' }}>+{unrealizedTotal}</span></strong>
							)}
							{unrealizedTotal < 0 && (
								<strong><span style={{ color: 'red' }}>{unrealizedTotal}</span></strong>
							)}
						</TableCell>
					</TableRow>
				</TableFooter>
			</Table>
		</Box>
	);
}

export default Iteration;
