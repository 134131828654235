const {
	common,
	blue,
	blueGrey,
	green,
	yellow,
	red,
	grey,
} = require('@material-ui/core/colors');

const primary = blueGrey;
const secondary = green;
const error = red;
const warning = yellow;
const info = blue;
const success = green;
const { black, white } = common;


module.exports = {
	primary,
	secondary,
	error,
	warning,
	info,
	success,
	black,
	white,
	grey,
};
