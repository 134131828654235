import React, { Fragment } from 'react';
import {connect} from 'react-redux';
import Moment from 'react-moment';
import { Formik, Form } from 'formik';
import styled from 'styled-components';

import { Box, Button, Grid, TextField, Typography, withTheme } from '@material-ui/core';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import CachedIcon from '@material-ui/icons/Cached';

import config from '@@Config';
import {operations as authOperations, selectors as authSelectors} from '@@Redux/auth';


const Ameritrade = ({
	ameritrade,
	postAmeritradeAccessToken,
	refreshAmeritradeAccessToken,
}) => {

	const openAmeritradeWindow = () => {
		window.open(config.ameritrade.authenticationUrl, '_blank', 'toolbar=0,status=0,width=548,height=460');
	};

	return (
		<Fragment>
			<Typography component="h2" variant="h3" gutterBottom>
				TD Ameritrade Access
			</Typography>

			{(!ameritrade.refreshToken || ameritrade.refreshTokenIsExpired) && (
				<Box>
					<Typography component="h6" variant="subtitle1">
						To generate tokens:
					</Typography>

					<Grid container>
						<Grid item xs={10}>
							<ol>
								<li>
									<Box mb={1}>
										<Button
											size="small"
											variant="contained"
											color="primary"
											onClick={openAmeritradeWindow}
										>
											Sign in to TD Ameritrade
										</Button>
									</Box>
								</li>
								<li>Allow / authorize this application</li>
								<li>After allowing, copy the resulting error page url's 'code' parameter value<br />(all characters after <code>?code=</code>) from the address bar.
									<Typography variant="caption" component="div">Note: This code may only be used once. You will need to re-authenticate each time.</Typography>
								</li>
							</ol>
						</Grid>
					</Grid>

					<Formik
						initialValues={{
							code: '',
						}}
						validate={values => {
							const errors = {};
							if (!values.code) {
								errors.code = 'Code is required.';
							}
							return errors;
						}}
						onSubmit={(values, { setSubmitting }) => {
							setTimeout(() => {
								setSubmitting(false);

								postAmeritradeAccessToken(values.code);
							}, 400);
						}}
					>
						{({
							values,
							errors,
							touched,
							handleChange,
							handleBlur,
							handleSubmit,
							isSubmitting,
							/* and other goodies */
						}) => (
							<Form>

								<Grid container>
									<Grid item xs={12} sm={8} md={6}>
										<TextField
											size="small"
											variant="filled"
											fullWidth
											id="code"
											name="code"
											label="Token Code"
											onBlur={handleBlur}
											onChange={handleChange}
											value={values.code}
											error={(errors.code && touched.code)}
											helperText={(errors.code && touched.code) && errors.code}
										/>
									</Grid>
								</Grid>
								<Box my={2}>
									<Button
										variant="contained"
										color="primary"
										type="submit"
										disabled={isSubmitting||!values.code}
									>
										Generate Access Token
									</Button>
								</Box>
							</Form>
						)}
					</Formik>
				</Box>
			)}

			{(ameritrade.refreshToken && !ameritrade.refreshTokenIsExpired) && (
				<Grid container>
					<Grid item xs={12}>

						<Box my={2}>
							<Typography component="h3" variant="subtitle1">
								Access Token
							</Typography>

							<Grid container>
								<Grid item>
									<VpnKeyIcon color={ameritrade.accessTokenIsExpired ? 'primary' : 'secondary'} />
								</Grid>
								<Grid item>
									<StyledCode color={ameritrade.accessTokenIsExpired ? 'primary' : 'secondary'} component="code" variant="body2" style={{ overflowWrap: 'break-word' }}>
										{ameritrade.accessToken}
									</StyledCode>
								</Grid>
							</Grid>

							<Typography component="div" variant="caption" gutterBottom>
								{ameritrade.accessTokenIsExpired ? 'Expired' : 'Expires'}:&nbsp;
								<Moment parse="X" date={ameritrade.accessTokenExpiresAt} format="dddd, MMMM Do YYYY, h:mm:ss a" />
							</Typography>

							<Button size="small" variant="contained" color="primary" onClick={() => { refreshAmeritradeAccessToken(); }}>
								<CachedIcon /> Refresh Access Token
							</Button>

						</Box>

						<Box my={2}>
							<Typography component="h3" variant="subtitle1">
								Refresh Token
							</Typography>

							<Grid container>
								<Grid item>
									<VpnKeyIcon color={ameritrade.refreshTokenIsExpired ? 'primary' : 'secondary'} />
								</Grid>
								<Grid item>
									<StyledCode color={ameritrade.refreshTokenIsExpired ? 'primary' : 'secondary'} component="code" variant="body2" style={{ overflowWrap: 'break-word' }}>
										{ameritrade.refreshToken}
									</StyledCode>
								</Grid>
							</Grid>

							<Typography component="div" variant="caption">
								{ameritrade.refreshTokenIsExpired ? 'Expired' : 'Expires'}:&nbsp;
								<Moment parse="X" date={ameritrade.refreshTokenExpiresAt} format="dddd, MMMM Do YYYY, h:mm:ss a" />
							</Typography>
						</Box>

					</Grid>
				</Grid>
			)}

		</Fragment>
	);
};

const StyledCode = styled(Typography)`
	font-family: 'Overpass Mono', monospace;
	line-height: 1.25em;
	display: inline-block;
	margin: 0.25em 0.5em;
`;

const mapStateToProps = (state) => {
	return {
		ameritrade: authSelectors.getAmeritrade(state),
	};
};

export default connect(
	mapStateToProps,
	{
		postAmeritradeAccessToken: authOperations.postAmeritradeAccessToken,
		refreshAmeritradeAccessToken: authOperations.refreshAmeritradeAccessToken,
	},
)(withTheme(Ameritrade));

