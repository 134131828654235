import styled, { css } from 'styled-components';
import { transparentize } from 'polished';

import { Card, CardHeader, Typography, withTheme } from '@material-ui/core';

import { agentStatuses, iterationStatuses } from '@@Constants';


const StyledCard = styled(Card)``;

const StyledCardHeader = withTheme(styled(CardHeader)`
	background-color: ${props => transparentize(0.9, props.theme.palette.primary.main)};
`);

const StatusBadge = styled(Typography)`
	display: inline-block;
	background-color: darkcyan;
	color: #fff;
	padding: 0.175em 0.3em 0.15em;
	border-radius: 0.15em;
	font-weight: bold;
	font-size: 1rem;
	line-height: 1.35em;
	${props => {
		switch (props.status) {
			case agentStatuses.IDLE:
			case iterationStatuses.OPEN_COMPLETE:
			case iterationStatuses.POSITION_CLOSED: {
				return css`
						background-color: #bbb;
					`;
			}
			case agentStatuses.ERROR: {
				return css`
						background-color: red;
					`;
			}
		}
	}}
`;

const StyledDescriptionList = styled.dl`
	display: flex;
	flex-wrap: wrap;
	margin: 1em 0 0;

	dd, dt {
		font-size: 0.75rem;
		display: inline-block;
	}
	dt {
		font-weight: bold;
		width: 50%;
	}
	dd {
		text-align: right;
		margin-left: auto;
	}
`;


export {
	StatusBadge,
	StyledCard as Card,
	StyledCardHeader as CardHeader,
	StyledDescriptionList as DescriptionList,
}
