import actions from './actions';


const getPositions = () => (dispatch) => {
	console.info('[operation] getPositions()');
	return dispatch(actions.getPositions.request());
};

export {
	getPositions,
};
