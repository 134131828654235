import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { v4 as uuid } from 'uuid';
import { Line } from 'react-chartjs-2';
import { Box, Button, Divider, Grid, Typography, Table, TableHead, TableRow, TableBody, TableCell } from '@material-ui/core';

import QuoteCard from '@@Components/QuoteCard';
import { operations as agentOperations, selectors as agentSelectors } from '@@Redux/agents';
import { operations as socketOperations } from '@@Redux/socket';
import { operations as accountOperations, selectors as accountSelectors } from '@@Redux/account';
import { USD } from '@@Utils';

import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";

import AgentDialog from './AgentDialog';
import AgentCard from './AgentCard';
import DebugOutput from "@@Components/DebugOutput";
import AccountSummary from '@@Components/Options/Dashboard/AccountSummary';


const Crypto = ({
	account,
	agents,
	deleteAgent,
	getAccountInstruments,
	getAccount,
	getAgents,
	startAgent,
	stopAgent,

}) => {

	const [dialogIsOpen, setDialogIsOpen] = useState(false);
	const [selectedAgent, setSelectedAgent] = useState({})

	useEffect(() => {
		async function fetchData() {
			await getAccount({ name: 'coinbase'});
			await getAccountInstruments({ name: 'coinbase'});
			await getAgents();
		}
		fetchData();
	}, [getAccount, getAccountInstruments, getAgents]);

	const openDialog = () => {
		setSelectedAgent(null);
		setDialogIsOpen(true);
	};

	const closeDialog = () => {
		setDialogIsOpen(false);
	};

	const handleDelete = (agent) => {
		deleteAgent(agent);
	};

	const handleStart = (agent) => {
		startAgent(agent);
	};

	const handleStop = (agent) => {
		stopAgent(agent);
	};

	return (
		<Box>
			<Typography variant="h5" gutterBottom>Crypto</Typography>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<AccountSummary name='coinbase' />
				</Grid>
			</Grid>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Button variant="contained" color="primary" onClick={openDialog}>
						Add Crypto Agent
					</Button>

					<Grid container>
						{agents.map((agent) => {
							return (
								<Grid key={agent.id} item xs={12}>
									{agent && agent.status && (
										<AgentCard
											agent={agent}
											onDelete={handleDelete}
											onStart={handleStart}
											onStop={handleStop}
										/>
									)}
								</Grid>
							);
						})}
					</Grid>
				</Grid>

			</Grid>

			<AgentDialog
				isOpen={dialogIsOpen}
				onClose={closeDialog}
				agent={selectedAgent}
			/>

		</Box>
	);
};

const mapStateToProps = (state) => {
	const agents = agentSelectors.getAgents(state, 'CRYPTO_GRID');
	const account = accountSelectors.getAccount(state, { name: 'coinbase' });
	const messages = agentSelectors.getAgentMessages(state);

	return {
		agents,
		account,
		messages,
	};
};

const mapDispatchToProps = {
	getAccount: accountOperations.getAccount,
	getAccountInstruments: accountOperations.getAccountInstruments,
	getAgents: agentOperations.getAgents,
	deleteAgent: agentOperations.deleteAgent,
	startAgent: agentOperations.startAgent,
	stopAgent: agentOperations.stopAgent,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(Crypto);
