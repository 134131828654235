import actions from './actions';


const getTransactions = () => (dispatch) => {
	console.info('[operation] getTransactions()');
	return dispatch(actions.getTransactions());
};

const getTransaction = (transactionId) => (dispatch) => {
	console.info('[operation] getTransaction()');
	return dispatch(actions.getTransaction(transactionId));
};

export {
	getTransactions,
	getTransaction,
};
