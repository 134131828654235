import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';

import AccountSummary from './AccountSummary';
import Actives from './Actives';

const Dashboard = (props) => {
	return (
		<Box my={2}>
			<Grid container spacing={2}>
				<Grid item xs={12} sm={6}>
					<Typography variant="h4" gutterBottom>
						Actives
					</Typography>
					<Actives symbol="NASDAQ-60" />
					<Actives symbol="NYSE-60" />
					<Actives symbol="OPTS-DESC-ALL" />
				</Grid>
				<Grid item xs={12} sm={6}>
					<Typography variant="h4" gutterBottom>
						Account Summary
					</Typography>
					<AccountSummary />
				</Grid>
			</Grid>
		</Box>
	);
};

export default Dashboard;
