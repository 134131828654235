import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { withTheme, Button, Card, CardContent } from '@material-ui/core';

import DebugOutput from '@@Components/DebugOutput';
import { operations as accountOperations, selectors as accountSelectors } from '@@Redux/account';
import { USD } from '@@Utils';


const AccountSummary = ({
	account,
	getAccount,
	name = 'oanda',
}) => {

	useEffect(() => {
		async function fetchData() {
			// await getAccount({ name });
		}
		fetchData();
	}, [getAccount]);

	const refresh = () => {
		getAccount({ name });
	}

	if (account) {
		return (
			<Card>
				<CardContent>
					<dl>
						<dt>Account:</dt>
						<dd>xxx-xxx-xxxxx{account.id ? account.id.slice(-7) : ''}</dd>

						<dt>Balance</dt>
						<dd>{USD(account.balance).format()}</dd>
						<dt>Open Trade Count</dt>
						<dd>{account.openTradeCount}</dd>
						<dt>Open Position Count</dt>
						<dd>{account.openPositionCount}</dd>
						<dt>Pending Order Count</dt>
						<dd>{account.pendingOrderCount}</dd>
						<dt>Unrealized PL</dt>
						<dd>{USD(account.unrealizedPL).format()}</dd>
						<dt>Margin Used</dt>
						<dd>{USD(account.marginUsed).format()}</dd>
						<dt>Margin Available</dt>
						<dd>{USD(account.marginAvailable).format()}</dd>
					</dl>

					<Button
						variant="outlined"
						color="secondary"
						onClick={refresh}>
						Refresh
					</Button>

					{/* <DebugOutput
						data={account}
						label="account"
					/> */}

				</CardContent>
			</Card>
		)
	}
	return null;
};


const mapStateToProps = (state, ownProps) => {
	const name = ownProps.name || 'oanda'
	return {
		account: accountSelectors.getAccount(state, { name }) || {},
	};
};

export default connect(
	mapStateToProps,
	{
		getAccount: accountOperations.getAccount,
	},
)(withTheme(AccountSummary));
