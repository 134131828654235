import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import { createMiddleware } from 'redux-api-middleware';

import socketMiddleware from './socket';
import authInjector from './authInjector';


export const sagaMiddleware = createSagaMiddleware();

const middleware = [
	thunk,
	createMiddleware(),
	authInjector,
	socketMiddleware,
	sagaMiddleware,
]

export default middleware;
