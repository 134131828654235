import { all, takeLatest, put, fork } from 'redux-saga/effects';

import { api } from '@@Utils';

import actions from './actions';
import types from './types';

/**
 Workers
 */
function* fetchAgents() {
	try {
		const { data } = yield api.get(`/agents`);
		yield put(actions.getAgents.success(data));
	}
	catch (err) {
		yield put(actions.getAgents.failure(err));
	}
}

function* createAgent(action) {
	const params = action.payload;

	try {
		const { data } = yield api.post(`/agents`, params);
		yield put(actions.createAgent.success(data, params));
	}
	catch (err) {
		yield put(actions.createAgent.failure(err));
	}
}

function* deleteAgent(action) {
	const obj = action.payload;

	try {
		yield api.delete(`/agents/${obj.id}`);
		yield put(actions.deleteAgent.success(null, obj));
	}
	catch (err) {
		yield put(actions.deleteAgent.failure(err));
	}
}

function* startAgent(action) {
	const params = action.payload;
	const { id: agentId } = params;

	try {
		const { data } = yield api.post(`/agents/${agentId}/start`);
		yield put(actions.startAgent.success(data, params));
	}
	catch (err) {
		yield put(actions.startAgent.failure(err));
	}
}

function* stopAgent(action) {
	const params = action.payload;
	const { id: agentId } = params;

	try {
		const { data } = yield api.post(`/agents/${agentId}/stop`);
		yield put(actions.stopAgent.success(data, params));
	}
	catch (err) {
		yield put(actions.stopAgent.failure(err));
	}
}


/**
 * Watchers
 */
function *watchAgents() {
	try {
		yield takeLatest(types.AGENTS.REQUEST, fetchAgents);
	} catch(err) {
		yield put(actions.getAgents.failure(err));
	}
}

function *watchAgentCreate() {
	try {
		yield takeLatest(types.AGENT_CREATE.REQUEST, createAgent);
	} catch(err) {
		yield put(actions.createAgent.failure(err));
	}
}

function *watchAgentDelete() {
	try {
		yield takeLatest(types.AGENT_DELETE.REQUEST, deleteAgent);
	} catch(err) {
		yield put(actions.deleteAgent.failure(err));
	}
}

function *watchAgentStart() {
	try {
		yield takeLatest(types.AGENT_START.REQUEST, startAgent);
	} catch(err) {
		yield put(actions.startAgent.failure(err));
	}
}

function *watchAgentStop() {
	try {
		yield takeLatest(types.AGENT_STOP.REQUEST, stopAgent);
	} catch(err) {
		yield put(actions.stopAgent.failure(err));
	}
}


/**
 * Sagas
 */
function* sagas() {
	yield all([
		fork(watchAgents),
		fork(watchAgentCreate),
		fork(watchAgentDelete),
		fork(watchAgentStart),
		fork(watchAgentStop),
	]);
}

export default sagas;
