const AGENTS = {
	name: 'agents/AGENTS',
	REQUEST: 'agents/AGENTS_REQUEST',
	SUCCESS: 'agents/AGENTS_SUCCESS',
	FAILURE: 'agents/AGENTS_FAILURE',
};

const AGENT_CREATE = {
	name: 'agents/AGENT_CREATE',
	REQUEST: 'agents/AGENT_CREATE_REQUEST',
	SUCCESS: 'agents/AGENT_CREATE_SUCCESS',
	FAILURE: 'agents/AGENT_CREATE_FAILURE',
};

const AGENT_DELETE = {
	name: 'agents/AGENT_DELETE',
	REQUEST: 'agents/AGENT_DELETE_REQUEST',
	SUCCESS: 'agents/AGENT_DELETE_SUCCESS',
	FAILURE: 'agents/AGENT_DELETE_FAILURE',
};

const AGENT_START = {
	name: 'agents/AGENT_START',
	REQUEST: 'agents/AGENT_START_REQUEST',
	SUCCESS: 'agents/AGENT_START_SUCCESS',
	FAILURE: 'agents/AGENT_START_FAILURE',
};

const AGENT_STOP = {
	name: 'agents/AGENT_STOP',
	REQUEST: 'agents/AGENT_STOP_REQUEST',
	SUCCESS: 'agents/AGENT_STOP_SUCCESS',
	FAILURE: 'agents/AGENT_STOP_FAILURE',
};

export default {
	AGENTS,
	AGENT_CREATE,
	AGENT_DELETE,
	AGENT_START,
	AGENT_STOP,
};
