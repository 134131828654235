import React from 'react';
import Moment from 'react-moment';

import { Box, Grid } from '@material-ui/core';

import { dateFormats } from '@@Constants/dateFormats';


const Indicators = ({
	dataset,
}) => {
	if (dataset && dataset.indicators) {
		return (
			<Box my={2}>
				<Grid container spacing={1} justifyContent="space-between">
					<Grid item>
						{dataset.indicators.M1.date && dataset.indicators.M1.date.length > 0 && (
							<Moment date={dataset.indicators.M1.date[0]} format={dateFormats.DATE_TIME}/>
						)}
					</Grid>
					<Grid item>
						{/* {dataset.indicators.M1.RSI && dataset.indicators.M1.RSI.data.length > 0 && dataset.indicators.M1.RSI.data.map((rsi, i) => (
										<div key={uid(i)}>{rsi}</div>
									))} */}
						<strong>RSI</strong> (M1)<br/>
						{dataset.indicators.M1.RSI && dataset.indicators.M1.RSI.value}
					</Grid>
					<Grid item>
						<strong>RSI</strong> (M15)<br/>
						{dataset.indicators.M15.RSI && dataset.indicators.M15.RSI.value}
					</Grid>
					<Grid item>
						<strong>ATR</strong> (M1)<br/>
						{dataset.indicators.M1.ATR && dataset.indicators.M1.ATR.value.atr}
					</Grid>
					<Grid item>
						<strong>ATR</strong> (M15)<br/>
						{dataset.indicators.M15.ATR && dataset.indicators.M15.ATR.value.atr}
					</Grid>
					<Grid item>
						{/* {dataset.indicators.M1.BB && dataset.indicators.M1.BB.data.length > 0 && dataset.indicators.M1.BB.data.map((bb, i) => (
										<div key={uid(i)}>{bb.pb}: {bb.bw} ({bb.bwn})</div>
									))} */}
						<strong>BB pb</strong> (M1)<br/>
						{dataset.indicators.M1.BB && dataset.indicators.M1.BB.value.pb}
					</Grid>
					<Grid item>
						{/* {dataset.indicators.M1.BB && dataset.indicators.M1.BB.data.length > 0 && dataset.indicators.M1.BB.data.map((bb, i) => (
										<div key={uid(i)}>{bb.pb}: {bb.bw} ({bb.bwn})</div>
									))} */}
						<strong>BB bwn</strong> (M1)<br/>
						{dataset.indicators.M1.BB && dataset.indicators.M1.BB.value.bwn}
					</Grid>
				</Grid>
			</Box>
		);
	}
	return null;
}

export default Indicators;
