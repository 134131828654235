export const iterationStatuses = {
	OPEN_BUY: 'OPEN_BUY',
	OPEN_BUY_PENDING: 'OPEN_BUY_PENDING',
	OPEN_BUY_QUEUED: 'OPEN_BUY_QUEUED',
	OPEN_BUY_FILLED: 'OPEN_BUY_FILLED',
	OPEN_SELL: 'OPEN_SELL',
	OPEN_SELL_PENDING: 'OPEN_SELL_PENDING',
	OPEN_SELL_QUEUED: 'OPEN_SELL_QUEUED',
	OPEN_SELL_FILLED: 'OPEN_SELL_FILLED',
	OPEN_COMPLETE: 'OPEN_COMPLETE',
	POSITION_OPEN: 'POSITION_OPEN',
	POSITION_CLOSED: 'POSITION_CLOSED',
};
