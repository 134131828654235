import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getApiStatus } from '@@Redux/apiStatus';

import * as Styles from './ProgressIndicator.styles';


const propTypes = {
	message: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

const ProgressIndicator = ({
	message = null,
}) => {
	if (message) {
		return (
			<Styles.Backdrop open={true}>
				<Styles.LinearProgress
					color="secondary"
				/>
				<Styles.ProgressLabel>
					{message}
				</Styles.ProgressLabel>
			</Styles.Backdrop>
		);
	}

	return null;
};

ProgressIndicator.propTypes = propTypes;

const mapStateToProps = (state) => {
	const apiStatus = getApiStatus(state);
	const message = Object.values(apiStatus).find(value => value);

	return {
		message,
	};
};

export default connect(
	mapStateToProps,
)(ProgressIndicator);
