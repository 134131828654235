import actions from './actions';


const createAgent = (params) => (dispatch) => {
	console.info('[operation] createAgent()', params);
	return dispatch(actions.createAgent.request(params));
};

const getAgents = (params) => (dispatch) => {
	console.info('[operation] getAgents()');
	return dispatch(actions.getAgents.request(params));
};

const deleteAgent = (params) => (dispatch) => {
	console.info('[operation] deleteAgent()', params);
	return dispatch(actions.deleteAgent.request(params));
};

const startAgent = (params) => (dispatch) => {
	console.info('[operation] startAgent()', params);
	return dispatch(actions.startAgent.request(params));
};

const stopAgent = (params) => (dispatch) => {
	console.info('[operation] stopAgent()', params);
	return dispatch(actions.stopAgent.request(params));
};

export {
	createAgent,
	getAgents,
	deleteAgent,
	startAgent,
	stopAgent,
};
