import types from './types';


const getPositions = {
	request: () => ({
		type: types.POSITIONS.REQUEST,
	}),
	success: (data) => ({
		type: types.POSITIONS.SUCCESS,
		payload: data,
	}),
	failure: (err) => ({
		type: types.POSITIONS.FAILURE,
		error: true,
		payload: err,
	}),
};


export default {
	getPositions,
};
