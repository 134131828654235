import React, { Fragment } from 'react';
import { Route, Switch, Link } from 'react-router-dom';

import { Grid, Typography } from '@material-ui/core';

import Orders from '@@Components/Options/Orders/Orders';
import PositionsList from '@@Components/Options/Positions/PositionsList';
import Transactions from '@@Components/Options/Transactions/Transactions';
import Dashboard from '@@Components/Options/Dashboard/Dashboard';


const Options = (props) => {
	return (
		<Fragment>
			<Typography variant="h5" gutterBottom>Options</Typography>

			<Grid container>
				<Link to="/options/orders">Orders</Link>
				<Switch>
					<Route exact path='/dashboard' render={(props) => (<Dashboard {...props} />)} />
					<Route exact path='/options/orders' render={(props) => (<Orders {...props} />)} />
					<Route exact path='/positions' render={(props) => (<PositionsList {...props} />)} />
					<Route exact path='/transactions' render={(props) => (<Transactions {...props} />)} />
				</Switch>
			</Grid>

		</Fragment>
	);
};

export default Options;
