import types from './types';


const createAgent = {
	request: (params) => ({
		type: types.AGENT_CREATE.REQUEST,
		payload: params,
		meta: {
			message: 'Creating Agent',
		}
	}),
	success: (data, params) => ({
		type: types.AGENT_CREATE.SUCCESS,
		payload: data,
		meta: { ...params }
	}),
	failure: (err) => ({
		type: types.AGENT_CREATE.FAILURE,
		error: true,
		payload: err,
	}),
};

const getAgents = {
	request: () => ({
		type: types.AGENTS.REQUEST,
	}),
	success: (data) => ({
		type: types.AGENTS.SUCCESS,
		payload: data,
	}),
	failure: (err) => ({
		type: types.AGENTS.FAILURE,
		error: true,
		payload: err,
	}),
};

const deleteAgent = {
	request: (params) => ({
		type: types.AGENT_DELETE.REQUEST,
		payload: params,
		meta: {
			message: `Deleting Agent ${params.name}`,
		}
	}),
	success: (data, params) => ({
		type: types.AGENT_DELETE.SUCCESS,
		payload: data,
		meta: { ...params }
	}),
	failure: (err) => ({
		type: types.AGENT_DELETE.FAILURE,
		error: true,
		payload: err,
	}),
};

const startAgent = {
	request: (params) => ({
		type: types.AGENT_START.REQUEST,
		payload: params,
		meta: {
			message: `Starting Agent ${params.name}`,
		}
	}),
	success: (data, params) => ({
		type: types.AGENT_START.SUCCESS,
		payload: data,
		meta: { ...params }
	}),
	failure: (err) => ({
		type: types.AGENT_START.FAILURE,
		error: true,
		payload: err,
	}),
};

const stopAgent = {
	request: (params) => ({
		type: types.AGENT_STOP.REQUEST,
		payload: params,
		meta: {
			message: `Stopping Agent ${params.name}`,
		}
	}),
	success: (data, params) => ({
		type: types.AGENT_STOP.SUCCESS,
		payload: data,
		meta: { ...params }
	}),
	failure: (err) => ({
		type: types.AGENT_STOP.FAILURE,
		error: true,
		payload: err,
	}),
};

export default {
	createAgent,
	getAgents,
	deleteAgent,
	startAgent,
	stopAgent,
}
