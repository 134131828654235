import { RSAA } from 'redux-api-middleware';

import config from '@@Config';
import types from './types';


const getTransactions = () => {
	const url = `${config.api.hostname}/transactions`;
	return {
		[RSAA]: {
			endpoint: url,
			method: 'GET',
			headers: { ...RSAA.headers, 'Content-Type': 'application/json' },
			types: [types.TRANSACTIONS.REQUEST, types.TRANSACTIONS.SUCCESS, types.TRANSACTIONS.FAILURE],
		},
	};
};

const getTransaction = (transactionId) => {
	const url = `${config.api.hostname}/transactions/${transactionId}`;
	return {
		[RSAA]: {
			endpoint: url,
			method: 'GET',
			headers: { ...RSAA.headers, 'Content-Type': 'application/json' },
			types: [types.TRANSACTION.REQUEST, types.TRANSACTION.SUCCESS, types.TRANSACTION.FAILURE],
		},
	};
};

export default {
	getTransactions,
	getTransaction,
}
