import React, { Fragment } from 'react';
import prettyMs from 'pretty-ms';
import Moment from 'react-moment';

import { Grid, Divider, ButtonGroup, Button, Box, CardContent, CardActions, Typography, TableFooter, TableContainer, Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import StopIcon from '@material-ui/icons/Stop';

import DebugOutput from '@@Components/DebugOutput';
import QuoteCard from '@@Components/QuoteCard';
import { agentStatuses } from '@@Constants';
import { currency } from '@@Utils';

import Indicators from './Indicators';
import Iteration from './Iteration';
import Targets from './Targets';
import * as Styles from './AgentCard.styles';


const AgentCard = ({
	agent,
	onDelete,
	onStart,
	onStop,
}) => {

	const { status } = agent;
	const { config, contracts, balances, dataset, quotes, orders, positions, positionDelta, agentStatus, spreads, iterations } = status;
	const underlying = config ? config.underlyingSymbol : '';

	if (status) {
		return (
			<Box m={2}>
				<Styles.Card>
					<Styles.CardHeader title={
						<Box>
							<Grid container justifyContent="space-between" alignItems="flex-start">
								<Grid item xs={6}>
									<Typography component="h6" variant="h6">
										{config.name}
									</Typography>
								</Grid>
								<Grid item xs={3}>
									<Typography variant="caption">
										Iterations: {Object.keys(iterations).length}
									</Typography>
									<Typography>
										At Risk: {currency(balances.openingTransactions * 100)}
									</Typography>
								</Grid>
								<Grid item xs={3} style={{ textAlign: 'right' }}>
									{status && status.agentStatus && (
										<Fragment>
											<Styles.StatusBadge
												status={status.agentStatus}>{status.agentStatus}</Styles.StatusBadge>
											{status.error && (
												<Typography component="div" variant="caption" color="error" gutterBottom>
													{status.error}
												</Typography>
											)}
										</Fragment>
									)}
									<Typography variant="caption" component="div" style={{ textAlign: 'right' }} gutterBottom>
										{status.duration ? prettyMs(status.duration) : null}
									</Typography>
									<Typography variant="caption">
										Iterations: {Object.keys(iterations).length}
									</Typography>
								</Grid>
							</Grid>
							<Grid container spacing={4} justifyContent="space-between" alignItems="flex-start">
								<Grid item xs={8}>
									<Box mt={2}>
										<Typography color="primary" variant="h1" component="h1">
											{config.underlyingSymbol}
										</Typography>
										{status.quotes && quotes[underlying] && (
											<Fragment>
												<Typography style={{ fontSize: 10, color: '#999' }} gutterBottom>
													{quotes[underlying].description}
												</Typography>

												<Typography color="primary" variant="h6" component="div">
													{currency(quotes[underlying].last)}
												</Typography>

											</Fragment>
										)}
										{/*<DebugOutput data={quotes[underlying]} label={underlying} />*/}
									</Box>
								</Grid>
								<Grid item xs={4}>
									<Typography component="div" variant="body1">
										<Styles.DescriptionList>
											<dt>Allocated Funds:</dt>
											<dd>{currency(config.allocatedFunds, true)}</dd>
											<dt>Spread Interval:</dt>
											<dd>{currency(config.spreadInterval, true)}</dd>
											<dt>Target Call Delta:</dt>
											<dd>{config.targetCallDelta}</dd>
											<dt>Target Put Delta:</dt>
											<dd>{config.targetPutDelta}</dd>
											<dt>Expiration Date:</dt>
											<dd><strong><Moment date={config.expirationDate} format="DD MMM YY"/></strong></dd>
										</Styles.DescriptionList>
									</Typography>
								</Grid>
							</Grid>
						</Box>
					}>
					</Styles.CardHeader>

					<CardContent>

						{/* <DebugOutput data={status.subscriptions} label="subscriptions" /> */}

						{/* <DebugOutput data={quotes} label="quotes" /> */}

						{/* <DebugOutput data={status.extra} label="extra" /> */}

						{/* <DebugOutput data={spreads.available} label="available spreads" /> */}

						<Indicators dataset={dataset} />

						<Divider />

						{/*<Grid container spacing={2}>*/}
						{/*	{Object.entries(quotes).map(([key, quote]) => (*/}
						{/*		<Grid item xs={3}>*/}
						{/*			<QuoteCard key={key} symbol={key} quote={quote} />*/}
						{/*		</Grid>*/}
						{/*	))}*/}
						{/*</Grid>*/}

						{/* <DebugOutput data={iterations} label="iterations" /> */}
						<Box my={2}>
							<Typography variant="h3" gutterBottom>Position</Typography>
							{Object.values(iterations).map((iteration) => (
								<Iteration
									key={iteration.id}
									iteration={iteration}
									quotes={quotes}
									config={config}
								/>
							))}
						</Box>

						{/*<Typography variant="h3" gutterBottom>Quotes</Typography>
						<Grid container spacing={2}>
							<Grid item xs>
								<QuoteCard service="QUOTE" symbol={underlying} />
							</Grid>

							{status.subscriptions.OPTION && status.subscriptions.OPTION.map((symbol) => {
								return (
									<Grid key={symbol} item xs={3}>
										<QuoteCard service="OPTION" symbol={symbol} />
									</Grid>
								);
							})}
						</Grid>*/}

						<Grid container spacing={2}>
							<Grid item xs={12}>
								<QuoteCard
									service="QUOTE"
									symbol={underlying}
								/>
							</Grid>
							<Grid item xs={3}>
								<QuoteCard
									service="OPTION"
									symbol={contracts.target.short.call.symbol}
									// quote={quotes[contracts.target.short.call.symbol]}
								/>
							</Grid>
							<Grid item xs={3}>
								<QuoteCard
									service="OPTION"
									symbol={contracts.target.long.call.symbol}
									// quote={quotes[contracts.target.long.call.symbol]}
								/>
							</Grid>
							<Grid item xs={3}>
								<QuoteCard
									service="OPTION"
									symbol={contracts.target.long.put.symbol}
									// quote={quotes[contracts.target.long.put.symbol]}
								/>
							</Grid>
							<Grid item xs={3}>
								<QuoteCard
									service="OPTION"
									symbol={contracts.target.short.put.symbol}
									// quote={quotes[contracts.target.short.put.symbol]}
								/>
							</Grid>
						</Grid>


						<Box my={2}>
							<Typography variant="h3" gutterBottom>Orders</Typography>
							{orders && Object.values(orders).map((order) => (
								<Box my={3} key={order.id}>
									<DebugOutput
										data={order}
										label={order.id}
									/>
								</Box>
							))}
						</Box>

						<dl>
							<dt>Opening</dt>
							<dd>{currency(balances.openingTransactions * 100)}</dd>
							<dt>Closing</dt>
							<dd>{currency(balances.closingTransactions * 100)}</dd>
							<dt>Total</dt>
							<dd>{currency((balances.openingTransactions + balances.closingTransactions) * 100)}</dd>
						</dl>

					</CardContent>

					<CardActions>
						<Box p={1}>
							<ButtonGroup variant="contained" aria-label="contained primary button group">

								<Button
									disabled={agentStatus !== agentStatuses.IDLE}
									onClick={() => onStart(agent)}>
									<PlayArrowIcon /> Start
								</Button>

								<Button
									disabled={[agentStatuses.ERROR, agentStatuses.IDLE].includes(agentStatus)}
									onClick={() => onStop(agent)}>
									<StopIcon /> Stop
								</Button>

								<Button
									onClick={() => onDelete(agent)}>
									<DeleteForeverIcon /> Delete
								</Button>

							</ButtonGroup>
						</Box>
					</CardActions>

				</Styles.Card>
			</Box>
		);
	}
	return null;
};

export default AgentCard;
