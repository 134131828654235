import React from 'react';
import { connect } from 'react-redux';
import { withTheme, Button, Box, Paper, TableContainer, Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core';
import Moment from 'react-moment';

import { dateFormats } from '@@Constants';
import { selectors as orderSelectors } from '@@Redux/orders';
import { currency } from '@@Utils';


const OrdersList = (props) => {
	return (
		<Box my={2}>
			<TableContainer component={Paper}>
				<Table size="small">
					<TableHead>
						<TableRow>
							<TableCell>Order Date / ID</TableCell>
							<TableCell>Status</TableCell>
							<TableCell>Type</TableCell>
							<TableCell>Legs</TableCell>
							<TableCell align="right">Price</TableCell>
							<TableCell />
						</TableRow>
					</TableHead>

					<TableBody>
						{props.orders.map((order, i) => {

							const { orderId, status, enteredTime, duration, orderType, quantity, filledQuantity, remainingQuantity, price, orderLegCollection, cancelable } = order;

							return (
								<TableRow key={orderId} style={ i % 2 === 0 ? { backgroundColor: '#fafafa'} : {}}>
									<TableCell>
										<div><Moment date={enteredTime} format={dateFormats.DATE_TIME} /></div>
										<div>#{orderId}</div>
									</TableCell>
									<TableCell>
										{status}
									</TableCell>
									<TableCell>
										<div>{orderType}</div>
									</TableCell>
									<TableCell>
										{orderLegCollection.map((leg) => {
											const { legId, orderLegType, instrument, instruction, positionEffect, quantity } = leg;
											const { assetType, symbol, description } = instrument;
											return (
												<div key={legId}>
													{instrument.symbol}:
													{instrument.description}
												</div>
											);
										})}
									</TableCell>
									<TableCell align="right">{currency(price)}</TableCell>
									<TableCell>
										{cancelable && (
											<Button variant="text" color="secondary" onClick={() => { props.onCancel(orderId); }}>
												Cancel
											</Button>
										)}
									</TableCell>
								</TableRow>
							)
						})}
					</TableBody>
				</Table>
			</TableContainer>
		</Box>
	)
};

const mapStateToProps = (state, ownProps) => {
	let { orders } = ownProps;

	if (!orders) {
		orders = orderSelectors.getOrders(state);
	}

	return {
		orders,
	};
};

export default connect(
	mapStateToProps,
)(withTheme(OrdersList));
