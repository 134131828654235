import React from 'react';
import { connect } from 'react-redux';
import { v4 as uuid } from 'uuid';

import { Box, Button, Grid } from '@material-ui/core';

import QuoteCard from '@@Components/QuoteCard';
import { operations as agentOperations } from '@@Redux/agents';
import { operations as socketOperations } from '@@Redux/socket';

import FuturesPair from '@@Components/Futures/FuturesPair';


const Futures = ({
	createAgent,
	subscribe,
}) => {

	const startAgent = (symbol) => {
		const underlying = symbol.toUpperCase();
		const agentObj = {};

		agentObj.id = uuid();
		agentObj.config = {
			type: 'FUTURES_PAIR',
			underlyingSymbol: underlying,
		};

		createAgent(agentObj);
		subscribe({ service: 'LEVELONE_FUTURES', symbol: underlying });
	};

	const startFuturesPair = (options) => {

		const { instrumentA, instrumentB } = options;
		const agentObj = {};

		agentObj.id = uuid();
		agentObj.config = {
			type: 'FUTURES_PAIR',
			instrumentA,
			instrumentB,
		};

		console.log('agentObj.config', agentObj.config);
		createAgent(agentObj);
		subscribe({ service: 'LEVELONE_FUTURES', symbol: instrumentA });
		subscribe({ service: 'LEVELONE_FUTURES', symbol: instrumentB });
	};

	return (
		<Box>
			<Button onClick={() => startAgent('/XC')}>/XC</Button>
			<Button onClick={() => startAgent('/XW')}>/XW</Button>

			<Button
				onClick={() => startFuturesPair({
					instrumentA: '/XCK21',
					instrumentB: '/XCN21',
				})}
			>
				/XCK21 + XCN21
			</Button>

			{/*<QuoteCard service="LEVELONE_FUTURES" symbol="/XC" />*/}
			{/*<QuoteCard service="LEVELONE_FUTURES" symbol="/XW" />*/}
			{/*<QuoteCard service="LEVELONE_FUTURES" symbol="/XK" />*/}

			<Grid container spacing={1}>
				<Grid item>
					<QuoteCard service="LEVELONE_FUTURES" symbol="/XC" />
				</Grid>
				<Grid item>
					<QuoteCard service="LEVELONE_FUTURES" symbol="/XW" />
				</Grid>
			</Grid>

			<Grid container spacing={1}>
				<Grid>
					<FuturesPair />
				</Grid>
			</Grid>


			<Grid container spacing={1}>
				<Grid item>
					<QuoteCard service="LEVELONE_FUTURES" symbol="/YM" />
				</Grid>
				<Grid item>
					<QuoteCard service="LEVELONE_FUTURES" symbol="/ES" />
				</Grid>
				<Grid item>
					<QuoteCard service="LEVELONE_FUTURES" symbol="/NQ" />
				</Grid>
			</Grid>

		</Box>
	);
};

const mapStateToProps = (state) => {
	return {
	}
};

const mapDispatchToProps = {
	createAgent: agentOperations.createAgent,
	subscribe: socketOperations.subscribe,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(Futures);
