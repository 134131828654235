import types from './types';


const getAccount = {
	request: (params) => ({
		type: types.ACCOUNT.REQUEST,
		payload: params,
	}),
	success: (data, params) => ({
		type: types.ACCOUNT.SUCCESS,
		payload: data,
		meta: { ...params }
	}),
	failure: (err) => ({
		type: types.ACCOUNT.FAILURE,
		error: true,
		payload: err,
	}),
};

const getAccountInstruments = {
	request: (params) => ({
		type: types.ACCOUNT_INSTRUMENTS.REQUEST,
		payload: params,
	}),
	success: (data, params) => ({
		type: types.ACCOUNT_INSTRUMENTS.SUCCESS,
		payload: data,
		meta: { ...params }
	}),
	failure: (err) => ({
		type: types.ACCOUNT_INSTRUMENTS.FAILURE,
		error: true,
		payload: err,
	}),
}

const getOpenPositions = {
	request: (params) => ({
		type: types.ACCOUNT_POSITIONS.REQUEST,
		payload: params,
	}),
	success: (data, params) => ({
		type: types.ACCOUNT_POSITIONS.SUCCESS,
		payload: data,
		meta: { ...params }
	}),
	failure: (err) => ({
		type: types.ACCOUNT_POSITIONS.FAILURE,
		error: true,
		payload: err,
	}),
}

export default {
	getAccount,
	getAccountInstruments,
	getOpenPositions,
}
