import React, { Fragment, useEffect, memo } from 'react';
import { connect } from 'react-redux';
import { Form, Formik } from 'formik';
import { v4 as uuid } from 'uuid';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/theme-tomorrow';


import { InputLabel, Box, Typography, FormHelperText, FormControl, IconButton, MenuItem, Grid, TextField, withTheme, DialogContent, Dialog, Button, DialogActions } from '@material-ui/core';
// import MaterialTable from 'material-table';
import MaterialTable from 'material-table';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import { DataGrid } from '@material-ui/data-grid';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import { table as tableDefaults } from '@@Config';
import orderActions from '@@Constants/orderActions';
import DialogTitle from '@@Components/Dialog/DialogTitle/DialogTitle';
import { getPipFactor } from '@@Utils';
import { operations as agentOperations, selectors as agentSelectors } from '@@Redux/agents';
import { selectors as accountSelectors, operations as accountOperations } from '@@Redux/account';
import DebugOutput from '@@Components/DebugOutput';
import tableIcons from '@@Theme/tableIcons';
import {colors} from '@@Theme';
import {getAccountInstruments} from '@@Redux/account/operations';


const AgentDialog = ({
	agent,
	createAgent,
	instruments,
	instrumentsEnabled,
	instrumentsAll,
	instrumentsActive,
	isOpen,
	onClose,
}) => {

	useEffect(() => {
		// getAccountInstruments('oanda');
	}, [getAccountInstruments]);

	const getInitialValues = () => {
		return {
			name: agent ? agent.name : '',
			instrument: localStorage.getItem('instrument') ? localStorage.getItem('instrument') : null, //instruments.EUR_USD.name,
			instrumentA: instruments.EUR_USD,
			instrumentB: instruments.NZD_JPY,
			actionA: orderActions.BUY_TO_OPEN,
			actionB: orderActions.BUY_TO_OPEN,
			unitsA: 50,
			unitsB: 50,
			correlationDirection: 'POSITIVE',
			fromDate: moment(localStorage.getItem('fromDate') || '2021-02-01'),
			toDate: moment(localStorage.getItem('toDate') || '2021-02-02'),
			targetPips: localStorage.getItem('targetPips') ? Number(localStorage.getItem('targetPips')) : 10,
			trailingStopLossTargetPips: localStorage.getItem('trailingStopLossTargetPips') ? Number(localStorage.getItem('trailingStopLossTargetPips')) : 4,
			trailingStopLossPips: localStorage.getItem('trailingStopLossPips') ? Number(localStorage.getItem('trailingStopLossPips')) : 2,
			stopLossPips: localStorage.getItem('stopLossPips') ? Number(localStorage.getItem('stopLossPips')) : 50,
			type: 'FOREX_PRICE_FOLLOWER', // 'FOREX_GRID', //'FOREX_BACKTEST_GRID',
			initialUnits: localStorage.getItem('initialUnits') ? Number(localStorage.getItem('initialUnits')) : 1000,
			maxUnits: localStorage.getItem('maxUnits') ? Number(localStorage.getItem('maxUnits')) : 5000,
			maxLevel: localStorage.getItem('maxLevel') ? Number(localStorage.getItem('maxLevel')) : 5,
			levelIncrement: localStorage.getItem('levelIncrement') ? Number(localStorage.getItem('levelIncrement')) : 0.5,
			levelMultiplier: localStorage.getItem('levelMultiplier') ? Number(localStorage.getItem('levelMultiplier')) : 1,
			positionAddPercentage: 90,
			longConditionAdd: '',
			longConditionOpen: '',
			shortConditionAdd: '',
			shortConditionOpen: '',
			longCondition: 'return I_M5.RSI.value < 50;',
			shortCondition: 'return I_M5.RSI.value > 50;',
		};
	};

	const handleSubmit = (values) => {
		let { type, name, targetPips, stopLossPips, trailingStopLossTargetPips, trailingStopLossPips } = values;
		const instrument = instruments[values.instrument];

		const fromDate = moment(values.fromDate).format('YYYY-MM-DD');
		const toDate = moment(values.toDate).format('YYYY-MM-DD');

		localStorage.setItem('instrument', instrument.name);
		localStorage.setItem('initialUnits', values.initialUnits);
		localStorage.setItem('maxUnits', values.maxUnits);
		localStorage.setItem('maxLevel', values.maxLevel);
		localStorage.setItem('levelIncrement', values.levelIncrement);
		localStorage.setItem('levelMultiplier', values.levelMultiplier);
		localStorage.setItem('targetPips', values.targetPips);
		localStorage.setItem('stopLossPips', values.stopLossPips);
		localStorage.setItem('fromDate', fromDate);
		localStorage.setItem('toDate', toDate);
		localStorage.setItem('trailingStopLossTargetPips', trailingStopLossTargetPips);
		localStorage.setItem('trailingStopLossPips', trailingStopLossPips);

		const agent = {};

		agent.id = uuid();

		agent.config = {
			name: name || '',
			targetPips,
			stopLossPips,
			trailingStopLossTargetPips,
			trailingStopLossPips,
			type,
			instruments: {
				core: {},
			},
			conditions: {},
		};

		if (type === 'FOREX_BACKTEST_SINGLE') {
			agent.config.instruments.core = {
				...instrument,
				units: values.units,
				pipFactor: getPipFactor(instrument),
			};
			agent.config.fromDate = moment(values.fromDate).format('YYYY-MM-DD');
			agent.config.toDate = moment(values.toDate).format('YYYY-MM-DD');
			agent.config.maxUnits = values.maxUnits;
			agent.config.initialUnits = values.initialUnits;
			agent.config.positionAddPercentage = values.positionAddPercentage / 100;
		} else if (type === 'FOREX_BACKTEST_PAIR_REVERSION') {
			agent.config.instruments.pairA = {
				name: values.instrumentA,
				units: values.unitsA,
				pipFactor: getPipFactor(values.instrumentA),
			};

			agent.config.instruments.pairB = {
				name: values.instrumentB,
				units: values.unitsB,
				pipFactor: getPipFactor(values.instrumentB),
			};

			agent.config.fromDate = moment(values.fromDate).format('YYYY-MM-DD');
			agent.config.toDate = moment(values.toDate).format('YYYY-MM-DD');
			agent.config.correlationDirection = values.correlationDirection;
		} else if (type === 'FOREX_BACKTEST_GRID') {
			agent.config.instruments.core = {
				name: instrument,
				pipFactor: getPipFactor(instrument),
			};
			agent.config.fromDate = moment(values.fromDate).format('YYYY-MM-DD');
			agent.config.toDate = moment(values.toDate).format('YYYY-MM-DD');
			agent.config.maxUnits = values.maxUnits;
			agent.config.initialUnits = values.initialUnits;
			agent.config.positionAddPercentage = values.positionAddPercentage / 100;
		} else if (type === 'FOREX_SINGLE') {
			agent.config.instruments.core = {
				name: instrument,
				pipFactor: getPipFactor(instrument),
			};
			agent.config.maxUnits = values.maxUnits;
			agent.config.initialUnits = values.initialUnits;
			agent.config.positionAddPercentage = values.positionAddPercentage / 100;
			agent.config.longConditionAdd = values.longConditionAdd;
			agent.config.longConditionOpen = values.longConditionOpen;
			agent.config.shortConditionAdd = values.shortConditionAdd;
			agent.config.shortConditionOpen = values.shortConditionOpen;
		} else if (type === 'FOREX_GRID') {
			agent.config.instruments.core = {
				...instrument,
				pipFactor: getPipFactor(instrument),
			};
			agent.config.initialUnits = values.initialUnits;
			// agent.config.maxUnits = values.maxUnits;
			agent.config.maxLevel = values.maxLevel;
			agent.config.levelIncrement = values.levelIncrement;
			agent.config.levelMultiplier = values.levelMultiplier;
		} else if (type === 'FOREX_PRICE_FOLLOWER') {
			agent.config.instruments.core = {
				...instrument,
				pipFactor: getPipFactor(instrument),
			};
			agent.config.initialUnits = values.initialUnits;
			// agent.config.maxUnits = values.maxUnits;
			agent.config.maxLevel = values.maxLevel;
			agent.config.levelIncrement = values.levelIncrement;
			agent.config.levelMultiplier = values.levelMultiplier;
		} else if (type === 'FOREX_SPREAD_HEDGE') {
			agent.config.instruments.core = {
				name: instrument,
				pipFactor: getPipFactor(instrument),
			};
			agent.config.maxUnits = values.maxUnits;
			agent.config.initialUnits = values.initialUnits;
			agent.config.positionAddPercentage = values.positionAddPercentage / 100;
		} else if (type === 'FOREX_CONDITIONAL') {
			agent.config.instruments.core = {
				...instrument,
				getPipFactor: getPipFactor(instrument),
			};
			agent.config.maxUnits = values.maxUnits;
			agent.config.initialUnits = values.initialUnits;
			agent.config.conditions.long = values.longCondition;
			agent.config.conditions.short = values.shortCondition;
		}

		createAgent(agent);

		onClose();
	};

	return (
		<Dialog
			open={isOpen}
			onClose={onClose}
			aria-labelledby="dialog-title"
			fullWidth
			maxWidth="md"
		>
			<DialogTitle id="dialog-title" onClose={onClose}>Add Agent</DialogTitle>

			<Formik
				initialValues={getInitialValues()}
				enableReinitialize={true}
				onSubmit={handleSubmit}
			>
				{({
					values,
					errors,
					touched,
					handleChange,
					handleBlur,
					isSubmitting,
					setFieldValue
				}) => (
					<Form>
						<DialogContent>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<TextField
										fullWidth
										id="type"
										name="type"
										label="Agent Type"
										select
										onBlur={handleBlur}
										onChange={handleChange}
										value={values.type}
										variant="filled"
									>
										<MenuItem key={0} value="FOREX_BACKTEST_SINGLE">BACKTEST: SINGLE</MenuItem>
										<MenuItem key={1} value="FOREX_BACKTEST_PAIR_REVERSION">BACKTEST: PAIR REVERSION</MenuItem>
										<MenuItem key={2} value="FOREX_BACKTEST_GRID">BACKTEST: GRID</MenuItem>
										<MenuItem key={3} value="FOREX_SINGLE">SINGLE</MenuItem>
										<MenuItem key={4} value="FOREX_GRID">GRID</MenuItem>
										<MenuItem key={5} value="FOREX_PRICE_FOLLOWER">PRICE FOLLOWER</MenuItem>
										<MenuItem key={6} value="FOREX_SPREAD_HEDGE">SPREAD HEDGE</MenuItem>
										<MenuItem key={7} value="FOREX_CONDITIONAL">CONDITIONAL</MenuItem>
									</TextField>
								</Grid>

								{values.type === 'FOREX_SINGLE' && (
									<Grid item xs={12}>
										<TextField
											fullWidth
											id="name"
											name="name"
											label="Agent Name"
											onBlur={handleBlur}
											onChange={handleChange}
											value={values.name}
											variant="filled"
										/>
									</Grid>
								)}

								{(values.type === 'FOREX_BACKTEST_SINGLE'
									|| values.type === 'FOREX_BACKTEST_PAIR_REVERSION'
									|| values.type === 'FOREX_BACKTEST_GRID'
								) && (
									<Fragment>
										<Grid item xs={6}>
											<FormControl
												fullWidth
												margin="normal"
											>
												<MuiPickersUtilsProvider utils={MomentUtils}>
													<DatePicker
														format="YYYY-MM-DD"
														autoOk={true}
														disableToolbar
														margin="normal"
														id="fromDate"
														name="fromDate"
														label="From Date"
														value={values.fromDate}
														inputVariant="filled"
														onBlur={handleBlur}
														onChange={(value) => {
															const toDate = moment(value).add(1, 'day');
															setFieldValue('fromDate', value);
															setFieldValue('toDate', toDate);
															//localStorage.setItem('fromDate', value.format('YYYY-MM-DD'));
															//localStorage.setItem('toDate', toDate.format('YYYY-MM-DD'));
														}}
													/>
												</MuiPickersUtilsProvider>

											</FormControl>
										</Grid>
										<Grid item xs={6}>
											<FormControl
												fullWidth
												margin="normal"
											>
												<MuiPickersUtilsProvider utils={MomentUtils}>
													<DatePicker
														format="YYYY-MM-DD"
														autoOk={true}
														disableToolbar
														margin="normal"
														id="toDate"
														name="toDate"
														label="To Date"
														value={values.toDate}
														inputVariant="filled"
														onBlur={handleBlur}
														onChange={(value) => {
															setFieldValue('toDate', value);
															//localStorage.setItem('toDate', value.format('YYYY-MM-DD'));
														}}
													/>
												</MuiPickersUtilsProvider>
											</FormControl>
										</Grid>
									</Fragment>
								)}

								{(values.type === 'FOREX_BACKTEST_SINGLE'
									|| values.type === 'FOREX_BACKTEST_PAIR_REVERSION'
									|| values.type === 'FOREX_BACKTEST_GRID'
									|| values.type === 'FOREX_GRID'
									|| values.type === 'FOREX_PRICE_FOLLOWER'
								) && (
									<Grid item xs={6} sm={3}>
										<TextField
											fullWidth
											type="number"
											id="target-pips"
											name="targetPips"
											label="Target Pips"
											onBlur={handleBlur}
											onChange={handleChange}
											value={values.targetPips}
											variant="filled"
										/>
									</Grid>
								)}
								<Grid item xs={4} sm={3}>
									<TextField
										fullWidth
										type="number"
										id="stop-loss-pips"
										name="stopLossPips"
										label="Stop-Loss Pips"
										onBlur={handleBlur}
										onChange={handleChange}
										value={values.stopLossPips}
										variant="filled"
									/>
								</Grid>
								<Grid item xs={4} sm={3}>
									<TextField
										fullWidth
										type="number"
										id="trailing-stop-loss-target-pips"
										name="trailingStopLossTargetPips"
										label="Trailing Stop Target Pips"
										onBlur={handleBlur}
										onChange={handleChange}
										value={values.trailingStopLossTargetPips}
										variant="filled"
									/>
								</Grid>
								<Grid item xs={4} sm={3}>
									{instruments && (
										<TextField
											fullWidth
											type="number"
											// inputProps={values.instrument && instruments[values.instrument] && {
											// 	min: instruments[values.instrument].minimumTrailingStopDistance * getPipFactor(values.instrument),
											// 	max: instruments[values.instrument].maximumTrailingStopDistance * getPipFactor(values.instrument)
											// }}
											inputProps={{
												min: 0,
											}}
											id="trailing-stop-loss-pips"
											name="trailingStopLossPips"
											label="Trailing Stop Amount Pips"
											onBlur={handleBlur}
											onChange={handleChange}
											value={values.trailingStopLossPips}
											variant="filled"
										/>
									)}
								</Grid>

								{/* <Grid item xs={6}>
									<TextField
										fullWidth
										type="number"
										id="position-add-percentage"
										name="positionAddPercentage"
										label="Percent to Add"
										onBlur={handleBlur}
										onChange={handleChange}
										value={values.positionAddPercentage}
										variant="filled"
									/>
								</Grid> */}
							</Grid>

							{values.type === 'FOREX_CONDITIONAL' && (
								<Grid container spacing={5}>
									<Grid item sm={6}>
										<InputLabel htmlFor="long-condition">LONG Condition</InputLabel>
										<Box my={1}>
											<AceEditor
												id="long-condition"
												mode="javascript"
												theme="tomorrow"
												showPrintMargin={false}
												showGutter={true}
												highlightActiveLine={false}
												onChange={(value) => {
													setFieldValue('longCondition', value);
												}}
												name="longCondition"
												editorProps={{$blockScrolling: true}}
												value={values.longCondition}
												tabSize={3}
												width="100%"
												height="50px"
											/>
										</Box>
										<FormHelperText>LONG position when condition returns true</FormHelperText>
									</Grid>
									<Grid item sm={6}>
										<InputLabel htmlFor="short-condition">SHORT Condition</InputLabel>
										<Box my={1}>
											<AceEditor
												id="short-condition"
												mode="javascript"
												theme="tomorrow"
												showPrintMargin={false}
												showGutter={true}
												highlightActiveLine={false}
												onChange={(value) => {
													setFieldValue('shortCondition', value);
												}}
												name="shortCondition"
												editorProps={{$blockScrolling: true}}
												value={values.shortCondition}
												tabSize={3}
												width="100%"
												height="50px"
											/>
										</Box>
										<FormHelperText>SHORT position when condition returns true</FormHelperText>
									</Grid>
								</Grid>
							)}

							{(
								values.type === 'FOREX_BACKTEST_SINGLE'
								|| values.type === 'FOREX_SINGLE'
								|| values.type === 'FOREX_GRID'
								|| values.type === 'FOREX_PRICE_FOLLOWER'
								|| values.type === 'FOREX_BACKTEST_GRID'
								|| values.type === 'FOREX_SPREAD_HEDGE'
								|| values.type === 'FOREX_CONDITIONAL'
							) && (
								<Fragment>
									<Grid container spacing={2}>
										<Grid item xs={12}>
											<Box mt={2} mb={4}>
												<MaterialTable
													{...tableDefaults}
													icons={tableIcons}
													data={instrumentsAll}
													options={{
														...tableDefaults.options(),
														search: false,
														rowStyle: (rowData) => {
															let rowStyle = {};

															if (!rowData.enabled) {
																rowStyle.color = '#aaa';
															}

															if (values.instrument === rowData.name) {
																rowStyle.backgroundColor = colors.secondary['50'];
															}

															if (instrumentsActive.includes(rowData.name)) {
																rowStyle.backgroundColor = colors.primary['100'];
															}

															return rowStyle;
														}
													}}
													columns={[
														{
															width: 25,
															sorting: false,
															cellStyle: { padding: 5 },
															render: (rowData) => {
																return (
																	<Fragment>
																		<IconButton
																			disabled={!rowData.enabled || instrumentsActive.includes(rowData.name)}
																			onClick={() => setFieldValue('instrument', rowData.name)}
																		>
																			{values.instrument === rowData.name && (
																				<RadioButtonCheckedIcon fontSize="small" />
																			)}
																			{values.instrument !== rowData.name && (
																				<RadioButtonUncheckedIcon fontSize="small" />
																			)}
																		</IconButton>
																	</Fragment>
																);
															}
														},
														{
															width: 500,
															cellStyle: { whiteSpace: 'nowrap' },
															render: (rowData) => {
																return (
																	<Fragment>
																		<Typography variant="h6">
																			<strong>{rowData.displayName}</strong>
																		</Typography>
																		<Typography variant="subtitle2" component="div">
																			{rowData.description}
																		</Typography>
																		{rowData.nickname && rowData.nickname.length > 0 && (
																			<Typography variant="subtitle2" component="em" color="textSecondary">
																				{rowData.nickname.join(', ')}
																			</Typography>
																		)}
																	</Fragment>
																)
															}
														},
														{ title: 'Spread', field: 'spread', align: 'right', width: 100 },
														{ title: 'Margin', field: 'marginRate', align: 'right', width: 100 },
														{ title: 'Financing (Long)', field: 'financingRateLong', align: 'right', width: 100 },
														{ title: 'Financing (Short)', field: 'financingRateShort', align: 'right', width: 100 },
													]}
												/>
											</Box>
										</Grid>
									</Grid>

									<Grid container spacing={2}>
										{/* <Grid item xs={12}>
											<TextField
												fullWidth
												select
												id="instrument"
												name="instrument"
												label="Instrument"
												onBlur={handleBlur}
												onChange={handleChange}
												value={values.instrument}
												variant="filled"
											>
												{instrumentsEnabled.map((instrument) => (
													<MenuItem key={instrument.name} value={instrument.name}>
														{instrument.name}
														: {instrument.marginRate}
														: {instrument.minimumTrailingStopDistance}
													</MenuItem>
												))}
											</TextField>
										</Grid> */}
										<Grid item xs={6} sm={3}>
											<TextField
												fullWidth
												id="initial-units"
												name="initialUnits"
												type="number"
												label="Initial Units"
												InputProps={{ inputProps: { min: 0 } }}
												onBlur={handleBlur}
												onChange={handleChange}
												value={values.initialUnits}
												variant="filled"
											/>
										</Grid>
										{/*<Grid item xs={3}>
											<TextField
												fullWidth
												id="max-units"
												name="maxUnits"
												type="number"
												label="Maximum Units"
												InputProps={{ inputProps: { min: 0 } }}
												onBlur={handleBlur}
												onChange={handleChange}
												value={values.maxUnits}
												variant="filled"
											/>
										</Grid>*/}
										<Grid item xs={6} sm={3}>
											<TextField
												fullWidth
												id="max-level"
												name="maxLevel"
												type="number"
												label="Maximum Level"
												InputProps={{ inputProps: { min: 1 } }}
												onBlur={handleBlur}
												onChange={handleChange}
												value={values.maxLevel}
												variant="filled"
											/>
										</Grid>
										<Grid item xs={6} sm={3}>
											<TextField
												fullWidth
												id="level-increment"
												name="levelIncrement"
												type="number"
												label="Level Increment"
												onBlur={handleBlur}
												onChange={handleChange}
												value={values.levelIncrement}
												variant="filled"
												helperText="Standard Deviation (SD)"
											/>
										</Grid>
										<Grid item xs={6} sm={3}>
											<TextField
												fullWidth
												id="level-multiplier"
												name="levelMultiplier"
												type="number"
												label="Level Multiplier"
												onBlur={handleBlur}
												onChange={handleChange}
												value={values.levelMultiplier}
												variant="filled"
											/>
										</Grid>
									</Grid>

									{/*<Grid container spacing={2}>
										<Grid item xs={6}>
											<Typography component="h6">LONG Position</Typography>
											<Box my={2}>
												<InputLabel htmlFor="long-condition-open">Open LONG Condition</InputLabel>
												<Box my={1}>
													<AceEditor
														id="long-condition-open"
														mode="javascript"
														theme="tomorrow"
														showPrintMargin={false}
														showGutter={true}
														highlightActiveLine={false}
														onChange={(value) => {
															setFieldValue('longConditionOpen', value);
														}}
														name="longConditionOpen"
														editorProps={{$blockScrolling: true}}
														value={values.longConditionOpen}
														tabSize={3}
														width="100%"
														height="50px"
													/>
												</Box>
												<FormHelperText>Open LONG position when condition returns true</FormHelperText>
											</Box>
											<Box my={2}>
												<InputLabel htmlFor="long-condition-add">Add to LONG Condition</InputLabel>
												<Box my={1}>
													<AceEditor
														id="long-condition-add"
														mode="javascript"
														theme="tomorrow"
														showPrintMargin={false}
														showGutter={true}
														highlightActiveLine={false}
														onChange={(value) => {
															setFieldValue('longConditionAdd', value);
														}}
														name="longConditionAdd"
														editorProps={{$blockScrolling: true}}
														value={values.longConditionAdd}
														tabSize={3}
														width="100%"
														height="50px"
													/>
												</Box>
												<FormHelperText>Add to LONG position when condition returns true</FormHelperText>
											</Box>
										</Grid>
										<Grid item xs={6}>
											<Typography component="h6">SHORT Position</Typography>
											<Box my={2}>
												<InputLabel htmlFor="short-condition-open">Open SHORT Condition</InputLabel>
												<Box my={1}>
													<AceEditor
														id="short-condition-open"
														mode="javascript"
														theme="tomorrow"
														showPrintMargin={false}
														showGutter={true}
														highlightActiveLine={false}
														onChange={(value) => {
															setFieldValue('shortConditionOpen', value);
														}}
														name="shortConditionOpen"
														editorProps={{$blockScrolling: true}}
														value={values.shortConditionOpen}
														tabSize={3}
														width="100%"
														height="50px"
													/>
												</Box>
												<FormHelperText>Open SHORT position when condition returns true</FormHelperText>
											</Box>
											<Box my={2}>
												<InputLabel htmlFor="short-condition-add">Add to SHORT Condition</InputLabel>
												<Box my={1}>
													<AceEditor
														id="short-condition-add"
														mode="javascript"
														theme="tomorrow"
														showPrintMargin={false}
														showGutter={true}
														highlightActiveLine={false}
														onChange={(value) => {
															setFieldValue('shortConditionAdd', value);
														}}
														name="shortConditionAdd"
														editorProps={{$blockScrolling: true}}
														value={values.shortConditionAdd}
														tabSize={3}
														width="100%"
														height="50px"
													/>
												</Box>
												<FormHelperText>Add to SHORT position when condition returns true</FormHelperText>
											</Box>
										</Grid>
									</Grid>*/}

								</Fragment>
							)}

							{values.type === 'FOREX_BACKTEST_PAIR_REVERSION' && (
								<Grid container spacing={2}>
									<Grid item xs={6}>
										<TextField
											fullWidth
											select
											id="instrumentA"
											name="instrumentA"
											label="Instrument A"
											onBlur={handleBlur}
											onChange={handleChange}
											value={values.instrumentA}
											variant="filled"
										>
											{instrumentsArray.map((instrument) => (
												<MenuItem key={instrument} value={instrument}>{instrument}</MenuItem>
											))}
										</TextField>

										{/*
											<TextField
												id="actionA"
												name="actionA"
												label="Action A"
												select
												onBlur={handleBlur}
												onChange={handleChange}
												value={values.actionA}
											>
												<MenuItem value={orderActions.BUY_TO_OPEN}>Buy (Long)</MenuItem>
												<MenuItem value={orderActions.SELL_TO_OPEN}>Sell (Short)</MenuItem>
										*/}

										<TextField
											fullWidth
											id="unitsA"
											name="unitsA"
											type="number"
											label="Units A"
											InputProps={{ inputProps: { min: 0 } }}
											onBlur={handleBlur}
											onChange={handleChange}
											value={values.unitsA}
											variant="filled"
										/>
									</Grid>

									<Grid item xs={6}>

										<TextField
											id="instrumentB"
											name="instrumentB"
											label="Instrument B"
											select
											fullWidth
											onBlur={handleBlur}
											onChange={handleChange}
											value={values.instrumentB}
											variant="filled"
										>
											{instrumentsArray.map((instrument) => (
												<MenuItem key={instrument} value={instrument}>{instrument}</MenuItem>
											))}
										</TextField>

										{/*
											<TextField
												id="actionB"
												name="actionB"
												label="Action B"
												select
												fullWidth
												onBlur={handleBlur}
												onChange={handleChange}
												value={values.actionB}
											>
												<MenuItem value={orderActions.BUY_TO_OPEN}>Buy (Long)</MenuItem>
												<MenuItem value={orderActions.SELL_TO_OPEN}>Sell (Short)</MenuItem>
											</TextField>
										*/}

										<TextField
											fullWidth
											id="unitsB"
											name="unitsB"
											type="number"
											label="Units"
											InputProps={{ inputProps: { min: 0 } }}
											onBlur={handleBlur}
											onChange={handleChange}
											value={values.unitsB}
											variant="filled"
										/>
									</Grid>

									<Grid item xs={12}>
										<TextField
											fullWidth
											select
											id="correlationDirection"
											name="correlationDirection"
											label="Correlation Direction"
											onBlur={handleBlur}
											onChange={handleChange}
											value={values.correlationDirection}
											variant="filled"
										>
											<MenuItem value="POSITIVE">Positive</MenuItem>
											<MenuItem value="NEGATIVE">Negative</MenuItem>
										</TextField>
									</Grid>
								</Grid>
							)}

						</DialogContent>

						<DialogActions>
							<Button
								type="submit"
								variant="contained"
								color="primary"
							>
								Save Agent
							</Button>
						</DialogActions>
					</Form>
				)}
			</Formik>
		</Dialog>
	);
};

const mapStateToProps = (state) => {
	const instruments = accountSelectors.getAccountInstruments(state);
	const instrumentsEnabled = accountSelectors.getAccountInstruments(state, true, true);
	const instrumentsAll  = accountSelectors.getAccountInstruments(state, true, false);
	const agents = agentSelectors.getAgents(state, 'FOREX_PRICE_FOLLOWER');
	const instrumentsActive = agents.map((agent) => agent.config.instruments.core.name);

	return {
		instruments,
		instrumentsEnabled,
		instrumentsAll,
		instrumentsActive,
	};
};

const mapDispatchToProps = {
	getAccountInstruments: accountOperations.getAccountInstruments,
	createAgent: agentOperations.createAgent,
};

const propsAreEqual = (prevProps, nextProps) => {
	return nextProps.instruments === prevProps.instruments
	&& nextProps.isOpen === prevProps.isOpen
	// && nextProps.instrumentsActive === prevProps.instrumentsActive
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(withTheme(memo(AgentDialog, propsAreEqual)));
