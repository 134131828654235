import React, { useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import { withTheme, Typography, Box, Divider, Paper, TableContainer, Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core';
import Moment from 'react-moment';
import styled from 'styled-components';

import { operations as transactionOperations, selectors as transactionSelectors } from '@@Redux/transactions';
import { dateFormats } from '@@Constants';
import DebugOutput from '@@Components/DebugOutput';

import { currency } from '@@Utils';


const TransactionsList = (props) => {
	return (
		<Box my={2}>
			<TableContainer component={Paper}>
				<Table size="small">
					<TableHead>
						<TableRow>
							<TableCell>Date / ID</TableCell>
							<TableCell>Type</TableCell>
							<TableCell>Instrument</TableCell>
							<TableCell>Description</TableCell>
							<TableCell>Order Date</TableCell>
							<TableCell align="center">Settlement Date</TableCell>
							<TableCell align="center">Fees</TableCell>
							<TableCell align="right">Net Amount</TableCell>
						</TableRow>
					</TableHead>

					<TableBody>
						{props.transactions.map((transaction, i) => {

							const { transactionId, description, type, transactionSubType, orderDate, transactionDate, settlementDate, netAmount, transactionItem, fees } = transaction;
							const totalFees = Object.values(fees).reduce((a, c) => a + c, 0);

							return (
								<TableRow key={transactionId} style={ i % 2 === 0 ? { backgroundColor: '#fafafa'} : {}}>
									<TableCell>
										<div><Moment date={transactionDate} format={dateFormats.DATE_TIME} /></div>
										<div>#{transactionId}</div>
									</TableCell>
									<TableCell title={description}>
										<div>{type}: {transactionSubType}</div>
									</TableCell>
									<TableCell>
										{(transactionItem && transactionItem.instrument && type === 'TRADE') && (
											<Fragment>
												<div><strong>{transactionItem.instrument.underlyingSymbol}</strong></div>
												<div>{transactionItem.instrument.symbol}</div>
											</Fragment>
										)}
									</TableCell>
									<TableCell>
										{(transactionItem && transactionItem.instrument && type === 'TRADE') && (
											<Fragment>
												<div>{transactionItem.positionEffect} {transactionItem.instruction}</div>
												<div>{transactionItem.instrument.description}</div>
												<div>{transactionItem.amount} @ {currency(transactionItem.price)}</div>
											</Fragment>
										)}
									</TableCell>
									<TableCell>
										{orderDate && (
											<Moment date={orderDate} format={dateFormats.DATE_TIME } />
										)}
									</TableCell>
									<TableCell align="center"><Moment date={settlementDate} format={dateFormats.DATE} /></TableCell>
									<TableCell align="center">
										{(fees && totalFees > 0) && (
											<Fragment>
												{currency(totalFees)}
											</Fragment>
										)}
									</TableCell>
									<TableCell align="right">{currency(netAmount)}</TableCell>
								</TableRow>
							)
						})}
					</TableBody>
				</Table>
			</TableContainer>
		</Box>
	);
};


const mapStateToProps = (state, ownProps) => {
	let { symbol, transactions } = ownProps;

	if (!transactions) {
		transactions = transactionSelectors.getTransactions(state, symbol);
	}

	return {
		transactions,
	};
};

export default connect(
	mapStateToProps,
)(withTheme(TransactionsList));
