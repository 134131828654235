const ACCOUNT = {
	name: 'account/ACCOUNT',
	REQUEST: 'account/ACCOUNT_REQUEST',
	SUCCESS: 'account/ACCOUNT_SUCCESS',
	FAILURE: 'account/ACCOUNT_FAILURE',
};

const ACCOUNT_INSTRUMENTS = {
	name: 'account/ACCOUNT_INSTRUMENTS',
	REQUEST: 'account/ACCOUNT_INSTRUMENTS_REQUEST',
	SUCCESS: 'account/ACCOUNT_INSTRUMENTS_SUCCESS',
	FAILURE: 'account/ACCOUNT_INSTRUMENTS_FAILURE',
};

const ACCOUNT_POSITIONS = {
	name: 'account/ACCOUNT_POSITIONS',
	REQUEST: 'account/ACCOUNT_POSITIONS_REQUEST',
	SUCCESS: 'account/ACCOUNT_POSITIONS_SUCCESS',
	FAILURE: 'account/ACCOUNT_POSITIONS_FAILURE',
};

export default {
	ACCOUNT,
	ACCOUNT_INSTRUMENTS,
	ACCOUNT_POSITIONS,
};
