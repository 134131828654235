const POSITIONS = {
	name: 'positions/POSITIONS',
	REQUEST: 'positions/POSITIONS_REQUEST',
	SUCCESS: 'positions/POSITIONS_SUCCESS',
	FAILURE: 'positions/POSITIONS_FAILURE',
};

export default {
	POSITIONS,
};
