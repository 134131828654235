import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withTheme, MenuItem, TextField } from '@material-ui/core';

import { selectors as chainSelectors } from '@@Redux/chains';


const ExpirationDates = ({
	expirationDate,
	expirationDates,
	onChange,
	symbol,
}) => {

	useEffect(() => {
		onChange('');
	}, [symbol]);

	const handleChange = (e) => {
		const expirationDate = e.target.value;
		onChange(expirationDate);
	};

	return (
		<TextField
			fullWidth
			select
			label="Expiration Date"
			id="expirationDate"
			value={expirationDate}
			onChange={handleChange}
			variant="filled"
		>
			{expirationDates.map((date) => (
				<MenuItem
					key={date}
					value={date}
				>
					{date}
				</MenuItem>
			))}
		</TextField>
	);
};


const mapStateToProps = (state, ownProps) => {
	return {
		expirationDates: chainSelectors.getAvailableExpirationDates(state, { symbol: ownProps.symbol }),
	}
};

export default connect(
	mapStateToProps,
)(withTheme(ExpirationDates));

