import actions from './actions';


const getAccount = (params) => (dispatch) => {
	console.info('[operation] getAccount()', params);
	return dispatch(actions.getAccount.request(params));
};

const getAccountInstruments = (params) => (dispatch) => {
	console.info('[operation] getAccountInstruments()', params);
	return dispatch(actions.getAccountInstruments.request(params));
};

const getOpenPositions = (params) => (dispatch) => {
	console.info('[operation] getOpenPositions()', params);
	return dispatch(actions.getOpenPositions.request(params));
};

export {
	getAccount,
	getAccountInstruments,
	getOpenPositions,
};
