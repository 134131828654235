import { combineReducers } from 'redux';
import produce from 'immer';

import { messageTypes } from '@@Constants';

import types from './types';


const MAX_MESSAGE_COUNT = 50;

const incoming = (state = [], action) => produce(state, draft => {
	const { type, payload } = action;
	switch (type) {
		case types.SOCKET.MESSAGE.RECEIVE: {
			if (payload.type && payload.type !== messageTypes.AMERITRADE.HEARTBEAT) {
				if (draft.length >= MAX_MESSAGE_COUNT) {
					draft.shift();
				}
				draft.push(payload);
			}
			return;
		}
		default: return state;
	}
});

const outgoing = (state = [], action) => {
	const { type, payload } = action;
	switch (type) {
		case types.SOCKET.MESSAGE.SEND: {
			return payload;
		}
		default: return state;
	}
};

const heartbeat = (state = '', action) => {
	const { type, payload, meta } = action;
	switch (type) {
		case types.SOCKET.MESSAGE.RECEIVE: {
			if (payload.type && payload.type === messageTypes.AMERITRADE.HEARTBEAT) {
				return payload.data;
			}
			if (meta.type === messageTypes.HEARTBEAT) {
				return payload.data;
			}
			return state;
		}
		default: return state;
	}
};

const subscriptions = (state = {}, action) => produce(state, draft => {
	const { type, payload } = action;
	switch (type) {
		case types.SOCKET.SUBSCRIBE: {
			const { service, symbol } = payload;
			draft[service] = draft[service] || [];
			draft[service].push(symbol);
			return;
		}
		default: return state;
	}
});

const messages = combineReducers({
	incoming,
	outgoing,
});

const reducer = combineReducers({
	heartbeat,
	messages,
	subscriptions,
});

export default reducer;
