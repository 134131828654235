import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { Form, Formik } from 'formik';
import { v4 as uuid } from 'uuid';
import moment from 'moment';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/theme-tomorrow';

import {
	withTheme,
	Box,
	Button,
	Checkbox,
	FormControlLabel,
	FormHelperText,
	Grid,
	TextField,
	Dialog,
	DialogContent,
	DialogActions,
	Divider,
	InputAdornment,
	InputLabel,
	MenuItem,
	Typography,
} from '@material-ui/core';

import SpreadIntervals from '@@Components/Options/Agents/SpreadIntervals';
import ExpirationDates from '@@Components/Options/Agents/ExpirationDates';
import DialogTitle from '@@Components/Dialog/DialogTitle/DialogTitle';
import { selectors as accountSelectors } from '@@Redux/account';
import { operations as agentOperations } from '@@Redux/agents';
import { operations as chainOperations, selectors as chainSelectors } from '@@Redux/chains';
import { operations as socketOperations } from '@@Redux/socket';


const AgentDialog = ({
	agent,
	buyingPower,
	createAgent,
	getChain,
	intervalCount,
	isOpen,
	onClose,
	updateAgent,
	subscribe,
}) => {

	const getInitialValues = () => {

		let allocatedFunds = Math.floor(Math.min(buyingPower, 100));

		return {
			name: agent ? agent.name : '',
			allocatedFunds,
			underlyingSymbol: '',
			expirationDate: '',
			targetCallDelta: 25,
			targetPutDelta: 25,
			spreadInterval: null,
			quantity: 1,
			sellInitPercentage: 100,
			closingPercentage: 50,
			buyCondition: 'return true;', // 'return (I_M1.RSI.value > 0);',
			sellCondition: '',
			buyUseTrailingStop: true,
			buyTrailingOffsetPercentage: 2.5,
			sellUseTrailingStop: true,
			sellTrailingOffsetPercentage: 2.5,
		};
	};

	const handleGetChain = (symbol) => {
		getChain(symbol);
	};

	const handleSubmit = (values) => {
		const underlying = values.underlyingSymbol.toUpperCase();
		const agentObj = {};
		if (agent && agent.id) {
			agentObj.id = agent.id;
			// updateAgent(agent);
		} else {
			agentObj.id = uuid();
			agentObj.config = {
				type: 'OPTION',
				name: values.name || '',
				allocatedFunds: values.allocatedFunds,
				underlyingSymbol: underlying,
				expirationDate: values.expirationDate,
				targetCallDelta: values.targetCallDelta,
				targetPutDelta: values.targetPutDelta,
				spreadInterval: values.spreadInterval,
				quantity: values.quantity,
				sellInitPercentage: values.sellInitPercentage / 100,
				closingPercentage: values.closingPercentage / 100,
				buyCondition: values.buyCondition,
				sellCondition: values.sellCondition,
				buyUseTrailingStop: values.buyUseTrailingStop,
				buyTrailingOffsetPercentage: values.buyTrailingOffsetPercentage,
				sellUseTrailingStop: values.sellUseTrailingStop,
				sellTrailingOffsetPercentage: values.sellTrailingOffsetPercentage,
			};

			createAgent(agentObj);
			subscribe({ service: 'QUOTE', symbol: underlying });
		}
		onClose();
	};

	return (
		<div>
			<Dialog
				open={isOpen}
				onClose={onClose}
				aria-labelledby="dialog-title"
				fullWidth
				maxWidth="sm"
			>
				<DialogTitle id="dialog-title" onClose={onClose}>
					Add Agent
				</DialogTitle>

				<Formik
					initialValues={getInitialValues()}
					enableReinitialize={true}
					onSubmit={handleSubmit}
				>
					{({
						values,
						errors,
						touched,
						handleChange,
						handleBlur,
						isSubmitting,
						setFieldValue
					}) => (
						<Form>
							<DialogContent>

								{/* <Grid container spacing={2}>
									<Grid item xs={4}>
										<TextField
											fullWidth
											id="allocatedFunds"
											name="allocatedFunds"
											label="Allocated Funds"
											onBlur={handleBlur}
											onChange={handleChange}
											value={values.allocatedFunds}
											type="number"
											helperText={`${Math.round((values.allocatedFunds / buyingPower) * 100)}% of available funds`}
											inputProps={{ maxLength: 4 }}
											InputProps={{
												startAdornment: (
													<InputAdornment position="start">$</InputAdornment>
												),
											}}
										/>
									</Grid>
								</Grid> */}

								<Grid container spacing={2} alignItems="flex-end">

									<Grid item xs={4}>
										<TextField
											fullWidth
											id="underlyingSymbol"
											name="underlyingSymbol"
											label="Underlying Symbol"
											placeholder="AAPL"
											onBlur={handleBlur}
											onChange={handleChange}
											value={values.underlyingSymbol.toUpperCase()}
											inputProps={{maxLength: 4}}
											InputLabelProps={{
												shrink: true,
											}}
											variant="filled"
										/>
									</Grid>

									<Grid item>
										<Button
											variant="contained"
											color="secondary"
											disabled={!values.underlyingSymbol}
											onClick={() => handleGetChain(values.underlyingSymbol.toUpperCase())}
										>
											Get Option Chain
										</Button>
									</Grid>
								</Grid>


								<Grid container spacing={2}>
									<Grid item xs={12}>
										<SpreadIntervals
											interval={values.spreadInterval}
											symbol={values.underlyingSymbol.toUpperCase()}
											expirationDate={values.expirationDate}
											onChange={(val) => {
												setFieldValue('spreadInterval', val);
											}}
										/>
									</Grid>
								</Grid>

								{values.spreadInterval && (
									<Fragment>
										<Grid container spacing={2}>
											<Grid item xs={4}>
												<ExpirationDates
													expirationDate={values.expirationDate}
													symbol={values.underlyingSymbol.toUpperCase()}
													onChange={(val) => setFieldValue('expirationDate', val)}
												/>
												{intervalCount && (
													<FormHelperText>
														<span>Count: {intervalCount}</span>&nbsp;
													</FormHelperText>
												)}

											</Grid>
											<Grid item xs>
												<TextField
													fullWidth
													id="targetCallDelta"
													name="targetCallDelta"
													label="CALL Delta"
													type="number"
													onBlur={handleBlur}
													onChange={handleChange}
													value={values.targetCallDelta}
													variant="filled"
													InputProps={{
														endAdornment: (
															<InputAdornment position="end">Δ</InputAdornment>
														),
													}}
												/>
											</Grid>

											<Grid item xs>
												<TextField
													fullWidth
													id="targetPutDelta"
													name="targetPutDelta"
													label="PUT Delta"
													type="number"
													onBlur={handleBlur}
													onChange={handleChange}
													value={values.targetPutDelta}
													variant="filled"
													InputProps={{
														endAdornment: (
															<InputAdornment position="end">Δ</InputAdornment>
														),
													}}
												/>
											</Grid>

											<Grid item xs>
												<TextField
													fullWidth
													id="quantity"
													name="quantity"
													label="Contract Qty"
													type="number"
													onBlur={handleBlur}
													onChange={handleChange}
													value={values.quantity}
													helperText="per direction"
													variant="filled"
												/>
											</Grid>
										</Grid>

										<Grid container spacing={2}>
											<Grid item xs={6}>
												<Typography variant="h6">BUY</Typography>

												<FormControlLabel
													control={(
														<Checkbox
															name="buyUseTrailingStop"
															checked={values.buyUseTrailingStop}
															onChange={(e) => {
																setFieldValue('buyUseTrailingStop', e.target.checked);
															}}
														/>
													)}
													label="Use Trailing Stop"
												/>

												{values.buyUseTrailingStop && (
													<TextField
														fullWidth
														id="buyTrailingOffsetPercentage"
														name="buyTrailingOffsetPercentage"
														label="BUY Trailing Offset"
														type="number"
														onBlur={handleBlur}
														onChange={handleChange}
														value={values.buyTrailingOffsetPercentage}
														InputProps={{
															endAdornment: (
																<InputAdornment position="end">%</InputAdornment>
															),
														}}
														variant="filled"
													/>
												)}
											</Grid>

											<Grid item xs={6}>
												<Typography variant="h6">SELL</Typography>

												<FormControlLabel
													control={(
														<Checkbox
															name="sellUseTrailingStop"
															checked={values.sellUseTrailingStop}
															onChange={(e) => {
																setFieldValue('sellUseTrailingStop', e.target.checked);
															}}
														/>
													)}
													label="Use Trailing Stop"
												/>

												{values.sellUseTrailingStop && (
													<TextField
														fullWidth
														id="sellTrailingOffsetPercentage"
														name="sellTrailingOffsetPercentage"
														label="SELL Trailing Offset"
														type="number"
														onBlur={handleBlur}
														onChange={handleChange}
														value={values.sellTrailingOffsetPercentage}
														InputProps={{
															endAdornment: (
																<InputAdornment position="end">%</InputAdornment>
															),
														}}
														variant="filled"
													/>
												)}

											</Grid>
										</Grid>


										<Box my={2}>
											<Divider />
										</Box>


										<Grid container spacing={2}>
											<Grid item xs={3}>
												<TextField
													fullWidth
													id="sellInitPercentage"
													name="sellInitPercentage"
													label="Initiate SELL at"
													type="number"
													onBlur={handleBlur}
													onChange={handleChange}
													value={values.sellInitPercentage}
													InputProps={{
														endAdornment: (
															<InputAdornment position="end">%</InputAdornment>
														),
													}}
													variant="filled"
												/>

											</Grid>

											<Grid item xs={3}>
												<TextField
													fullWidth
													id="closingPercentage"
													name="closingPercentage"
													label="Close position at"
													type="number"
													onBlur={handleBlur}
													onChange={handleChange}
													value={values.closingPercentage}
													InputProps={{
														endAdornment: (
															<InputAdornment position="end">%</InputAdornment>
														),
													}}
													variant="filled"
												/>
											</Grid>

											{/*<Grid item xs={12}>
												<Typography variant="h6" gutterBottom>
													BUY condition
												</Typography>
												<AceEditor
													mode="javascript"
													theme="tomorrow"
													onChange={(value) => {
														setFieldValue('buyCondition', value);
													}}
													id="buyCondition"
													name="buyCondition"
													// editorProps={{$blockScrolling: true}}
													value={values.buyCondition}
													width="100%"
													height="100px"
													setOptions={{
														showLineNumbers: true,
														tabSize: 2,
														highlightActiveLine: false,
														useWorker: false,
													}}
												/>
												<small className="form-text text-muted">BUY order placed when condition returns true</small>
											</Grid>

											<Grid item xs={12}>
												<Typography variant="h6" gutterBottom>
													SELL condition
												</Typography>
												<AceEditor
													mode="javascript"
													theme="tomorrow"
													onChange={(value) => {
														setFieldValue('sellCondition', value);
													}}
													id="sellCondition"
													name="sellCondition"
													// editorProps={{$blockScrolling: true}}
													value={values.sellCondition}
													width="100%"
													height="100px"
													setOptions={{
														showLineNumbers: true,
														tabSize: 2,
														highlightActiveLine: false,
														useWorker: false,
													}}
												/>
												<small className="form-text text-muted">SELL order placed when condition returns true</small>
											</Grid>

											*/}

											<Grid item xs={12}>
												<TextField
													fullWidth
													id="name"
													name="name"
													variant="filled"
													label="Agent Name"
													onBlur={handleBlur}
													onChange={handleChange}
													value={values.name}

												/>
											</Grid>

										</Grid>
									</Fragment>
								)}

							</DialogContent>

							{(values.underlyingSymbol && values.spreadInterval) && (
								<DialogActions>
									<Button
										type="submit"
										variant="contained"
										color="primary"
										disabled={!values.underlyingSymbol || !values.spreadInterval || !values.expirationDate}
									>
										Save Agent
									</Button>
								</DialogActions>
							)}
						</Form>
					)}
				</Formik>
			</Dialog>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		buyingPower: accountSelectors.getBuyingPower(state),
	};
};

const mapDispatchToProps = {
	createAgent: agentOperations.createAgent,
	getChain: chainOperations.getChain,
	subscribe: socketOperations.subscribe,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(withTheme(AgentDialog));
