import React, { useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import { Box, Card, CardHeader, CardContent, CardActions, Grid, Typography } from '@material-ui/core';
import Moment from 'react-moment';

import { operations as orderOperations } from '@@Redux/orders';
import { operations as positionOperations, selectors as positionSelectors } from '@@Redux/positions';
import { operations as transactionOperations, selectors as transactionSelectors } from '@@Redux/transactions';

import DebugOutput from '@@Components/DebugOutput';
import TransactionsList from '@@Components/Options/Transactions/TransactionsList';
import { currency } from "@@Utils";


const PositionsList = (props) => {

	useEffect(() => {
		props.getPositions();
		props.getTransactions();
		// props.createOrder();
	}, []);

	const getStrikePrice = (symbol) => {
		console.log('symbol', symbol);
		const dateStrike = symbol.split('_')[1];
		return dateStrike.split((/[C,P]+/))[1];
	};

	const getExpirationDate = (symbol) => {
		const dateStrike = symbol.split('_')[1];
		return dateStrike.split((/[C,P]+/))[0];
	};

	return (
		<Box m={2}>
			<Typography gutterBottom variant="h1">
				Positions
			</Typography>
			{props.positions.length > 0 && props.positions.map((position) => {

				const { instrument, averagePrice, shortQuantity, longQuantity } = position;

				return (
					<Box key={instrument.symbol} my={2}>
						<Card>
							<CardHeader
								title={(
									<Fragment>
										<Grid container justifyContent="space-between">
											<Grid item>
												{instrument.assetType === 'OPTION' && (
													<Fragment>
														{instrument.underlyingSymbol}
														&nbsp;
														{getStrikePrice(instrument.symbol)}
														&nbsp;
														{instrument.putCall}
													</Fragment>
												)}

												{instrument.assetType === 'EQUITY' && (
													<Fragment>
														{instrument.symbol}
													</Fragment>
												)}

												&nbsp;
												({shortQuantity || longQuantity})

												<Typography color="textSecondary" gutterBottom component="div">
													{instrument.description}
												</Typography>
											</Grid>
											{instrument.assetType === 'OPTION' && (
											<Grid item>
												<Moment date={getExpirationDate(instrument.symbol)} parse="MMDDYY" format="MM/DD" />
											</Grid>
											)}
										</Grid>

									</Fragment>
								)}
							/>

							<CardContent>
								<Typography variant="h4" gutterBottom>{currency(averagePrice, true)}</Typography>

								<TransactionsList symbol={instrument.symbol} />
							</CardContent>

							<CardActions>
							</CardActions>
						</Card>
					</Box>
				);
			})}

			<DebugOutput
				data={props.positions}
				label="positions"
			/>
		</Box>
	)
};

const mapStateToProps = (state) => {

	return {
		positions: positionSelectors.getPositions(state, null),
		transactions: transactionSelectors.getTransactions(state)
	};
};



export default connect(
	mapStateToProps,
	{
		createOrder: orderOperations.createOrder,
		getTransactions: transactionOperations.getTransactions,
		getPositions: positionOperations.getPositions,
	}
)(PositionsList);

