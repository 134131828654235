import React from 'react';
import { connect } from 'react-redux';
import { Formik, Form } from 'formik';
import * as yup from 'yup';

import {
	makeStyles,
	Box,
	Button,
	Container,
	FormGroup,
	Grid,
	Paper,
	TextField,
	Typography
} from '@material-ui/core';

import { operations } from '@@Redux/auth';


const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		alignItems: 'center',
		height: '100vh',
	},
}));

const SignIn = (props) => {

	const classes = useStyles();

	const getInitialValues = () => {
		return {
			username: '',
			password: '',
		}
	};

	const validationSchema = yup.object().shape({
		username: yup.string()
			.min(3, 'Please enter your email address')
			.email('Invalid email address')
			.required('Please enter your email address'),
		password: yup.string()
			.min(3, 'Please enter your password')
			.required('Please enter your password'),
	});

	const handleSignIn = async (username, password) => {
		return props.signIn(username, password);
	};

	return (
		<Container className={classes.root}>

			<Grid container justifyContent="center" spacing={3}>
				<Grid item xs={12} sm={8} md={4}>

					<Paper>

						<Box p={4}>

							<Box my={3}>
								<Typography align="center" variant="h1">🤑</Typography>
							</Box>

							<Formik
								initialValues={getInitialValues()}
								validationSchema={validationSchema}
								onSubmit={(values, actions) => {
									actions.setSubmitting(true);

									handleSignIn(values.username, values.password)
										.then(() => {
											actions.setSubmitting(false);
										});
								}}
							>
								{({
									values,
									isSubmitting,
									errors,
									handleBlur,
									handleChange,
									touched,
								}) => (
									<Form>
										<FormGroup component="fieldset">

											<TextField
												id="username"
												name="username"
												helperText={touched.username ? errors.username : ''}
												error={errors.username && touched.username && Boolean(errors.username && touched.username)}
												label="Email"
												value={values.username}
												variant="filled"
												fullWidth
												onChange={handleChange}
												onBlur={handleBlur}
											/>

											<TextField
												id="password"
												name="password"
												error={errors.password && touched.password && touched.username}
												helperText={touched.password ? errors.password : ''}
												label="Password"
												type="password"
												value={values.password}
												variant="filled"
												fullWidth
												onChange={handleChange}
												onBlur={handleBlur}
											/>

										</FormGroup>

										<Button
											fullWidth
											type="submit"
											variant="contained"
											color="primary"
											disabled={isSubmitting}
										>
											{isSubmitting ? 'Signing in…' : 'Sign in'}
										</Button>

									</Form>
								)}
							</Formik>
						</Box>
					</Paper>

				</Grid>
			</Grid>

		</Container>
	);
};

const mapStateToProps = (state) => {
	return {};
};

const mapDispatchToProps = {
	signIn: operations.signIn,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(SignIn);
