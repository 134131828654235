import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { Form, Formik } from 'formik';
import { v4 as uuid } from 'uuid';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/theme-tomorrow';

import { InputLabel, Box, Typography, FormHelperText, FormControl, MenuItem, Grid, TextField, withTheme, DialogContent, Dialog, Button, DialogActions } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import DialogTitle from '@@Components/Dialog/DialogTitle/DialogTitle';
import instruments from '@@Constants/coins';
import orderActions from '@@Constants/orderActions';
import { operations as agentOperations } from '@@Redux/agents';
import { selectors as accountSelectors } from '@@Redux/account';


const AgentDialog = (props) => {

	useEffect(() => {}, []);

	const getInitialValues = () => {
		return {
			initialUnits: localStorage.getItem('initialUnits') ? Number(localStorage.getItem('initialUnits')) : 0.001,
			instrument: instruments.BTC_USD,
			maxUnits: localStorage.getItem('maxUnits') ? Number(localStorage.getItem('maxUnits')) : 0.01,
			name: props.agent ? props.agent.name : '',
			stopLossPercentage: localStorage.getItem('stopLossPercentage') ? Number(localStorage.getItem('stopLossPercentage')) : 10,
			targetPercentage: localStorage.getItem('targetPercentage') ? Number(localStorage.getItem('targetPercentage')) : 1,
			trailingStopLossTargetPercentage: 5,
			trailingStopLossPercentage: 5,
			type: 'CRYPTO_GRID',
			maxLevel: localStorage.getItem('maxLevel') ? Number(localStorage.getItem('maxLevel')) : 5,
			levelIncrement: localStorage.getItem('levelIncrement') ? Number(localStorage.getItem('levelIncrement')) : 0.5,
			levelMultiplier: localStorage.getItem('levelMultiplier') ? Number(localStorage.getItem('levelMultiplier')) : 1,
			longConditionAdd: '',
			longConditionOpen: '',
			shortConditionAdd: '',
			shortConditionOpen: '',
			fromDate: moment(localStorage.getItem('fromDate') || '2021-02-01'),
			toDate: moment(localStorage.getItem('toDate') || '2021-02-02'),
		};
	};

	const handleSubmit = (values) => {
		let {
			initialUnits,
			instrument,
			maxUnits,
			maxLevel,
			name,
			stopLossPercentage,
			targetPercentage,
			levelIncrement,
			levelMultiplier,
			trailingStopLossTargetPercentage,
			trailingStopLossPercentage,
			type,
		} = values;

		const fromDate = moment(values.fromDate).format('YYYY-MM-DD');
		const toDate = moment(values.toDate).format('YYYY-MM-DD');

		localStorage.setItem('initialUnits', initialUnits);
		localStorage.setItem('maxUnits', maxUnits);
		localStorage.setItem('maxLevel', maxLevel);
		localStorage.setItem('levelIncrement', levelIncrement);
		localStorage.setItem('levelMultiplier', levelMultiplier);
		localStorage.setItem('targetPercentage', targetPercentage);
		localStorage.setItem('stopLossPercentage', stopLossPercentage);
		localStorage.setItem('fromDate', fromDate);
		localStorage.setItem('toDate', toDate);

		const agent = {};

		agent.id = uuid();

		agent.config = {
			initialUnits,
			instrument: {
				name: instrument,
			},
			maxUnits,
			maxLevel,
			name: name || '',
			stopLossPercentage,
			targetPercentage,
			levelIncrement,
			levelMultiplier,
			trailingStopLossTargetPercentage,
			trailingStopLossPercentage,
			type,
		};


		props.createAgent(agent);
		props.onClose();
	};

	return (
		<Dialog
			open={props.isOpen}
			onClose={props.onClose}
			aria-labelledby="dialog-title"
			fullWidth
			maxWidth="md"
		>
			<DialogTitle id="dialog-title" onClose={props.onClose}>Add Agent</DialogTitle>

			<Formik
				initialValues={getInitialValues()}
				enableReinitialize={true}
				onSubmit={handleSubmit}
			>
				{({
					values,
					errors,
					touched,
					handleChange,
					handleBlur,
					isSubmitting,
					setFieldValue
				}) => (
					<Form>
						<DialogContent>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<TextField
										fullWidth
										id="type"
										name="type"
										label="Agent Type"
										select
										onBlur={handleBlur}
										onChange={handleChange}
										value={values.type}
										variant="filled"
									>
										<MenuItem key={0} value="CRYPTO_GRID">GRID</MenuItem>
									</TextField>
								</Grid>

								{false && (
									<Fragment>
									<Grid item xs={6}>
										<FormControl
											fullWidth
											margin="normal"
										>
											<MuiPickersUtilsProvider utils={MomentUtils}>
												<DatePicker
													format="YYYY-MM-DD"
													autoOk={true}
													disableToolbar
													margin="normal"
													id="fromDate"
													name="fromDate"
													label="From Date"
													value={values.fromDate}
													inputVariant="filled"
													onBlur={handleBlur}
													onChange={(value) => {
														const toDate = moment(value).add(1, 'day');
														setFieldValue('fromDate', value);
														setFieldValue('toDate', toDate);
														//localStorage.setItem('fromDate', value.format('YYYY-MM-DD'));
														//localStorage.setItem('toDate', toDate.format('YYYY-MM-DD'));
													}}
												/>
											</MuiPickersUtilsProvider>

										</FormControl>
									</Grid>
									<Grid item xs={6}>
										<FormControl
											fullWidth
											margin="normal"
										>
											<MuiPickersUtilsProvider utils={MomentUtils}>
												<DatePicker
													format="YYYY-MM-DD"
													autoOk={true}
													disableToolbar
													margin="normal"
													id="toDate"
													name="toDate"
													label="To Date"
													value={values.toDate}
													inputVariant="filled"
													onBlur={handleBlur}
													onChange={(value) => {
														setFieldValue('toDate', value);
														//localStorage.setItem('toDate', value.format('YYYY-MM-DD'));
													}}
												/>
											</MuiPickersUtilsProvider>
										</FormControl>
									</Grid>
								</Fragment>
								)}

								<Grid item xs={6} sm={6} md={3}>
									<TextField
										fullWidth
										type="number"
										id="target-percentage"
										name="targetPercentage"
										label="Target Percentage"
										onBlur={handleBlur}
										onChange={handleChange}
										value={values.targetPercentage}
										variant="filled"
									/>
								</Grid>
								<Grid item xs={6} sm={6} md={3}>
									<TextField
										fullWidth
										type="number"
										id="stop-loss-percentage"
										name="stopLossPercentage"
										label="Stop-Loss Percentage"
										onBlur={handleBlur}
										onChange={handleChange}
										value={values.stopLossPercentage}
										variant="filled"
									/>
								</Grid>
								{/*<Grid item xs={3}>
									<TextField
										fullWidth
										type="number"
										id="trailing-stop-loss-target-percentage"
										name="trailingStopLossTargetPercentage"
										label="Trailing Stop-Loss Target Percentage"
										onBlur={handleBlur}
										onChange={handleChange}
										value={values.trailingStopLossTargetPercentage}
										variant="filled"
									/>
								</Grid>
								<Grid item xs={3}>
									{instruments && (
										<TextField
											fullWidth
											type="number"
											inputProps={{
												min: 5,
											}}
											id="trailing-stop-loss-percentage"
											name="trailingStopLossPercentage"
											label="Trailing Stop-Loss Amount Percentage"
											onBlur={handleBlur}
											onChange={handleChange}
											value={values.trailingStopLossPercentage}
											variant="filled"
										/>
									)}
								</Grid>*/}
							</Grid>

							<Grid container spacing={2}>
								<Grid item xs={12}>
									<TextField
										fullWidth
										select
										id="instrument"
										name="instrument"
										label="Instrument"
										onBlur={handleBlur}
										onChange={handleChange}
										value={values.instrument}
										variant="filled"
									>
										{Object.values(instruments).map((instrument) => (
											<MenuItem key={instrument} value={instrument}>
												{instrument}
											</MenuItem>
										))}
									</TextField>

								</Grid>
								<Grid item xs={6} sm={6} md={3}>
									<TextField
										fullWidth
										id="initial-units"
										name="initialUnits"
										type="number"
										label="Initial Units"
										// InputProps={{ inputProps: { min: 0 } }}
										onBlur={handleBlur}
										onChange={handleChange}
										value={values.initialUnits}
										variant="filled"
									/>
								</Grid>
								{/*<Grid item xs={6}>
									<TextField
										fullWidth
										id="max-units"
										name="maxUnits"
										type="number"
										label="Maximum Units"
										// InputProps={{ inputProps: { min: 0 } }}
										onBlur={handleBlur}
										onChange={handleChange}
										value={values.maxUnits}
										variant="filled"
									/>
								</Grid>*/}
								<Grid item xs={6} sm={6} md={3}>
									<TextField
										fullWidth
										id="max-level"
										name="maxLevel"
										type="number"
										label="Maximum Level"
										InputProps={{ inputProps: { min: 1 } }}
										onBlur={handleBlur}
										onChange={handleChange}
										value={values.maxLevel}
										variant="filled"
									/>
								</Grid>
								<Grid item xs={6} sm={6} md={3}>
									<TextField
										fullWidth
										id="level-increment"
										name="levelIncrement"
										type="number"
										label="Level Increment"
										onBlur={handleBlur}
										onChange={handleChange}
										value={values.levelIncrement}
										variant="filled"
										helperText="Standard Deviations"
									/>
								</Grid>
								<Grid item xs={6} sm={6} md={3}>
									<TextField
										fullWidth
										id="level-multiplier"
										name="levelMultiplier"
										type="number"
										label="Level Multiplier"
										onBlur={handleBlur}
										onChange={handleChange}
										value={values.levelMultiplier}
										variant="filled"
									/>
								</Grid>


								{/*<Grid item xs={6}>
									<Typography component="h6">LONG Position</Typography>
									<Box my={2}>
										<InputLabel htmlFor="long-condition-open">Open LONG Condition</InputLabel>
										<Box my={1}>
											<AceEditor
												id="long-condition-open"
												mode="javascript"
												theme="tomorrow"
												showPrintMargin={false}
												showGutter={true}
												highlightActiveLine={false}
												onChange={(value) => {
													setFieldValue('longConditionOpen', value);
												}}
												name="longConditionOpen"
												editorProps={{$blockScrolling: true}}
												value={values.longConditionOpen}
												tabSize={3}
												width="100%"
												height="50px"
											/>
										</Box>
										<FormHelperText>Open LONG position when condition returns true</FormHelperText>
									</Box>
									<Box my={2}>
										<InputLabel htmlFor="long-condition-add">Add to LONG Condition</InputLabel>
										<Box my={1}>
											<AceEditor
												id="long-condition-add"
												mode="javascript"
												theme="tomorrow"
												showPrintMargin={false}
												showGutter={true}
												highlightActiveLine={false}
												onChange={(value) => {
													setFieldValue('longConditionAdd', value);
												}}
												name="longConditionAdd"
												editorProps={{$blockScrolling: true}}
												value={values.longConditionAdd}
												tabSize={3}
												width="100%"
												height="50px"
											/>
										</Box>
										<FormHelperText>Add to LONG position when condition returns true</FormHelperText>
									</Box>
								</Grid>

								<Grid item xs={6}>
									<Typography component="h6">SHORT Position</Typography>
									<Box my={2}>
										<InputLabel htmlFor="short-condition-open">Open SHORT Condition</InputLabel>
										<Box my={1}>
											<AceEditor
												id="short-condition-open"
												mode="javascript"
												theme="tomorrow"
												showPrintMargin={false}
												showGutter={true}
												highlightActiveLine={false}
												onChange={(value) => {
													setFieldValue('shortConditionOpen', value);
												}}
												name="shortConditionOpen"
												editorProps={{$blockScrolling: true}}
												value={values.shortConditionOpen}
												tabSize={3}
												width="100%"
												height="50px"
											/>
										</Box>
										<FormHelperText>Open SHORT position when condition returns true</FormHelperText>
									</Box>
									<Box my={2}>
										<InputLabel htmlFor="short-condition-add">Add to SHORT Condition</InputLabel>
										<Box my={1}>
											<AceEditor
												id="short-condition-add"
												mode="javascript"
												theme="tomorrow"
												showPrintMargin={false}
												showGutter={true}
												highlightActiveLine={false}
												onChange={(value) => {
													setFieldValue('shortConditionAdd', value);
												}}
												name="shortConditionAdd"
												editorProps={{$blockScrolling: true}}
												value={values.shortConditionAdd}
												tabSize={3}
												width="100%"
												height="50px"
											/>
										</Box>
										<FormHelperText>Add to SHORT position when condition returns true</FormHelperText>
									</Box>
								</Grid>*/}

							</Grid>

						</DialogContent>

						<DialogActions>
							<Button
								type="submit"
								variant="contained"
								color="primary"
							>
								Save Agent
							</Button>
						</DialogActions>
					</Form>
				)}
			</Formik>
		</Dialog>
	);
};

const mapStateToProps = (state) => {
	//const instruments = accountSelectors.getAccountInstruments(state);
	return {
		//instruments,
	};
};

const mapDispatchToProps = {
	createAgent: agentOperations.createAgent,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(withTheme(AgentDialog));
