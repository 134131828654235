import { transparentize } from 'polished';

import colors from './colors';

const overrides = {
	MuiAppBar: {
		colorPrimary: {
			backgroundColor: colors.primary['700'],
		},
	},
	MuiIconButton: {
		root: {
			color: 'inherit',
			'&.active': {
				color: colors.white,
			},
		},
	},
	MuiButton: {
		root: {
			textTransform: 'uppercase',
			fontWeight: '700',
		},
		containedPrimary: {
			backgroundColor: colors.primary['900'],
		},
		containedSecondary: {
			backgroundColor: colors.secondary['900'],
			color: colors.white,
			'&:hover': {
				backgroundColor: colors.secondary['600'],
			},
		},
		outlinedPrimary: {
			borderColor: transparentize(0.85, colors.primary['500']),
		},
		outlinedSecondary: {
			borderColor: transparentize(0.85, colors.secondary['900']),
			color: colors.secondary['900'],
		},
	},
	MuiDrawer: {
		paperAnchorDockedLeft: {
			borderRight: 0,
		},
	},
	MuiExpansionPanelDetails: {
		root: {
			padding: '1em 2em',
		},
	},
	MuiFilledInput: {
		root: {
			backgroundColor: colors.grey['100'],
		},
		underline: {
			'&:before': {
				borderBottomWidth: 1,
				borderBottomColor: transparentize(0.85, colors.black),
			},
		},
	},
	MuiFormControl: {
		root: {
			marginBottom: '0.5em',
		},
	},
	MuiFormGroup: {
		root: {
			marginBottom: '1em',
		},
	},
	MuiTableCell: {
		root: {
			verticalAlign: 'top',
			padding: '0.9em',
		},
		head: {
			fontSize: '0.9em',
			fontWeight: '700',
			lineHeight: 1.2,
			verticalAlign: 'bottom',
		},
	},
	MuiTableRow: {
		root: {
			'&:nth-of-type(odd)': {
				backgroundColor: colors.grey[50],
			},
		},
		head: {
			backgroundColor: colors.grey[200],
		},
	},
	MuiTableSortLabel: {
		root: {
			lineHeight: 1.25,
		},
	},
	MuiTooltip: {
		tooltip: {
			backgroundColor: colors.primary['800'],
			fontSize: '0.95em',
		},
		arrow: {
			color: colors.primary['800'],
		},
	},
	MuiTypography: {
		colorError: {
			color: colors.error[700],
		},
	},
};

export default overrides;
