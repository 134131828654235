
const getQuotes = (state) => {
	return state.quotes;
};

const getQuote = (state, { service, symbol }) => {
	if (state.quotes[service]) {
		return state.quotes[service][symbol];
	}
};

const getSymbols = (state) => {
	return Object.keys(state.quotes);
};

export {
	getQuote,
	getQuotes,
	getSymbols,
};
