import React, { Fragment, useEffect, useState } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

import { Box, Toolbar, Container, CssBaseline } from '@material-ui/core';
import { ThemeProvider, StylesProvider } from '@material-ui/core/styles';

import AgentDashboard from '@@Components/Options/Agents/AgentDashboard';
import AuthSignIn from '@@Components/Auth/SignIn';
import AuthSignOut from '@@Components/Auth/SignOut';
import Crypto from '@@Components/Crypto/Crypto';
import Forex from '@@Components/Forex/Forex';
import Futures from '@@Components/Futures/Futures';
import Options from '@@Components/Options/Options';
import ProgressIndicator from '@@Components/ProgressIndicator/ProgressIndicator';
import Settings from '@@Components/Settings/Settings';
import config from '@@Config';
import { operations as authOperations, selectors as authSelectors } from '@@Redux/auth';
import { operations as socketOperations } from '@@Redux/socket';
import theme, { colors } from '@@Theme';
import GlobalStyle from '@@Theme/components/GlobalStyle';

import Header from './Header';


const App = ({
	connect,
	session,
	getAWSCredentials,
	getSession,
}) => {

	const { sub: userId } = session || {};

	const [selectedTab, setSelectedTab] = useState(1);

	useEffect(() => {
		async function fetchData() {
			await getSession();

			if (userId) {
				connect();
				getAWSCredentials();
			}
		}
		fetchData();
	}, [userId, getSession,connect, getAWSCredentials]);

	const handleTabChange = (event, newValue) => {
		setSelectedTab(newValue);
	};

	return (
		<StylesProvider injectFirst>
			<ThemeProvider theme={theme}>
				<CssBaseline />
				<GlobalStyle />

				<Helmet
					titleTemplate={`%s- ${config.basePageTitle}`}
					defaultTitle={config.basePageTitle}
				>
					<meta name="theme-color" content={colors.primary['500']} />
				</Helmet>

				<ProgressIndicator />

				{!session && (
					<Switch>
						<Route exact path="/">
							<Fragment>
								<Redirect to="/auth/sign-in" />
							</Fragment>
						</Route>

						<Route
							exact
							path="/auth/sign-in"
							render={props => <AuthSignIn {...props} />}
						/>
					</Switch>
				)}

				{session && (
					<Fragment>
						<Header
							onTabChange={handleTabChange}
							selectedTab={selectedTab}
						/>

						<Container id="main" tabIndex={-1} component="main" maxWidth={false}>

							{/* this exists solely to create fixed <AppBar> offset for content */}
							<Toolbar />

							<Box py={3}>
								<Switch>
									<Route exact path="/auth/sign-out" render={(props) => (<AuthSignOut {...props} />)}/>
									<Route exact path="/agents-dashboard" render={(props) => (<AgentDashboard {...props} />)}/>
									<Route exact path="/crypto" render={(props) => (<Crypto {...props} />)}/>
									<Route exact path="/forex" render={(props) => (<Forex {...props} />)}/>
									<Route path="/options" render={(props) => (<Options {...props} />)}/>
									{/* <Route exact path="/futures" render={(props) => (<Futures {...props} />)}/> */}
									<Route exact path="/settings" render={(props) => (<Settings {...props} />)}/>
									<Route exact path="/">
										<Redirect to="/forex" />
									</Route>
								</Switch>
							</Box>
						</Container>
					</Fragment>
				)}

			</ThemeProvider>
		</StylesProvider>
	);
};

const mapStateToProps = (state) => {
	return {
		credentials: authSelectors.getAWSCredentials(state),
		session: authSelectors.getSession(state),
	};
};

const mapDispatchToProps = {
	connect: socketOperations.connect,
	getAWSCredentials: authOperations.getAWSCredentials,
	getSession: authOperations.getSession,
	subscribe: socketOperations.subscribe,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(withRouter(App));

