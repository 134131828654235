import React from 'react';
import PropTypes from 'prop-types';

import { Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import * as Styles from './DialogTitle.styles';

const propTypes = {};

const DialogTitle = ({
	children,
	onClose,
	...otherProps
}) => {
	return (
		<Styles.DialogTitle disableTypography {...otherProps}>
			<Typography variant="h6">
				{children}
			</Typography>

			{onClose && (
				<Styles.CloseButton aria-label="close" onClick={onClose}>
					<CloseIcon />
				</Styles.CloseButton>
			)}
		</Styles.DialogTitle>
	)
};

DialogTitle.propTypes = propTypes;

export default DialogTitle;
