import React, { useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import {
	Box,
	FormControl,
	FormLabel,
	Radio,
	RadioGroup,
	FormControlLabel,
	FormHelperText,
	withTheme,
} from '@material-ui/core';

import { operations as chainOperations, selectors as chainSelectors } from '@@Redux/chains';


const SpreadIntervals = ({
	defaultInterval,
	expirationDate,
	getChain,
	intervalCount,
	intervals,
	onChange,
	selectedInterval,
	symbol,
}) => {

	useEffect(() => {
		onChange(null);
	}, [symbol]);

	useEffect(() => {
		if (symbol && defaultInterval) {
			getChain(symbol, expirationDate, defaultInterval);
			onChange(defaultInterval);
		}
	}, [defaultInterval]);

	const handleChange = (e) => {
		const interval = Number(e.target.value);
		if (symbol) {
			getChain(symbol, expirationDate, interval);
		}
		onChange(interval);
	};

	return (
		<Box>
			{((selectedInterval) && (intervals.length > 0)) && (
				<Box my={2}>
					<FormControl component="fieldset">
						<FormLabel component="legend">Spread Interval (width)</FormLabel>
						<RadioGroup row aria-label="spread width" name="spread" value={selectedInterval} onChange={handleChange}>
							{intervals.map((spread) => (
								<FormControlLabel key={spread} value={spread} control={<Radio/>} label={
									<span>
										{spread}&nbsp;
										{(spread === defaultInterval) && <small>(DEFAULT)</small>}
									</span>
								} />
							))}
						</RadioGroup>

						{intervalCount && (
							<FormHelperText>
								<span>Count: {intervalCount}</span>&nbsp;
							</FormHelperText>
						)}
					</FormControl>
				</Box>
			)}
		</Box>
	);
};

const mapStateToProps = (state, ownProps) => {
	const { expirationDate, interval, symbol } = ownProps;
	const selectedInterval = interval;
	const intervals = chainSelectors.getAvailableSpreadIntervals(state, { symbol });
	const intervalCount = chainSelectors.getSpreadIntervalCount(state, { symbol, expiration: expirationDate, interval: selectedInterval });
	const defaultInterval = chainSelectors.getDefaultSpreadInterval(state, { symbol });

	return {
		defaultInterval,
		intervalCount,
		intervals,
		selectedInterval,
	}
};

const mapDispatchToProps = {
	getChain: chainOperations.getChain,
};

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	withTheme,
)(SpreadIntervals);
