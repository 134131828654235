
const ORDERS = {
	name: 'orders/ORDERS',
	REQUEST: 'orders/ORDERS_REQUEST',
	SUCCESS: 'orders/ORDERS_SUCCESS',
	FAILURE: 'orders/ORDERS_FAILURE',
};

const ORDER = {
	name: 'orders/ORDER',
	REQUEST: 'orders/ORDER_REQUEST',
	SUCCESS: 'orders/ORDER_SUCCESS',
	FAILURE: 'orders/ORDER_FAILURE',
};

const ORDER_CREATE = {
	name: 'orders/ORDER_CREATE',
	REQUEST: 'orders/ORDER_CREATE_REQUEST',
	SUCCESS: 'orders/ORDER_CREATE_SUCCESS',
	FAILURE: 'orders/ORDER_CREATE_FAILURE',
};

const ORDER_CANCEL = {
	name: 'orders/ORDER_CANCEL',
	REQUEST: 'orders/ORDER_CANCEL_REQUEST',
	SUCCESS: 'orders/ORDER_CANCEL_SUCCESS',
	FAILURE: 'orders/ORDER_CANCEL_FAILURE',
};


export default {
	ORDERS,
	ORDER,
	ORDER_CREATE,
	ORDER_CANCEL,
};
