import actions from './actions';


const connect = () => (dispatch) => {
	console.info('[operation] connect()');
	return dispatch(actions.socketConnect());
};

const sendMessage = (message) => (dispatch) => {
	console.info('[operation] sendMessage()', message);
	return dispatch(actions.sendMessage(message));
};

const subscribe = (params) => (dispatch) => {
	console.info('[operation] subscribe()', params);
	return dispatch(actions.subscribe(params));
}

export {
	connect,
	sendMessage,
	subscribe,
};
