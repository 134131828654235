import React, { Fragment } from 'react';
import prettyMs from 'pretty-ms';
import Moment from 'react-moment';

import {
	Grid,
	Divider,
	ButtonGroup,
	Button,
	Box,
	CardContent,
	CardActions,
	Typography,
	TableFooter,
	TableContainer,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	Accordion, AccordionDetails, AccordionSummary, LinearProgress
} from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import StopIcon from '@material-ui/icons/Stop';

import DebugOutput from '@@Components/DebugOutput';

import { agentStatuses } from '@@Constants';
import { USD } from '@@Utils';

import * as Styles from './AgentCard.styles';
import QuoteCard from './QuoteCard';
import {Skeleton} from '@material-ui/lab';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';



// MIN = Minimum expected value
// MAX = Maximium expected value
// Function to normalise the values (MIN / MAX could be integrated)
const normalise = (value, min, max) => (value - min) * 100 / (max - min);


const AgentCard = ({
	agent,
	onDelete,
	onStart,
	onStop,
}) => {

	const { status } = agent;

	if (status) {
		const {config, dataset, iterations, quotes, orders, position, agentStatus} = status;

		const quote = quotes[config.instrument.streamName];

		let plPercentage = 0;

		return (
			<Box m={2}>
				<Styles.Card>
					<Styles.CardHeader title={
						<Box>
							<Grid container justifyContent="space-between" alignItems="flex-start">
								<Grid item xs={8}>
									<Typography component="h6" variant="h6">
										{config.name}
									</Typography>

									<Grid container spacing={4} justifyContent="space-between" alignItems="flex-start">
										<Grid item xs={12}>
											<Box>
												<DebugOutput data={config} label="config"/>
												{/*<Typography color="primary" variant="h1" component="h1">
												{config.instrument && config.instrument.name}
											</Typography>*/}

												{/* <DebugOutput
												data={status.quotes}
												label="quotes"
											/> */}

												{status.quotes && (
													<QuoteCard
														symbol={config.instrument.name}
														service="CRYPTO"
														quote={status.quotes[config.instrument.streamName]}
													/>

												)}
												{/*{iteration.position.type}&nbsp;*/}
												{/*{iteration.position.units} @ {iteration.position.averagePrice}*/}

												{/*<Typography variant="caption" component="div">
												Units (max): {config.initialUnits} ({config.maxUnits})
											</Typography>

											<Typography variant="caption" component="div">
												Target: {config.targetPercentage}%
											</Typography>

											<Typography variant="caption" component="div">
												Stop Loss: {config.stopLossPercentage}%
											</Typography>

											<Typography variant="caption" component="div">
												Trailing Stop Target: {config.trailingStopLossTargetPercentage}%
											</Typography>

											<Typography variant="caption" component="div">
												Trailing Stop Active: {position.trailingStopLossActive.toString()}
											</Typography>*/}

												<div>
													<Typography variant="caption">
														P/L%: {plPercentage}<br/>
														{/*Max Loss: {USD(config.stopLossPips * pipValue)}*/}
													</Typography>
												</div>

											</Box>
										</Grid>
									</Grid>
								</Grid>
								<Grid item xs={3} style={{textAlign: 'right'}}>
									{status && status.agentStatus && (
										<Fragment>
											<Styles.StatusBadge
												status={status.agentStatus}
											>
												{status.agentStatus}
											</Styles.StatusBadge>

											{status.error && (
												<Typography component="div" variant="caption" color="error" gutterBottom>
													{status.error}
												</Typography>
											)}
										</Fragment>
									)}
									<Typography variant="caption" component="div" style={{textAlign: 'right'}} gutterBottom>
										{status.duration ? prettyMs(status.duration) : null}
									</Typography>
								</Grid>
							</Grid>
						</Box>
					}>
					</Styles.CardHeader>

					<CardContent>
						{Object.values(iterations).map((iteration) => {

							if (position.type === 'SHORT') {
								plPercentage = (position.averagePrice - quote.ask);
							} else if (position.type === 'LONG') {
								plPercentage = (quote.bid - position.averagePrice);
							}

							const progressPercentage = normalise(((quote.bid + quote.ask) / 2), iteration.nextLevelPrice, iteration.targetPrice);

							return (
								<Fragment key={iteration.id}>

									<Accordion defaultExpanded={iteration.status === 'POSITION_OPEN'}>
										<AccordionSummary
											expandIcon={<ExpandMoreIcon color="primary" />}
											aria-controls="panel1a-content"
											id="panel1a-header"
										>
											<Styles.StatusBadge
												title={iteration.id}
												status={iteration.status}
											>
												{iteration.status.replaceAll('_', ' ')}
											</Styles.StatusBadge>
											duration: {iteration.duration ? prettyMs(iteration.duration) : null}


										</AccordionSummary>
										<AccordionDetails>
											<Box my={2} width="100%">

												<DebugOutput
													data={iteration}
													label="iteration"
												/>

												<Grid container>
													{iteration.status === 'POSITION_OPEN' && (
														<Grid item xs={12}>
															{/*((input - min) * 100) / (max - min)*/}
															{/*{((quote.bid - iteration.nextLevelPrice) * 100) / (iteration.targetPrice - iteration.nextLevelPrice)}*/}
															<Box mb={3}>
																<LinearProgress
																	variant="determinate"
																	value={progressPercentage}
																	color="primary"
																/>
															</Box>
														</Grid>
													)}
												</Grid>

												<Box my={1}>
													<Styles.StatusBadge
														status={iteration.status}
														title={iteration.id}
													>
														{iteration.status}
													</Styles.StatusBadge>
												</Box>

												<Grid container spacing={4}>
													<Grid item>
														<strong>{iteration.position.type} {iteration.position.units}</strong><br/>
														@ {iteration.position.averagePrice}
													</Grid>
													<Grid item>
														<Typography variant="caption">
															B/E
														</Typography>
														<br/>
														{iteration.position.breakEvenPrice}
													</Grid>
													<Grid item>
														<Typography variant="caption">
															Level
														</Typography>
														<Typography variant="h2">
															{iteration.level}
														</Typography>
													</Grid>
													<Grid item>
														<Typography variant="caption">
															Next Level at
														</Typography>
														<br/>
														{iteration.nextLevelPrice}
													</Grid>
												</Grid>

												<Box my={2}>
													<Divider/>
												</Box>

												{/*<DebugOutput
										data={iteration.orders}
										label="orders"
									/>*/}

												{iteration.orders['closed'] && (
													<Box my={3}>
														<Table>
															<TableHead>
																<TableRow>
																	<TableCell>Date</TableCell>
																	<TableCell>Instrument</TableCell>
																	<TableCell>Order Action</TableCell>
																	<TableCell>Size</TableCell>
																	<TableCell>Order Price</TableCell>
																	<TableCell>Total</TableCell>
																	<TableCell>Fee</TableCell>
																	{/*<TableCell>Timestamp</TableCell>*/}
																</TableRow>
															</TableHead>
															<TableBody>
																{Object.values(iteration.orders['closed']).map((order) => (
																	<Fragment key={order.id}>
																		<TableRow>

																			<TableCell><Moment date={order.timestamp}/></TableCell>
																			<TableCell>{order.symbol}</TableCell>
																			<TableCell>{order.side}</TableCell>
																			<TableCell>{order.filled}</TableCell>
																			<TableCell>{order.price}</TableCell>
																			<TableCell>{order.cost}</TableCell>
																			<TableCell>{order.fee.cost}</TableCell>
																			{/*<TableCell>{order.timestamp}</TableCell>*/}
																		</TableRow>
																	</Fragment>
																))}
															</TableBody>
														</Table>
													</Box>
												)}
											</Box>
										</AccordionDetails>
									</Accordion>

								</Fragment>
							);
						})}

						<Divider/>

						{/*<DebugOutput*/}
						{/*	data={dataset.indicators.M5.BB}*/}
						{/*	label="M5.BB"*/}
						{/*/>*/}

						{/*<DebugOutput*/}
						{/*	data={dataset.indicators.H1.EMA_20}*/}
						{/*	label="H1.EMA_20"*/}
						{/*/>*/}

						{/*<DebugOutput*/}
						{/*	data={dataset.indicators.M5.AO}*/}
						{/*	label="M5.AO"*/}
						{/*/>*/}

						{/*<DebugOutput
						data={config.instruments}
						label="instruments"
					/>*/}

						{/*<DebugOutput
						data={orders}
						label="orders"
					/>*/}

						{/*<Typography variant="h6">Open</Typography>

					{orders['open'].map((po) => (
						<Fragment key={po.id}>
							<Box>
								{po.id}
							</Box>
						</Fragment>
					))}*/}

						{/*<Typography variant="h6">Filled</Typography>

					{orders['closed'].map((po) => (
						<Fragment key={po.id}>
							<Box>
								{po.id}
							</Box>
						</Fragment>
					))}*/}

						<Divider/>

						{/*<Box my={2}>
						<Typography variant="h3" gutterBottom>Agent Orders</Typography>

						<DebugOutput
							data={orders['open']}
							label="open orders"
						/>

						<DebugOutput
							data={orders['closed']}
							label="filled orders"
						/>

						{orders['closed'] && (
							<Box my={3}>
								<Table>
									<TableHead>
										<TableRow>
											<TableCell>Date</TableCell>
											<TableCell>Instrument</TableCell>
											<TableCell>Order Action</TableCell>
											<TableCell>Size</TableCell>
											<TableCell>Order Price</TableCell>
											<TableCell>Cost</TableCell>
											<TableCell>Timestamp</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{Object.values(orders['closed']).map((order) => (
											<Fragment key={order.id}>
												<TableRow>
													<TableCell>{order.dateString}</TableCell>
													<TableCell>{order.symbol}</TableCell>
													<TableCell>{order.side}</TableCell>
													<TableCell>{order.filled}</TableCell>
													<TableCell>{order.price}</TableCell>
													<TableCell>{order.cost}</TableCell>
													<TableCell>{order.timestamp}</TableCell>
												</TableRow>
											</Fragment>
										))}
									</TableBody>
								</Table>
							</Box>
						)}
					</Box>*/}

						<dl>
							{/*<dt>Opening</dt>*/}
							{/*<dd>{USD(balances.openingTransactions * 100)}</dd>*/}
							{/*<dt>Closing</dt>*/}
							{/*<dd>{USD(balances.closingTransactions * 100)}</dd>*/}
							{/*<dt>Total</dt>*/}
							{/*<dd>{USD((balances.openingTransactions + balances.closingTransactions) * 100)}</dd>*/}
						</dl>

					</CardContent>

					<CardActions>
						<Box p={1}>
							<ButtonGroup variant="contained">

								<Button
									disabled={agentStatus !== agentStatuses.IDLE}
									onClick={() => onStart(agent)}>
									<PlayArrowIcon/> Start
								</Button>

								<Button
									disabled={[agentStatuses.ERROR, agentStatuses.IDLE].includes(agentStatus)}
									onClick={() => onStop(agent)}>
									<StopIcon/> Stop
								</Button>

								<Button
									onClick={() => onDelete(agent)}>
									<DeleteForeverIcon/> Delete
								</Button>

							</ButtonGroup>
						</Box>
					</CardActions>

				</Styles.Card>
			</Box>
		);
	}
	return (
		<Skeleton animation="wave" />
	);
};

export default AgentCard;
