import React, { useState } from 'react';
import { withRouter, NavLink, Link as RouterLink } from 'react-router-dom';
import { connect } from 'react-redux';

import {
	withTheme,
	AppBar,
	Divider,
	Grid,
	Box,
	Menu,
	MenuItem,
	Toolbar,
	IconButton,
	Typography, Tooltip,
} from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import SettingsIcon from '@material-ui/icons/Settings';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import ShuffleIcon from '@material-ui/icons/Shuffle';
import Zoom from '@material-ui/core/Zoom';


const Header = () => {

	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);

	const handleMenu = event => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<AppBar
			elevation={0}
			variant="outlined"
		>
			<Toolbar>
				<Grid container alignItems="center" justifyContent="space-between" spacing={4}>
					<Grid item xs={2}>
						<IconButton component={RouterLink} to="/">
							<Typography variant="h2">🤑</Typography>
						</IconButton>
					</Grid>
					<Grid item xs={8} container spacing={3} justifyContent="center" alignContent="center" alignItems="center">
						<Grid item xs align="center">
							<Tooltip
								arrow
								title="Forex"
								placement="bottom"
								TransitionComponent={Zoom}
							>
								<IconButton component={NavLink} to="/forex">
									<LocalAtmIcon color="inherit" />
								</IconButton>
							</Tooltip>
						</Grid>
						<Grid item xs align="center">
							<Tooltip
								arrow
								title="Crypto"
								placement="bottom"
								TransitionComponent={Zoom}
							>
								<IconButton component={NavLink} to="/crypto">
									<MonetizationOnIcon color="inherit" />
								</IconButton>
							</Tooltip>
						</Grid>
						<Grid item xs align="center">
							<Tooltip
								arrow
								title="Options"
								placement="bottom"
								TransitionComponent={Zoom}
							>
								<IconButton component={NavLink} to="/options">
									<ShuffleIcon color="inherit" />
								</IconButton>
							</Tooltip>
						</Grid>
					</Grid>
					<Grid item xs={2}>
						<div className="text-right">
							<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', whiteSpace: 'nowrap' }}>

								<IconButton
									aria-label="account of current user"
									aria-controls="menu-appbar"
									aria-haspopup="true"
									onClick={handleMenu}
									color="inherit"
								>
									<AccountCircleIcon />
								</IconButton>

								<Menu
									id="menu-appbar"
									anchorEl={anchorEl}
									transformOrigin={{
										vertical: 'top',
										horizontal: 'left',
									}}
									open={open}
									onClose={handleClose}
								>

									<MenuItem onClick={handleClose} component={NavLink} to="/settings">
										<SettingsIcon color="primary" style={{ marginRight: 10 }} />
										Settings
									</MenuItem>

									<Box my={1}>
										<Divider />
									</Box>

									<MenuItem onClick={handleClose} component={NavLink} to="/auth/sign-out">
										<ExitToAppIcon color="primary" style={{ marginRight: 10 }} />
										Sign out
									</MenuItem>
								</Menu>

							</div>
						</div>
					</Grid>
				</Grid>
			</Toolbar>
		</AppBar>
	);
};


const mapStateToProps = (/* state */) => {
	return {};
};

export default connect(
	mapStateToProps,
	{},
)(withRouter(withTheme(Header)));
