import React from 'react';

import { Divider, Box, Typography } from '@material-ui/core';

import Ameritrade from './Ameritrade';
import Oanda from './Oanda';


const Settings = () => {
	return (
		<Box m={2}>

			<Typography component="h1" variant="h1" gutterBottom>
				Settings
			</Typography>

			<Divider />

			<Box my={4}>
				<Oanda />
			</Box>

			<Box my={4}>
				<Ameritrade />
			</Box>

		</Box>
	)
};

export default Settings;
